/* ============
 * Account Module
 * ============
 */

import optimizationReport from '@/store/modules/assignment/optimizationReport'
import assignmentSummary from '@/store/modules/assignment/assignmentSummary'
import { LABELS_CREATED_SUCCESS, LABELS_CREATED_WITH_ERRORS } from '@/const/LabelsCreatedStatuses'
import { STAFF } from '@/const/AccountTypes'
import {
  BACK_TO_EDIT_AVAILABLE,
  CANCELLED,
  CUSTOMER_EDITABLE_STATUSES,
  LABELS_CREATED,
  STAFF_EDITABLE_STATUSES,
} from '@/const/AssignmentStatuses'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import AssignmentTransformer from '@/transformers/AssignmentTransformer'
import AssignmentStatusProxy from '@/proxies/AssignmentStatusProxy'

export const FILL_ASSIGNMENT = 'FILL_ASSIGNMENT'
export const FILL_ASSIGNMENT_STATUS = 'FILL_ASSIGNMENT_STATUS'
export const FILL_ASSIGNMENT_LABELS_STATUSES = 'FILL_ASSIGNMENT_LABELS_STATUSES'
export const RESET_STATE = 'RESET_STATE'
export const RESET_LIST = 'RESET_LIST'
export const RESET_ITEMS_LIST = 'RESET_ITEMS_LIST'

export const defaultState = {
  id: null,
  createdAt: null,
  description: null,
  domesticErrorsCount: 0,
  domesticErrors: {},
  domesticItemsCount: 0,
  documentsUrl: null,
  fatalErrorsCount: 0,
  internationalErrorsCount: 0,
  internationalErrors: {},
  internationalItemsCount: 0,
  itemsCount: null,
  labelsDownloadStatus: null,
  labelsDownloadUrl: null,
  labelsStatuses: {},
  message: null,
  optimisationReportUrl: null,
  permissibleErrorsCount: 0,
  pickupDate: null,
  pickupInstructions: '',
  reference: null,
  serviceLevel: null,
  sourceFiles: [],
  staff: {},
  status: null,
  updatedAt: null,
  labelsCreatedAt: null,
  customerReference: null,
  customOptimization: {
    priority: '',
    price: '',
  },
  user: {},
  utils: {
    statusUpdateInterval: null,
  },
  archived: null,
  senderAddress: {},
  version: '',
  itemsVersion: '',
  denyLabelsDownload: false,
}

const state = {
  ...defaultState,
}

const getters = {
  get(state) {
    return state
  },
  getId(state) {
    return state.id
  },
  getStatus(state) {
    return state.status
  },
  labelsDownloadStatus(state) {
    return state.labelsDownloadStatus
  },
  getFatalErrorsCount(state) {
    return state.fatalErrorsCount
  },
  getLabelsCreatedStatus(state) {
    return Object.values(state.labelsStatuses).some((item) => item === 'error')
      ? LABELS_CREATED_WITH_ERRORS
      : LABELS_CREATED_SUCCESS
  },
  getLabelsSuccessCreatedCount(state) {
    return Object.values(state.labelsStatuses).filter((item) => item === 'shipped').length
  },
  getLabelsNotCreatedCount(state) {
    return Object.values(state.labelsStatuses).filter((item) => item === 'error').length
  },
  getUser(state) {
    return state.user
  },
  isItemsEditable(state, getters, rootState) {
    const isStaff = rootState.account.type === STAFF
    const status = state.archived ? CANCELLED : state.status
    const editableStatuses = isStaff ? STAFF_EDITABLE_STATUSES : CUSTOMER_EDITABLE_STATUSES
    return editableStatuses.includes(status)
  },

  isAvailableBackToEdit(state) {
    const status = state.archived ? CANCELLED : state.status
    return BACK_TO_EDIT_AVAILABLE.includes(status)
  },

  version(state) {
    return state.version
  },

  itemsVersion(state) {
    return state.itemsVersion
  },
  denyLabelsDownload(state) {
    return state.denyLabelsDownload
  },
}

const mutations = {
  [FILL_ASSIGNMENT](state, payload) {
    Object.assign(state, payload)
  },
  [FILL_ASSIGNMENT_STATUS](state, { status }) {
    state.status = status
  },
  [FILL_ASSIGNMENT_LABELS_STATUSES](state, statuses) {
    state.labelsStatuses = statuses
  },
  [RESET_STATE](state) {
    Object.assign(state, defaultState)
  },
  [RESET_LIST](state) {
    state.version = new Date().toISOString()
  },
  [RESET_ITEMS_LIST](state) {
    state.itemsVersion = new Date().toISOString()
  },
}

const actions = {
  //common actions
  validate: ({ state }, payload) => new AssignmentProxy().validate(payload || state.id),
  archive: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitCancel(),
  unarchive: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitUnArchive(),
  update: ({ state }, { id, data }) => new AssignmentProxy().update(id || state.id, data),
  create: (_, payload) => new AssignmentProxy({}).create(payload),
  destroy: (_, payload) => new AssignmentProxy(payload).destroy(payload),
  complete: (_, payload) => new AssignmentStatusProxy(payload).submitComplete(),
  downloadLabelsMultiple: (_, payload) => new AssignmentProxy().getLabelsMultiple(payload),

  // buttons actions
  submitProcess: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitProcess(),
  submitReview: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitReview(),
  submitReportRelease: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitReportRelease(),
  submitOptimize: ({ state }, payload) => new AssignmentProxy({}).submitOptimize(payload || state.id),
  submitComplete: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitComplete(),
  submitReportAccept: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitReportAccept(),
  submitReportDeny: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitReportDeny(),
  submitReportReleaseAndAccept: ({ state }, payload) =>
    new AssignmentStatusProxy(payload || state.id).submitReportReleaseAndAccept(),
  submitLabelsRelease: ({ state }, payload) => new AssignmentStatusProxy(payload || state.id).submitLabelsRelease(),
  createLabels: ({ state }, { id, pickupDate, pickupInstructions }) =>
    new AssignmentProxy().createLabels(id || state.id, {
      pickupDate,
      pickupInstructions,
    }),

  refresh: ({ commit }) => {
    commit(RESET_LIST)
  },
  refreshItems: ({ commit }) => {
    commit(RESET_ITEMS_LIST)
  },
  resetState: ({ commit }) => {
    commit(RESET_STATE)
  },

  fetch: ({ commit, state, dispatch }, payload) => {
    const assignmentId = payload || state.id
    new AssignmentProxy().find(assignmentId).then((response) => {
      if (response.data.id) {
        if (response.data.status === LABELS_CREATED) {
          dispatch('fetchLabelsStatuses', assignmentId)
        }
        commit(FILL_ASSIGNMENT, AssignmentTransformer.fetch(response.data))
      }
    })
  },
  fetchStatus: ({ commit, state }, payload) => {
    new AssignmentProxy({}).getStatus(payload || state.id).then((response) => {
      if (response.data.status) {
        commit(FILL_ASSIGNMENT_STATUS, response.data)
      }
    })
  },
  fetchLabelsStatuses: async ({ commit, state }, payload) => {
    const assignmentId = payload || state.id
    const response = await new AssignmentProxy().getLabelsStatuses(assignmentId)
    if (response.data) {
      commit(FILL_ASSIGNMENT_LABELS_STATUSES, response.data)
    }
    return response
  },
  updateServiceLevel: (_, { level, id }) =>
    new Promise((resolve, reject) => {
      const serviceLevel = {
        serviceLevel: level,
      }
      new AssignmentProxy().updateService(id, serviceLevel).then(resolve).catch(reject)
    }),
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    optimizationReport,
    assignmentSummary,
  },
}
