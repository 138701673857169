<template>
  <div class="assignment">
    <div class="assignment-header">
      <div class="d-flex">
        <div class="assignment-header__body">
          <div class="assignment-header__title">Assigment Summary</div>
          <div class="assignment-header__subtitle">
            Here’s a recap of your single package assignment. Please review before creating labels.
          </div>
        </div>
      </div>
    </div>

    <div class="assignment__body">
      <div class="assignment__title">Sender Address</div>
      <div class="assignment__subtitle">
        <span>{{ userName }}</span>
      </div>

      <hr class="delimiter" />

      <div class="assignment__title">Shipping address</div>
      <div class="row mb-3">
        <div class="col-3">
          <div class="assignment__subtitle mb-1">First name</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.firstName }}
          </div>
        </div>
        <div class="col-2">
          <div class="assignment__subtitle mb-1">Last name</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.lastName }}
          </div>
        </div>
        <div class="col-3">
          <div class="assignment__subtitle mb-1">Company</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.company }}
          </div>
        </div>
        <div class="col-1" />
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <div class="assignment__subtitle mb-1">Country</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.country }}
          </div>
        </div>
        <div class="col-2">
          <div class="assignment__subtitle mb-1">City</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.city }}
          </div>
        </div>
        <div class="col-1">
          <div class="assignment__subtitle mb-1">ZIP</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.postalCode }}
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <div class="assignment__subtitle mb-1">Email address</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.email }}
          </div>
        </div>
        <div class="col-2">
          <div class="assignment__subtitle mb-1">Address 1</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.addressLine }}
          </div>
        </div>
        <div class="col-2">
          <div class="assignment__subtitle mb-1">Reference</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.item?.orderReference }}
          </div>
        </div>
        <div class="col-1" />
      </div>
      <div class="row mb-5">
        <div class="col-3">
          <div class="assignment__subtitle mb-1">Phone number</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.phoneNumber }}
          </div>
        </div>
        <div class="col-2">
          <div class="assignment__subtitle mb-1">Address 2</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
            {{ assignment.recipient?.addressLine2 }}
          </div>
        </div>
        <div class="col-1" />
      </div>

      <hr class="delimiter" />

      <div v-if="!isHidePrice">
        <div class="assignment__title">Shipping cost</div>
        <ShippingCostCell v-if="assignment.report?.manualTotal" :report="assignment.report" class="cost" />
      </div>

      <hr class="delimiter" />

      <div v-if="!hasCustomInvoice">
        <div class="assignment__title">Customs Invoice</div>

        <div class="d-flex">
          <div class="assignment__subtitle mb-1">Category:</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey mb-1 ml-2">
            {{ invoiceCategory }}
          </div>
        </div>

        <div v-if="products && products.length > 0" class="row mb-3">
          <div class="col-12">
            <table class="table-report bg-grey-title">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Country</th>
                  <th>Content (in English)</th>
                  <th>Commodity code</th>
                  <th>Unit weight (g)</th>
                  <th>Unit value</th>
                  <th v-text="`Total value (${currency})`" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="'product' + index">
                  <td>{{ product.quantity }}</td>
                  <td>{{ product.countryCode }}</td>
                  <td>{{ product.description }}</td>
                  <td>{{ product.commodityCode }}</td>
                  <td>{{ product.weight }}</td>
                  <td>{{ product.estimatedCost }}</td>
                  <td>
                    {{ product.quantity * Number(product.estimatedCost) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="6" class="border-0" />
                  <td>
                    <div class="total-count">
                      {{ totalInvoiceCosts || 0 }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="delimiter" />
      </div>

      <div v-if="assignment.distributionChannel === PACKAGE">
        <div class="assignment__title">Parcel Characteristics</div>
        <div class="row mb-5">
          <div class="col-2">
            <div class="assignment__subtitle mb-1">Weight</div>
            <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">{{ assignment.item?.weight }}g</div>
          </div>
          <div class="col-2">
            <div class="assignment__subtitle mb-1">Height</div>
            <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">{{ assignment.item?.height }}cm</div>
          </div>
          <div class="col-2">
            <div class="assignment__subtitle mb-1">Length</div>
            <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">{{ assignment.item?.length }}cm</div>
          </div>
          <div class="col-2">
            <div class="assignment__subtitle mb-1">Width</div>
            <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">{{ assignment.item?.width }}cm</div>
          </div>
          <div class="col-2">
            <div class="assignment__subtitle mb-1">Worth</div>
            <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
              {{ assignment.item?.estimatedCost }}kr
            </div>
          </div>
          <div class="col-2">
            <div class="assignment__subtitle mb-1">Description / Ref</div>
            <div class="assignment__subtitle assignment__subtitle_color-grey mb-1">
              {{ assignment.item?.description }}
            </div>
          </div>
        </div>

        <hr class="delimiter" />
      </div>

      <div class="row">
        <div :class="additionalInfosCol">
          <div class="assignment__title">Distribution</div>
          <div class="form-group disabled">
            <input
              id="distribution_channel"
              v-model="assignment.distributionChannel"
              type="radio"
              class="d-none"
              :value="assignment.distributionChannel"
            />
            <label for="service_level_economy" class="radio-group hide-radio">
              <span class="radio-title">{{ distributionChannel }}</span>
            </label>
          </div>
        </div>

        <div v-if="assignment.distributionChannel !== PARCEL" :class="additionalInfosCol">
          <div class="assignment__title">Service level</div>
          <div class="form-group disabled">
            <input
              v-if="assignment.report?.info.serviceLevel"
              id="service_level_economy"
              v-model="assignment.report.info.serviceLevel"
              type="radio"
              class="d-none"
              :value="assignment.report.info.serviceLevel"
            />
            <label for="service_level_economy" class="radio-group hide-radio">
              <span class="radio-title">{{ assignment.report?.info.serviceLevel }}</span>
              <span class="radio-description">
                {{ serviceLevelDesc }}
              </span>
            </label>
          </div>
        </div>

        <div v-if="showPickupCalendar" :class="additionalInfosCol">
          <div class="assignment__title">Pickup Date</div>
          <div class="form-group disabled">
            <label for="service_level_economy" class="radio-group hide-radio">
              <span class="radio-title">{{ assignment.pickupDate }}</span>
              <span class="radio-description">
                {{ assignment.pickupInstructions }}
              </span>
            </label>
          </div>
        </div>

        <div v-if="assignment.item?.deliveryType" :class="additionalInfosCol">
          <div class="assignment__title">Delivery type</div>
          <div class="assignment__subtitle assignment__subtitle_color-grey">
            {{ getDeliveryTypeLabel(assignment.item.deliveryType) }}
          </div>
        </div>
      </div>

      <hr class="delimiter" />

      <div v-if="assignment.report?.info.invoiceReference">
        <div class="assignment__title">Invoice reference</div>
        <div class="assignment__subtitle assignment__subtitle_color-black">
          {{ assignment.report.info.invoiceReference }}
        </div>

        <hr class="delimiter" />
      </div>

      <Open v-if="assignment.status === OPEN" />
      <ReportReleased v-if="assignment.status === REPORT_RELEASED" />
      <ReportAccepted v-else-if="assignment.status === REPORT_ACCEPTED" />
      <LabelsReleased v-else-if="assignment.status === LABELS_RELEASED" />
      <div v-else-if="assignment.status === 'report_denied'">Price is rejected</div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, defineComponent, type Ref, type ComputedRef, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { getDeliveryTypeLabel, getDistributionChannelTxt, getProformaInvoiceTypes } from '@/services/Utils'
import { COMPLETE, LABELS_RELEASED, OPEN, REPORT_ACCEPTED, REPORT_RELEASED } from '@/const/AssignmentStatuses'
import { LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { ECONOMY } from '@/const/AssignmentServiceLevels'
import Open from '@/views/Components/Assignment/SingleItemActions/Open.vue'
import ShippingCostCell from '@/views/Components/Elements/Table/ShippingCostCell.vue'
import ReportReleased from '@/views/Components/Assignment/SingleItemActions/ReportReleased.vue'
import ReportAccepted from '@/views/Components/Assignment/SingleItemActions/ReportAccepted.vue'
import LabelsReleased from '@/views/Components/Assignment/SingleItemActions/LabelsReleased.vue'
import type { Category, SingleAssignment } from '@/types/Models/Assignment'
import type { Product } from '@/types/Models/Product'

export default defineComponent({
  name: 'AssignmentSummary',
  components: {
    Open,
    ShippingCostCell,
    ReportReleased,
    ReportAccepted,
    LabelsReleased,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const products: Ref<Product[]> = ref([])
    const showDownloadButton: Ref<boolean> = ref(true)
    const isLabelCreated: Ref<boolean> = ref(false)
    const hasCustomInvoice: Ref<boolean> = ref(false)
    const currency: Ref<string> = ref('SEK')
    const totalInvoiceCosts: Ref<number> = ref(0)
    const documentsUrl: Ref<string | null> = ref(null)

    const assignmentId: Ref<string> = ref(String(route.params.id))
    const assignment: ComputedRef<SingleAssignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const isHidePrice: ComputedRef<boolean> = computed(() => store.getters['account/isHidePrice'])

    const serviceLevelDesc = computed(() => {
      if (assignment.value.distributionChannel === LETTER) {
        return 'Tracking not available.'
      }

      return assignment.value.serviceLevel === ECONOMY ? '1-4 days, cheapest. Tracking available.' : ''
    })

    const categories: ComputedRef<Category[]> = computed(getProformaInvoiceTypes)

    const invoiceCategory = computed(() => categories.value[assignment.value.item?.invoiceType]?.text)
    const distributionChannel = computed(() => getDistributionChannelTxt(assignment.value.distributionChannel))
    const additionalInfosCol = computed(() => (assignment.value.pickupDate ? 'col-4' : 'col-6'))
    const showPickupCalendar = computed(() => assignment.value.distributionChannel === PARCEL)

    const userName = computed(() => {
      if (!assignment.value.user) {
        return ''
      }

      const { fullName, email, company } = assignment.value.user

      if (!fullName && !email && !company) {
        return ''
      }

      const name = fullName.trim()
      return company || name || email.split('@')[0]
    })

    watch(assignment, () => {
      showDownloadButton.value = assignment.value.status !== COMPLETE
      products.value = assignment.value.item?.products
      isLabelCreated.value = assignment.value.item?.labelsCreationStatus !== 'pending'
      hasCustomInvoice.value = assignment.value.item?.products?.length === 0
      documentsUrl.value = assignment.value.documentsUrl
      currency.value = assignment.value.item?.currency ?? 'EUR'
      totalInvoiceCosts.value = assignment.value.item?.products?.reduce(
        (acc: any, cv: any) => acc + cv.quantity * cv.estimatedCost,
        0,
      )
    })

    onMounted(() => {
      store.dispatch('assignment/assignmentSummary/fetch', assignmentId.value)
    })

    return {
      products,
      showDownloadButton,
      isLabelCreated,
      hasCustomInvoice,
      currency,
      totalInvoiceCosts,
      documentsUrl,
      assignment,
      userName,
      isHidePrice,
      serviceLevelDesc,
      invoiceCategory,
      distributionChannel,
      additionalInfosCol,
      showPickupCalendar,
      getDeliveryTypeLabel,
      PARCEL,
      PACKAGE,
      OPEN,
      LABELS_RELEASED,
      REPORT_ACCEPTED,
      REPORT_RELEASED,
    }
  },
})
</script>

<style lang="scss" scoped>
.centred {
  text-align: center;
}
.assignment {
  &__body {
    background: #ffffff;
    padding: 40px;
    margin-bottom: 128px;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    color: #151515;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #757575;
    margin-bottom: 40px;

    &_color-grey {
      color: #151515;
    }

    &_color-black {
      font-style: normal;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
      mix-blend-mode: normal;
    }
  }

  &-header {
    margin-bottom: 24px;

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      text-transform: capitalize;
      color: #151515;
    }

    &__subtitle {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
    }

    &__body {
      margin-top: -5px;
      margin-left: 35px;
    }
  }

  .form-group {
    pointer-events: none;
    cursor: none;
    margin-bottom: 0;

    &.disabled {
      .radio-group {
        &::before {
          filter: grayscale(2);
          opacity: 0.6;
        }
      }
    }

    .radio-group {
      border: none !important;
      background: none !important;
      padding-left: 30px !important;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 0 40px 0;
      filter: grayscale(1);

      &.hide-radio {
        padding-left: 0 !important;

        &::before {
          display: none;
        }
      }

      &::before {
        top: -1px;
        left: 0;
      }
    }
  }

  .delimiter {
    height: 1px;
    background: #d6d6d1;
    margin: 0 0 40px 0;
  }

  .saved-cost {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #08a762;
    }

    &__percent {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      background: rgba(8, 167, 98, 0.15);
      border-radius: 32px;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 144%;
      color: #08a762;
      padding: 0 5px;
      margin-left: 8px;
    }
  }
}

.cost {
  :deep(.saved-cost__value) {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    /* Green - Success */
    color: #08a762;
  }
}

.table-report {
  thead {
    th {
      font-size: 13px;
    }
  }
}
</style>
