<template>
  <ul v-if="isLabelsCreated" class="footer-errors mb-0">
    <li>Labels: {{ getLabelsSuccessCreatedCount }} / {{ assignment.itemsCount }}</li>
    <li>
      <div class="errors-slots">
        Errors:
        <mdicon name="Square" class="mx-2" />
        {{ labelsNotCreatedCount }}
        {{ pluralize('slot', labelsNotCreatedCount) }}
      </div>
    </li>
    <li>
      <div class="errors-type">
        <p>View type</p>
        <div class="switch-error">
          All
          <ToggleBtn v-model="isErrorsOnly" value="" />
          Errors only
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { pluralize } from '@/services/Helpers'
import { TABLE_NAME_ASSIGNMENT_ITEMS } from '@/const/TableNames'
import { LABELS_CREATED } from '@/const/AssignmentStatuses'
import ToggleBtn from '@/views/Components/Elements/Buttons/ToggleBtn.vue'

export default defineComponent({
  name: 'LabelsCreatedErrors',
  components: { ToggleBtn },
  setup() {
    const store = useStore()

    const assignment = computed(() => store.getters['assignment/get'])
    const status = computed(() => store.getters['assignment/getStatus'])
    const labelsNotCreatedCount = computed(() => store.getters['assignment/getLabelsNotCreatedCount'])
    const getLabelsSuccessCreatedCount = computed(() => store.getters['assignment/getLabelsSuccessCreatedCount'])
    const isLabelsCreated = computed(() => status.value === LABELS_CREATED)

    const isErrorsOnly = computed({
      get: () => store.getters['item/labelsErrorsOnly'],
      set: (errorsOnly: boolean) => {
        store.dispatch('item/setLabelsErrorsOnly', errorsOnly)
      },
    })

    watch(labelsNotCreatedCount, (newVal) => {
      if (newVal.length === 0 && store.getters['item/labelsErrorsOnly']) {
        store.dispatch('item/setLabelsErrorsOnly', false)
      }
    })

    return {
      assignment,
      labelsNotCreatedCount,
      getLabelsSuccessCreatedCount,
      isErrorsOnly,
      isLabelsCreated,
      pluralize,
    }
  },
})
</script>
