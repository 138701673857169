import { CUSTOMER, STAFF } from '@/const/AccountTypes'
import {
  CANCELLED,
  COMPLETE,
  IN_REVIEW,
  LABELS_CREATED,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPTIMISATION_COMPLETE,
  OPTIMISING,
  OPEN,
  PENDING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATING,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'

export const ActionColor = {
  [STAFF]: {
    [OPEN]: 'button-gray',
    [PENDING]: 'button-gray',
    [COMPLETE]: 'button-green',
    [CANCELLED]: 'button-light-red',
    [VALIDATING]: 'button-gray',
    [VALIDATION_COMPLETE]: 'button-black',
    [IN_REVIEW]: 'button-light-red',
    [OPTIMISING]: 'button-gray',
    [OPTIMISATION_COMPLETE]: 'button-yellow',
    [REPORT_RELEASED]: 'button-gray',
    [REPORT_ACCEPTED]: 'button-lettuce-green',
    [REPORT_DENIED]: 'button-light-red',
    [LABELS_CREATED]: 'button-lettuce-green',
    [LABELS_CREATING]: 'button-gray',
    [LABELS_RELEASED]: 'button-green',
  },
  [CUSTOMER]: {
    [OPEN]: 'button-gray',
    [PENDING]: 'button-gray',
    [COMPLETE]: 'button-green',
    [CANCELLED]: 'button-light-red',
    [VALIDATING]: 'button-gray',
    [VALIDATION_COMPLETE]: 'button-black',
    [IN_REVIEW]: 'button-gray',
    [OPTIMISING]: 'button-gray',
    [OPTIMISATION_COMPLETE]: 'button-gray',
    [REPORT_RELEASED]: 'button-yellow',
    [REPORT_ACCEPTED]: 'button-yellow',
    [REPORT_DENIED]: 'button-light-red',
    [LABELS_CREATED]: 'button-gray',
    [LABELS_CREATING]: 'button-gray',
    [LABELS_RELEASED]: 'button-green',
  },
}
