<template>
  <div class="parcel-row mt-3" :class="{ 'in-modal': inModal, 'with-worth': showEstimatedCost }">
    <div class="parcel-field">
      <label class="required">Weight</label>
      <VInput
        v-model="localParcel.weight"
        type="number"
        unit="g"
        placeholder="Weight"
        :vid="`weight${index}`"
        :name="`Weight[${index}]`"
        :rules="{
          required: true,
          min_value: fieldsLimits.weight.min,
          max_value: fieldsLimits.weight.max,
        }"
        :custom-messages="{
          min_value: `Weight must be greater or equal ${fieldsLimits.weight.min} grams`,
          max_value: `Weight must be less or equal ${fieldsLimits.weight.max} grams`,
        }"
        @input="(val: string) => updateParcelData('weight', val)"
      />
    </div>

    <div class="parcel-field">
      <label class="required">Height</label>
      <VInput
        v-model="localParcel.height"
        type="number"
        unit="cm"
        placeholder="Height"
        :name="`Height[${index}]`"
        :vid="`height${index}`"
        :rules="{
          required: true,
          min_value: fieldsLimits.height.min,
          max_value: fieldsLimits.height.max,
        }"
        :custom-messages="{
          min_value: `Height must be greater or equal ${fieldsLimits.height.min} cm`,
          max_value: `Height must be less or equal ${fieldsLimits.height.max} cm`,
        }"
        @input="(val: string) => updateParcelData('height', val)"
      />
    </div>

    <div class="parcel-field">
      <label class="required">Length</label>
      <VInput
        v-model="localParcel.length"
        type="number"
        placeholder="Length"
        unit="cm"
        :name="`Length[${index}]`"
        :vid="`length${index}`"
        :rules="{
          required: true,
          min_value: fieldsLimits.length.min,
          max_value: fieldsLimits.length.max,
        }"
        :custom-messages="{
          min_value: `Length must be greater or equal ${fieldsLimits.length.min} cm`,
          max_value: `Length must be less or equal ${fieldsLimits.length.max} cm`,
        }"
        @input="(val: string) => updateParcelData('length', val)"
      />
    </div>

    <div class="parcel-field">
      <label class="required">Width</label>
      <VInput
        v-model="localParcel.width"
        type="number"
        unit="cm"
        placeholder="Width"
        :name="`Width[${index}]`"
        :vid="`width${index}`"
        :rules="{
          required: true,
          min_value: fieldsLimits.width.min,
          max_value: fieldsLimits.width.max,
        }"
        :custom-messages="{
          min_value: `Width must be greater or equal ${fieldsLimits.width.min} cm`,
          max_value: `Width must be less или equal ${fieldsLimits.width.max} cm`,
        }"
        @input="(val: string) => updateParcelData('width', val)"
      />
    </div>

    <div v-if="showEstimatedCost" class="parcel-field">
      <label class="required">Worth</label>
      <VInput
        v-model="localParcel.estimated_cost"
        type="number"
        placeholder="Worth"
        :rules="{ required: true, min_value: 1 }"
        :name="`Worth[${index}]`"
        :vid="`estimated_cost${index}`"
        unit="kr"
        @input="(val: string) => updateParcelData('estimated_cost', val)"
      />
    </div>

    <div class="parcel-field parcel-field-wider">
      <label>
        <Tooltip text="Reference or shipment description, that will be placed on the shipping label">
          Description / Ref
          <i class="fa fa-question-circle" />
        </Tooltip>
      </label>
      <VInput
        v-model="localParcel.description"
        type="text"
        placeholder="Description / Ref"
        :name="`Description[${index}sub]`"
        :vid="`description_${index}`"
        @input="(val: string) => updateParcelData('description', val)"
      />
    </div>

    <div class="parcel-field">
      <label>Commodity Code</label>
      <VInput
        v-model="localParcel.commodity_code"
        type="text"
        placeholder="Commodity Code"
        :name="`Code[${index}]`"
        :vid="`commodity_code${index}`"
        @input="(val: string) => updateParcelData('commodity_code', val)"
      />
    </div>

    <div class="action-btns">
      <b-button v-if="showCopyBtn" class="copy-btn" @click="copy">
        <mdicon name="ContentCopy" />
      </b-button>

      <b-button v-if="showRemoveBtn" @click="remove">
        <mdicon name="TrashCanOutline" />
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watch } from 'vue'
import { FieldsLimits, ParcelCharacteristic } from '@/types/Models/Assignment'
import VInput from '@/views/Components/Elements/Form/VInput.vue'

export default defineComponent({
  name: 'ParcelCharacteristicsRow',
  components: {
    VInput,
  },
  props: {
    parcel: {
      type: Object as PropType<ParcelCharacteristic>,
      required: true,
    },
    countryCode: {
      type: String,
      default: 'SE',
    },
    showRemoveBtn: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    showEstimatedCost: {
      type: Boolean,
      default: false,
    },
    fieldsLimits: {
      type: Object as PropType<FieldsLimits>,
      required: true,
    },
    showCopyBtn: {
      type: Boolean,
      default: false,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['parcel-removed', 'parcel-copied', 'parcel-updated'],
  setup(props, { emit }) {
    const localParcel = ref({ ...props.parcel })

    const remove = () => {
      emit('parcel-removed', props.index)
    }

    const copy = () => {
      emit('parcel-copied', props.index)
    }

    const updateParcelData = (field: string, value: string) => {
      if (value) {
        localParcel.value[field] = value
        emit('parcel-updated', props.index, field, value)
      }
    }

    watch(
      () => props.parcel,
      (newVal) => {
        localParcel.value = { ...newVal }
      },
    )

    return {
      localParcel,
      remove,
      copy,
      updateParcelData,
    }
  },
})
</script>

<style lang="scss" scoped>
.parcel-row {
  width: 100%;
  position: relative;
  display: flex;

  .parcel-field {
    width: 18%;
    margin-right: 25px;
  }

  .action-btns {
    text-align: end;
    padding-top: 30px;
    width: 18%;

    .btn {
      padding: 8px;
      color: #000;
      background-color: #fff;
      border: 1px solid #cccccc;

      &.copy-btn {
        margin-right: 15px;
      }
    }
  }

  label {
    color: #151515;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &.in-modal {
    .parcel-field {
      :deep(input.form-control, input.form-control.is-invalid) {
        padding-right: calc(1em + 0.75rem);
      }
    }

    .action-btns {
      width: 14%;
    }
  }

  &.with-worth {
    .parcel-field {
      width: 15%;

      &.parcel-field-wider {
        width: 18%;
      }
    }

    .action-btns {
      width: 14%;
    }
  }
}
</style>
