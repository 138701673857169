<template>
  <tr>
    <td>
      <RowDate :date="event.eventTime" />
    </td>
    <td>
      {{ trackingStatus2Name(event.status, '') }}
    </td>
    <td>
      {{ event.description }}
      <div v-if="event.locationName" class="text-muted mt-1">
        <span class="fa fa-map-pin" />
        {{ event.locationName }}
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { trackingStatus2Name } from '@/services/Helpers'
import RowDate from '@/views/Components/Elements/Table/RowDate.vue'
import type { TrackingEvent } from '@/types/Models/Tracking'

export default defineComponent({
  name: 'TrackingEvent',
  components: { RowDate },
  props: {
    event: {
      type: Object as PropType<TrackingEvent>,
      required: true,
    },
  },
  setup() {
    return {
      trackingStatus2Name,
    }
  },
})
</script>
