import { LABELS_CREATED, LABELS_CREATING, LABELS_RELEASED } from '@/const/AssignmentStatuses'
import { LABELS_CREATED_SUCCESS, LABELS_CREATED_WITH_ERRORS } from '@/const/LabelsCreatedStatuses'
import { GREEN, GREEN_SUCCESS, GREY } from '@/const/Colors'
import { getDefaultToDo } from '@/services/Assignment/DefaultToDo'
import StaffActions from '@/services/Assignment/Actions/StaffActions'
import DefaultActions from '@/services/Assignment/Actions/DefaultActions'

export default () => {
  const defaultToDo = getDefaultToDo()
  return {
    [LABELS_CREATED]: {
      color: GREEN,
      text: 'Labels Created',
      toDo: defaultToDo.VIEW_LABELS,
      itemsActions: [
        {
          icon: 'Download',
          variant: 'link',
          text: 'Download labels',
          style: {},
          class: ['green-color', 'green-outline'],
          handler: () => {
            DefaultActions.downloadLabels()
          },
        },
        {
          showIfLabelCreatedStatus: LABELS_CREATED_SUCCESS,
          text: 'Release Labels',
          color: GREEN_SUCCESS,
          handler: () => {
            StaffActions.submitLabelsRelease()
          },
        },
        {
          showIfLabelCreatedStatus: LABELS_CREATED_WITH_ERRORS,
          text: 'Create missing labels',
          color: GREEN,
          handler: () => {
            StaffActions.createMissingLabels()
          },
        },
      ],
    },
    [LABELS_CREATING]: {
      color: GREY,
      text: 'Labels Creating',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
      infoNotify: {
        text: 'Creating labels. We will notify you when labels are ready to download.',
        class: ['success'],
      },
    },
    [LABELS_RELEASED]: {
      color: GREEN_SUCCESS,
      text: 'Labels Released',
      toDo: defaultToDo.VIEW_LABELS,
      itemsActions: [
        {
          icon: 'Download',
          variant: 'link',
          text: 'Download labels',
          style: {},
          class: ['green-color', 'green-outline'],
          handler: () => {
            DefaultActions.downloadLabels()
          },
        },
      ],
    },
  }
}
