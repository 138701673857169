import { PENDING, COMPLETE, CANCELLED, VALIDATING, OPEN } from '@/const/AssignmentStatuses'
import { GREEN_SUCCESS, GREY, RED } from '@/const/Colors'
import { getDefaultToDo } from '@/services/Assignment/DefaultToDo'

export default () => {
  const defaultToDo = getDefaultToDo()
  return {
    [OPEN]: {
      color: GREY,
      text: 'Buffering',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
    },
    [PENDING]: {
      color: GREY,
      text: 'Validating',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
    },
    [COMPLETE]: {
      color: GREEN_SUCCESS,
      text: 'Shipped',
      toDo: { ...defaultToDo.VIEW_ASSIGNMENT, color: GREY },
    },
    [CANCELLED]: {
      color: RED,
      text: 'Cancelled',
      toDo: { ...defaultToDo.VIEW_ASSIGNMENT, color: GREY },
      infoNotify: {
        text: 'Assignment archived. It will not work to correct the assignment',
        class: ['danger'],
      },
    },
    [VALIDATING]: {
      color: GREY,
      text: 'Validating',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
      infoNotify: {
        text: 'Validation process in progress',
        class: ['secondary'],
      },
    },
  }
}
