import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'

export default class Statistics extends applyProxyMethodsMixin(class {}) implements ProxyMethods {
  public endpoint = 'statistics'

  getAdvancedFinance() {
    return this.submit('get', `/${this.endpoint}/finance-advanced`)
  }

  advancedFinanceExport() {
    return this.submit('get', `/${this.endpoint}/finance-advanced/export`)
  }
}
