/* ============
 * Assignment List Transformer
 * ============
 *
 * The transformer for the Assignment in the list.
 */

import ItemTransformer from '@/transformers/ItemTransformer'
import Transformer from './Transformer'
import type { ListAssignment, ListAssignmentResponse } from '@/types/Models/Assignment'

export default class AssignmentsListTransformer extends Transformer {
  /**
   * Method used to transform a fetched Assignment.
   */
  static fetch(data: ListAssignmentResponse): ListAssignment {
    return {
      id: data.id,
      reference: data.reference,
      status: data.status,
      serviceLevel: data.serviceLevel,
      itemsCount: data.domesticItemsCount + data.internationalItemsCount,
      item: data.item ? ItemTransformer.fetch(data.item) : null,
      domesticItemsCount: data.domesticItemsCount,
      internationalItemsCount: data.internationalItemsCount,
      documentsUrl: data.documentsUrl,
      sourceFileName:
        data.sourceFileName || (data.sourceFiles && data.sourceFiles.length > 0 ? data.sourceFiles[0]?.name : ''),
      sourceFileUrl:
        data.sourceFileUrl || (data.sourceFiles && data.sourceFiles.length > 0 ? data.sourceFiles[0]?.fileUrl : ''),
      labelsDownloadUrl: data.labelsDownloadUrl,
      labelsDownloadStatus: data.labelsDownloadStatus,
      createdAt: data.createdAt,
      pickupDate: data.pickupDate,
      updatedAt: data.updatedAt,
      labelsCreatedAt: data.labelsCreatedAt,
      optimisationReportUrl: data.optimisationReportUrl || null,
      customerReference: data.customerReference || null,
      hasCustomPrices: data.hasCustomPrices || false,
      hasCustomPriorities: data.hasCustomPriorities || false,
      archived: data.archived || false,
      report: data.report || {},
      type: data.type || 'single',
      hasDocuments: data.hasDocuments || false,
      carriers: data.carriers || [],
      carriersSummary: data.carriersSummary || {},
      recipient: data.recipient || {},
      user: data.user || {},
      hidePrice: data.user?.hidePrice || false,
      source: data.source || '',
      distributionChannel: data.distributionChannel || '',
      pickupInstructions: data.pickupInstructions || '',
      denyLabelsDownload: data.denyLabelsDownload,
    }
  }
}
