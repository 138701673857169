<template>
  <div>
    <div v-if="insufficientStatus">-</div>
    <div v-else>
      <div v-if="assignment.type === SINGLE">
        <CarrierRowWithIcon :carrier="assignment.item?.carrierHandle" :only-icon="false" />
        <div v-if="assignment.item?.shipmentNumber">
          <small class="text-success">{{ assignment.item.shipmentNumber }}</small>
        </div>
      </div>
      <div v-else class="d-flex">
        <div v-for="carrier of assignment.carriers" :key="carrier" class="mr-1">
          <CarrierRowWithIcon :carrier="carrier" :only-icon="assignment.carriers.length > 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { SINGLE } from '@/const/AssignmentTypes'
import { IN_REVIEW, OPEN, OPTIMISING, PENDING, VALIDATING, VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'CarriersCell',
  components: { CarrierRowWithIcon },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const statuses = [OPEN, PENDING, VALIDATING, VALIDATION_COMPLETE, IN_REVIEW, OPTIMISING]

    const insufficientStatus = computed(() => statuses.includes(props.assignment.status))

    return {
      SINGLE,
      insufficientStatus,
    }
  },
})
</script>
