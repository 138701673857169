<template>
  <modal-wrap
    :id="id"
    ref="integrationsOrders"
    :name="modalName"
    :header-class="'integrations-orders-header'"
    body-class="integrations-orders-modal"
    :hide-footer="true"
    size="lg"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">Orders</h4>
      </div>
    </template>
    <template #default>
      <div class="w-100">
        <div class="w-100 d-flex justify-content-between mb-3 filters">
          <TableTitle icon="file-document-outline" title="Orders" />
        </div>
        <easy-data-table
          ref="dataTable"
          v-model:server-options="serverOptions"
          table-class-name="vue3-easy-data-table table-shadow"
          :server-items-length="serverItemsLength"
          :loading="loading"
          :headers="headers"
          :items="items"
          :prevent-context-menu-row="false"
          :fixed-header="false"
          no-hover
          alternating
          hide-footer
          @update:server-options="updateServerOptions"
        >
          <template #loading>
            <div style="text-align: center">Loading...</div>
          </template>
          <template #no-data>
            <div style="text-align: center">No data available</div>
          </template>
          <template #item="{ column, item }">
            <span v-if="column === 'externalId'">{{ item.externalId }}</span>
            <span v-if="column === 'assignmentId'">{{ item.assignmentId }}</span>
            <span v-if="column === 'status'">{{ item.status }}</span>
            <span v-if="column === 'error'">{{ item.error }}</span>
          </template>
        </easy-data-table>

        <TablePagination
          v-model:server-options-ref="serverOptions"
          v-model:data-table-ref="dataTable"
          :server-items-length="serverItemsLength"
        />
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { defineComponent, ref, computed, watch } from 'vue'
import { INTEGRATION_ORDERS } from '@/const/ModalTypes'
import TableTitle from '@/views/Components/Elements/Table/TableTitle.vue'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import EasyDataTable from 'vue3-easy-data-table'
import IntegrationOrderTransformer from '@/transformers/IntegrationOrderTransformer'
import type { Ref } from 'vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'

export default defineComponent({
  name: 'IntegrationsOrdersModal',
  components: {
    TablePagination,
    TableTitle,
    ModalWrap,
    EasyDataTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const dataTable = ref(null)
    const items: Ref<any[]> = ref([])
    const error = ref(false)
    const loading = ref(false)
    const serverItemsLength = ref(0)
    const checkedOrders = ref([])
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    })

    const headers = computed(() => [
      { text: 'External ID', value: 'externalId' },
      { text: 'Assignment ID', value: 'assignmentId' },
      { text: 'Status', value: 'status' },
      { text: 'Error', value: 'error' },
    ])

    const modalName = INTEGRATION_ORDERS
    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName))

    const loadFromServer = async () => {
      if (!modalData.value.customerId || !modalData.value.integrationId) {
        serverItemsLength.value = 0
        items.value = []
        error.value = false
        loading.value = false
        return
      }
      loading.value = true
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
      }

      try {
        const response = await store.dispatch('customers/integrations/runActions', {
          customerId: modalData.value.customerId,
          id: modalData.value.integrationId,
          action: 'get-mapping',
          params,
        })
        items.value = IntegrationOrderTransformer.fetchCollection(response.data || [])
        serverItemsLength.value = response.meta?.pagination.total || 0
      } catch (e) {
        serverItemsLength.value = 0
        items.value = []
        error.value = true
      } finally {
        loading.value = false
      }
    }

    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    watch(serverOptions, loadFromServer)
    watch(modalData, loadFromServer)

    return {
      dataTable,
      error,
      items,
      loading,
      headers,
      serverOptions,
      serverItemsLength,
      checkedOrders,
      updateServerOptions,
      modalName,
      modalData,
    }
  },
})
</script>
