<template>
  <div>
    <AssignmentForm />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'CreateAssignmentPage',
  components: {
    AssignmentForm: defineAsyncComponent(() => import('@/views/Components/Assignment/AssignmentForm.vue')),
  },
})
</script>
