<template>
  <VSelect
    :id="vinputId"
    :value="value"
    :options="countries"
    :rules="computedRules"
    :placeholder="placeholder"
    :name="name"
    @update:model-value="onUpdate"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, Ref } from 'vue'
import VSelect from '@/views/Components/Elements/Form/VSelect.vue'
import allCountries from '@/assets/files/countries.json'
import type { Country } from '@/types/Models/Customer'

export default defineComponent({
  name: 'CountryPicker',
  components: { VSelect },
  props: {
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputRef: {
      type: String,
      default: 'country',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    vinputId: {
      type: String,
      default: 'country-picker',
    },
    vid: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Country',
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const input = ref<HTMLElement | null>(null)

    const countries: Ref<Country[]> = ref(allCountries)

    const computedRules = computed(() => ({
      required: props.required,
      countryCode: props.required,
    }))

    const onUpdate = (value: string) => {
      emit('change', value)
    }

    return {
      input,
      countries,
      computedRules,
      onUpdate,
    }
  },
})
</script>
