<template>
  <div>
    <b-badge v-if="insufficientStatus" variant="warning"> Pending </b-badge>
    <div v-else class="d-flex align-items-center saved-cost">
      <template v-if="!hidePrices.value || isStaff.value">
        <span class="saved-cost__value"> {{ assignment.report.optimizationCost }} kr. </span>
        <span class="saved-cost__percent"> {{ assignment.report.percentSaved }}% </span>
      </template>
      <template v-else>
        <span class="saved-cost__value">
          {{ assignment.report.manualTotal ? assignment.report.manualTotal + ' kr.' : '' }}
        </span>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { IN_REVIEW, OPEN, OPTIMISING, PENDING, VALIDATING, VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'CostCell',
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const hidePrices = computed(() => store.getters['account/isHidePrice'])

    const statuses = [OPEN, PENDING, VALIDATING, VALIDATION_COMPLETE, IN_REVIEW, OPTIMISING]

    const insufficientStatus = computed(() => statuses.includes(props.assignment.status))

    return {
      insufficientStatus,
      isStaff,
      hidePrices,
    }
  },
})
</script>

<style lang="scss" scoped>
.saved-cost {
  display: flex;
  align-items: center;
  span {
    color: #08a762;
  }
  .saved-cost__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background: rgba(8, 167, 98, 0.15);
    border-radius: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 144%;
    color: #08a762;
    padding: 0 5px;
    margin-left: 8px;
  }
}
</style>
