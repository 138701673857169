<template>
  <div class="details-block">
    <div v-if="isSingle" class="pt-1 pb-1">
      <div>
        {{ addressString(assignment.recipient) }}
      </div>

      <div v-if="assignment.item">
        <small class="text-muted mr-2">
          {{ addressOwner(assignment.recipient) }}
        </small>
        <b-badge variant="secondary" class="mr-2"> {{ assignment.item.weight }} g. </b-badge>
        <b-badge variant="secondary">
          {{ assignment.item.length }}x{{ assignment.item.width }}x{{ assignment.item.height }}cm
        </b-badge>
      </div>

      <div>
        <small v-if="assignment.recipient.phoneNumber" class="text-muted mr-2">
          {{ assignment.recipient.phoneNumber }}
        </small>
        <small v-if="assignment.recipient.email" class="text-muted">
          {{ assignment.recipient.email }}
        </small>
      </div>
    </div>
    <div v-else>
      <b-badge variant="success"> {{ assignment.itemsCount }} addresses </b-badge>
      <div v-if="assignment.sourceFileName" class="pt-1">
        <small class="text-muted">
          {{ fileNameTrim(assignment.sourceFileName) }}
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { addressOwner, addressString, fileNameTrim } from '@/services/Helpers'
import { SINGLE } from '@/const/AssignmentTypes'
import type { ListAssignment } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'AssignmentDetailsCell',
  props: {
    assignment: {
      type: Object as PropType<ListAssignment>,
      required: true,
    },
  },
  setup(props) {
    const isSingle = computed(() => props.assignment.type === SINGLE)

    return {
      isSingle,
      fileNameTrim,
      addressString,
      addressOwner,
    }
  },
})
</script>

<style scoped lang="scss">
.details-block {
  width: 250px;
  white-space: normal !important;
}
</style>
