<template>
  <CarrierData :shipment="shipment" :only-icon="multipleCarriers" />
</template>

<script lang="ts">
import CarrierData from '@/views/Components/Shipments/CarrierData.vue'
import { computed, defineComponent, PropType } from 'vue'
import type { Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'ShipmentsCarrierCell',
  components: { CarrierData },
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const multipleCarriers = computed(() => Boolean(props.shipment.adjustedCarrierHandle))

    return {
      multipleCarriers,
    }
  },
})
</script>
