<template>
  <div class="row">
    <div class="col-6">
      <button class="btn create-btn btn-green w-100" @click="acceptPriceAndCreateLabels">
        Accept price and create labels
      </button>
    </div>
    <!-- todo - reenable when editing assignment form is fixed -->
    <div v-if="false" class="col-2">
      <button class="btn create-btn btn-grey w-100" @click="edit">Edit</button>
    </div>
    <div class="col-6">
      <button class="btn create-btn btn-danger w-100" @click="rejectPrice">Reject price</button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent } from 'vue'
import type { SingleAssignment } from '@/types/Models/Assignment'
import { useStore } from 'vuex'
import { LABELS_CREATING_LOADER } from '@/const/LoadersNames'
import { notify } from '@kyvg/vue3-notification'
import { CUSTOMER_ASSIGNMENTS_SINGLE_EDIT, STAFF_ASSIGNMENTS_SINGLE_EDIT } from '@/types/router'
import { useRouter } from 'vue-router'
import { FETCH_ASSIGNMENT_SUMMARY } from '@/store/modules/assignment/assignmentSummary'
import AssignmentTransformer from '@/transformers/AssignmentTransformer'

export default defineComponent({
  name: 'ReportReleased',
  setup() {
    const store = useStore()
    const router = useRouter()

    const isStaff = computed(() => store.getters['account/isStaff'])
    const assignment: ComputedRef<SingleAssignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const acceptPriceAndCreateLabels = () => {
      store
        .dispatch('assignment/submitReportAccept', assignment.value.id)
        .then(() => {
          store.dispatch('ui/loaderShow', {
            name: LABELS_CREATING_LOADER,
            data: { progress: 0 },
          })

          store.dispatch('assignment/createLabels', { id: assignment.value.id }).then((response) => {
            store.commit(
              `assignment/assignmentSummary/${FETCH_ASSIGNMENT_SUMMARY}`,
              AssignmentTransformer.fetch(response.data),
            )
            store.dispatch('ui/loaderHide')
          })
        })
        .catch(() => {
          notify({ group: 'error', text: 'Labels Create Error' })
        })
    }

    const edit = () => {
      const pageName = isStaff.value ? STAFF_ASSIGNMENTS_SINGLE_EDIT : CUSTOMER_ASSIGNMENTS_SINGLE_EDIT

      router.push({
        name: pageName,
        params: {
          id: assignment.value.id,
        },
      })
    }

    const rejectPrice = () => {
      store.dispatch('assignment/submitReportDeny', assignment.value.id).then(() => {
        store.dispatch('assignment/assignmentSummary/fetch', assignment.value.id)
        notify({ group: 'success', text: 'Price has been rejected' })
      })
    }

    return {
      acceptPriceAndCreateLabels,
      edit,
      rejectPrice,
    }
  },
})
</script>
