import {
  CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
  CUSTOMER_ASSIGNMENTS_SINGLE,
  STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT,
  STAFF_ASSIGNMENTS_SINGLE,
} from '@/types/router'
import { DOWNLOAD_LABELS } from '@/const/ModalTypes'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'
import { none } from '@/services/Helpers'
import store from '@/store'
import router from '@/router'

export default class DefaultActions {
  static submitReportDeny() {
    openModal('confirmWrap', {
      title: 'Deny Report',
      message: 'Are you sure you want to deny report?',
      okButtonText: 'Deny report',
      onConfirm: () => {
        store.dispatch('assignment/submitReportDeny').then(() => {
          store.dispatch('assignment/fetch').then(() => {
            DefaultActions.toAssignmentSinglePage()
          })
          notify({
            group: 'success',
            text: 'The report was successfully denied.',
          })
        })
      },
    })
  }

  static processAssignment() {
    openModal('confirmWrap', {
      title: 'Process the assignment',
      message: 'Are you sure you want to process the assignment? This action will start the optimization process.',
      okButtonText: 'Process',
      onConfirm: () => {
        store
          .dispatch('assignment/submitProcess')
          .then(() => {
            store.dispatch('assignment/fetch').then(() => {
              DefaultActions.toAssignmentSinglePage()
            })
          })
          .catch((e) => {
            notify({
              group: 'error',
              text: 'The assignment could not be processed. ' + e.message,
            })
          })
      },
    })
  }

  static toAssignmentSinglePage() {
    const isStaff = store.getters['account/isStaff']
    router
      .push({
        name: isStaff ? STAFF_ASSIGNMENTS_SINGLE : CUSTOMER_ASSIGNMENTS_SINGLE,
        params: {
          id: store.state.assignment.id,
        },
      })
      .then(none)
  }

  static toReportPage() {
    const isStaff = store.getters['account/isStaff']
    router
      .push({
        name: isStaff ? STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT : CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
        params: {
          id: store.state.assignment.id,
        },
      })
      .then(none)
  }

  static downloadLabels() {
    openModal(DOWNLOAD_LABELS, { ids: [store.state.assignment.id] })
  }
}
