<template>
  <div class="table-shadow bg-dark-green-title table-editable">
    <table class="table-striped table bg-table-cell-dark">
      <thead>
        <tr>
          <th>Vendor</th>
          <th>Area</th>
          <th>Service level</th>
          <th>Amount</th>
          <th>Country Group</th>
          <th>Price per piece</th>
          <th>Price per kg</th>
          <th>Minimum price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="price in basePrices" :key="price.id">
          <td>
            {{ carrierHandler2Name(price.carrierHandle) }}
          </td>
          <td>
            {{ ucFirst(price.destination) }}
          </td>
          <td>
            {{ ucFirst(price.serviceLevel) }}
          </td>
          <td>>{{ price.minPackagesAmount }}</td>
          <td>
            {{ formatCountry(price.countryGroup) }}
          </td>
          <td v-if="!isWeightMode(price)" class="p-0">
            <div class="form-group form-group_bg_white">
              <input
                v-model.number="price.pricePerPiece"
                type="number"
                class="form-control form-control_hide_arrow"
                step="0.01"
                @input="updatePrice(price, 'pricePerPiece')"
              />
              <div
                v-if="getBasePrice(price) && getBasePrice(price).pricePerPiece && isHasDefaultPrices"
                class="base-price"
              >
                {{ getBasePrice(price).pricePerPiece }}
              </div>
            </div>
          </td>
          <td v-if="!isWeightMode(price)" colspan="1" class="p-0">
            <div class="form-group form-group_bg_white">
              <input
                v-model.number="price.pricePerKg"
                type="number"
                class="form-control form-control_hide_arrow"
                step="0.01"
                @input="updatePrice(price, 'pricePerKg')"
              />
              <div
                v-if="getBasePrice(price) && getBasePrice(price).pricePerKg && isHasDefaultPrices"
                class="base-price"
              >
                {{ getBasePrice(price).pricePerKg }}
              </div>
            </div>
          </td>
          <td v-if="!isWeightMode(price)" colspan="1" class="p-0">
            <div class="form-group form-group_bg_white">
              <input
                v-model.number="price.minPrice"
                type="number"
                class="form-control form-control_hide_arrow"
                step="0.01"
                @input="updatePrice(price, 'minPrice')"
              />
              <div v-if="getBasePrice(price) && getBasePrice(price).minPrice && isHasDefaultPrices" class="base-price">
                {{ getBasePrice(price).minPrice }}
              </div>
            </div>
          </td>
          <td v-if="isWeightMode(price)" class="p-0" colspan="3">
            <button
              class="btn btn-success btn-lg btn-bright-green"
              @click="
                openModal(EDIT_PRICE_WEIGHT, {
                  price,
                  isHasDefaultPrices,
                })
              "
            >
              Apply custom logic
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <EditBasePriceWeight :id="EDIT_PRICE_WEIGHT" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { carrierHandler2Name, ucFirst } from '@/services/Helpers'
import { openModal, registerModals } from '@/services/Modal'
import { EDIT_PRICE_WEIGHT } from '@/const/ModalTypes'
import EditBasePriceWeight from '@/views/Components/BasePrice/EditBasePriceWeight.vue'
import type { BasePrice } from '@/types/Models/BasePrice'

export default defineComponent({
  name: 'BasePriceTable',
  components: { EditBasePriceWeight },
  props: {
    prices: {
      type: Array,
      required: true,
    },
    defaultPrices: {
      type: Array as () => BasePrice[],
      default: () => [],
    },
  },
  emits: ['update-price'],
  setup(props, { emit }) {
    const basePrices = computed<BasePrice[]>(() => JSON.parse(JSON.stringify(props.prices)))
    const isHasDefaultPrices = computed(() => props.defaultPrices.length > 0)
    const numberFormat = (value: string) => {
      if (value.indexOf('.') === -1) {
        return value
      }
      return value.replace('-', '').slice(0, value.indexOf('.') + 2 + 1)
    }

    const updatePrice = (price: BasePrice, key: keyof BasePrice) => {
      const basePrice: BasePrice = { ...price }
      if (key === 'pricePerPiece' || key === 'pricePerKg' || key === 'minPrice') {
        basePrice[key] = Number(price[key])
        emit('update-price', basePrice)
      }
    }

    const isWeightMode = (price: BasePrice) => price.mode === 'weight'

    const formatCountry = (country: string) => {
      if (!country) {
        return ''
      }
      return country === 'eu' ? 'European Union' : 'Other Countries'
    }

    const getBasePrice: any = (price: BasePrice | null) => {
      const basePrice = props.defaultPrices.find(
        (el) =>
          el?.minPackagesAmount === price?.minPackagesAmount &&
          el?.countryGroup === price?.countryGroup &&
          el?.carrierHandle === price?.carrierHandle &&
          el?.destination === price?.destination &&
          el?.serviceLevel === price?.serviceLevel,
      )
      return basePrice || {}
    }

    onMounted(() => {
      registerModals(EDIT_PRICE_WEIGHT)
    })

    return {
      basePrices,
      isHasDefaultPrices,
      openModal,
      numberFormat,
      updatePrice,
      isWeightMode,
      formatCountry,
      getBasePrice,
      carrierHandler2Name,
      ucFirst,
      EDIT_PRICE_WEIGHT,
    }
  },
})
</script>

<style lang="scss" scoped>
.table-striped td {
  white-space: nowrap;
}
.btn-bright-green {
  width: 100%;
}
.form-group {
  position: relative;
}
.base-price {
  position: absolute;
  top: 50%;
  right: 5%;
  display: flex;
  min-width: 60px;
  transform: translate(-5%, -50%);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #757575;
  &::before {
    display: block;
    content: '';
    width: 1px;
    background: #cccccc;
    margin-right: 15px;
  }
}
.form-control_hide_arrow::-webkit-outer-spin-button,
.form-control_hide_arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control_hide_arrow {
  appearance: textfield;
}
</style>
