<template>
  <div class="row">
    <div class="col-12">
      <button class="btn create-btn btn-green w-100" @click="createLabels">Create labels</button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent } from 'vue'
import type { SingleAssignment } from '@/types/Models/Assignment'
import { useStore } from 'vuex'
import { LABELS_CREATING_LOADER } from '@/const/LoadersNames'
import { notify } from '@kyvg/vue3-notification'

export default defineComponent({
  name: 'ReportAccepted',
  setup() {
    const store = useStore()

    const assignment: ComputedRef<SingleAssignment> = computed(() => store.getters['assignment/assignmentSummary/get'])

    const createLabels = () => {
      store.dispatch('ui/loaderShow', {
        name: LABELS_CREATING_LOADER,
        data: { progress: 0 },
      })

      store.dispatch('assignment/createLabels', { id: assignment.value.id }).catch(() => {
        notify({ group: 'error', text: 'Labels Create Error' })
      })
    }

    return {
      createLabels,
    }
  },
})
</script>
