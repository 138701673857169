<template>
  <div v-if="hasActions">
    <b-dropdown right split :text="action.name" :class="dropdownClass" @click="dropdownClick">
      <DropdownItem
        v-for="actionItem of otherActions"
        :key="actionItem.handle"
        :action="actionItem"
        :assignment="assignment"
      />
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ActionColor } from '@/services/Status/ActionColor'
import {
  ActionsConfig,
  filterAction,
  prepareAction,
  sortAction,
  transformAction,
  useActionHandlers,
} from '@/services/AssignmentsListActions'
import { STAFF, CUSTOMER } from '@/const/AccountTypes'
import DropdownItem from '@/views/Components/Assignment/Cells/DropdownItem.vue'
import type { ListAssignment } from '@/types/Models/Assignment'
import type { Action } from '@/types/Components/Actions'

export default defineComponent({
  name: 'ActionsCell',
  components: { DropdownItem },
  props: {
    assignment: {
      type: Object as PropType<ListAssignment>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
    const actions: ComputedRef<Action[]> = computed(() => {
      const filterer = filterAction(props.assignment)
      const preparer = prepareAction(props.assignment, isStaff.value)
      const transformer = transformAction(props.assignment, isStaff.value)

      return ActionsConfig.map(preparer).filter(filterer).map(transformer).sort(sortAction)
    })

    const action: ComputedRef<Action> = computed(() => actions.value[0])
    const { actionRoute, actionLink, actionFn } = useActionHandlers(props.assignment, action.value)

    const actionColor: ComputedRef<string> = computed(
      () => ActionColor[isStaff.value ? STAFF : CUSTOMER][props.assignment.status],
    )

    const hasActions: ComputedRef<boolean> = computed(() => actions.value.length > 0)
    const otherActions: ComputedRef<Action[]> = computed(() => actions.value.slice(1))
    const dropdownClass: ComputedRef<string[]> = computed(() => ['assignment-cta', actionColor.value])

    function dropdownClick() {
      if (actionRoute.value) {
        router.push(actionRoute.value)
      } else if (actionLink.value) {
        window.location.href = actionLink.value
      } else {
        actionFn()
      }
    }

    return {
      action,
      hasActions,
      otherActions,
      dropdownClass,
      dropdownClick,
    }
  },
})
</script>
