<template>
  <div>
    <div>
      {{ addressString(shipment.recipient) }}
    </div>

    <div>
      <small class="text-muted">
        {{ addressOwner(shipment.recipient) }}
      </small>
    </div>

    <div v-if="notifications.length" class="text-danger pt-2">
      <div v-for="(notification, index) in notifications" :key="index">
        <i class="fa fa-exclamation-triangle fa-fw" />
        {{ notification }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from 'vue'
import { addressOwner, addressString } from '@/services/Helpers'
import { adjustedServicePointProblemDescription } from '@/const/Texts'
import type { AdjustedShipment, Shipment } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'RecipientCell',
  props: {
    shipment: {
      type: Object as PropType<Shipment>,
      required: true,
    },
  },
  setup(props) {
    const hasServicePoints = computed(() => props.shipment.servicePoints.length > 0)
    const orderedPieces = computed(() =>
      [...props.shipment.shipments].sort(
        (a: AdjustedShipment, b: AdjustedShipment) => b.runningNumber - a.runningNumber,
      ),
    )
    const secondaryShipment = computed(() => orderedPieces.value[1] || null)
    const hasAdjustedData = computed(() => Boolean(secondaryShipment.value))
    const hasAdjustedServicePointProblem = computed(() => hasAdjustedData.value && !hasServicePoints.value)

    const notifications = computed(() => {
      const buffer: string[] = []

      if (props.shipment.hasTrackingError && props.shipment.lastTrackingEventDescription) {
        buffer.push(props.shipment.lastTrackingEventDescription)
      }

      if (hasAdjustedServicePointProblem.value) {
        buffer.push(adjustedServicePointProblemDescription)
      }

      return buffer
    })

    return {
      notifications,
      addressString,
      addressOwner,
    }
  },
})
</script>
