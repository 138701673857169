import { findModal } from '@/services/Modal'

const state = {
  modalProps: {},
  renderedModals: [],
}

const mutations = {
  setModalProps(state: any, { name, data }: any) {
    state.modalProps = {
      ...state.modalProps,
      [name]: data,
    }
  },
  renderModal(state: any, name: string) {
    if (state.renderedModals.includes(name)) return
    state.renderedModals.push(name)
  },
}

const actions = {
  openModal({ commit }: any, { name, data }: any) {
    commit('setModalProps', { name, data })
    findModal(name)?.show()
  },
  closeModal({ commit }: any, name: string) {
    commit('setModalProps', { name, data: {} })
    findModal(name)?.hide()
  },
  renderModal({ commit }: any, name: string) {
    commit('renderModal', name)
  },
}

const getters = {
  modalProps: (state: any) => (name: string) => state.modalProps[name],
  renderedModals: (state: any) => state.renderedModals,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
