import Axios from 'axios'
import store from '@/store'
import router from '@/router'
import { LOGIN_PAGE } from '@/types/router'
import { none } from '@/services/Helpers'

Axios.defaults.baseURL = import.meta.env.VITE_APP_API_LOCATION
Axios.defaults.headers.common.Accept = 'application/json'
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.config.url !== 'auth/logout') {
      if (store.getters['isLoggedIn']) {
        store.dispatch('auth/logout').then(none)
      } else {
        router
          .push({
            name: LOGIN_PAGE,
            params: {},
          })
          .catch(none)
      }
    }

    return Promise.reject(error)
  },
)

export default Axios
