/* ============
 * Assignment Transformer
 * ============
 *
 * The transformer for the Assignment.
 */

import ItemTransformer from '@/transformers/ItemTransformer'
import Transformer from '@/transformers/Transformer'
import type { Assignment, ListAssignmentResponse } from '@/types/Models/Assignment'

export default class AssignmentTransformer extends Transformer {
  /**
   * Method used to transform a fetched Assignment.
   */
  static fetch(data: ListAssignmentResponse): Assignment {
    if (!data?.id) {
      return {}
    }
    const assignment = {
      id: data.id,
      reference: data.reference,
      status: data.status,
      serviceLevel: data.serviceLevel,
      description: data.description,
      message: data.message,
      itemsCount: data.domesticItemsCount + data.internationalItemsCount,
      item: data.item ? ItemTransformer.fetch(data.item) : null,
      domesticItemsCount: data.domesticItemsCount,
      internationalItemsCount: data.internationalItemsCount,
      documentsUrl: data.documentsUrl,
      labelsDownloadUrl: data.labelsDownloadUrl,
      labelsDownloadStatus: data.labelsDownloadStatus,
      domesticErrorsCount: data.domesticErrorsCount,
      domesticErrors: data.domesticErrors,
      internationalErrorsCount: data.internationalErrorsCount,
      internationalErrors: data.internationalErrors,
      fatalErrorsCount: data.domesticErrorsCount + data.internationalErrorsCount,
      permissibleErrorsCount: data.permissibleErrorsCount,
      createdAt: data.createdAt,
      pickupDate: data.pickupDate,
      updatedAt: data.updatedAt,
      sourceFiles: data.sourceFiles || [],
      optimisationReportUrl: data.optimisationReportUrl || null,
      customerReference: data.customerReference || null,
      user: data.user || {},
      staff: data.staff || {},
      hasCustomPrices: data.hasCustomPrices || false,
      hasCustomPriorities: data.hasCustomPriorities || false,
      archived: data.archived || false,
      senderAddress: data.senderAddress || {},
      denyLabelsDownload: data.denyLabelsDownload || false,
    }
    const customPriority = this.getCustomPriority(assignment)
    const customPrice = this.getCustomPrice(assignment)
    assignment.customOptimization = {
      priority: customPriority,
      price: customPrice,
    }
    return { ...data, ...assignment }
  }

  static getCustomPriority(assignmentData) {
    const { user, hasCustomPriorities, senderAddress } = assignmentData

    if (hasCustomPriorities) {
      return 'Assignment carriers'
    }

    if (senderAddress.hasCustomPriorities) {
      return 'Sender carriers'
    }

    if (user.hasCustomPriorities) {
      return 'Customer carriers'
    }
    return ''
  }

  static getCustomPrice(assignmentData) {
    const { user, hasCustomPrices, senderAddress } = assignmentData

    if (hasCustomPrices) {
      return 'Assignment prices'
    }
    if (senderAddress.hasCustomPrices) {
      return 'Sender prices'
    }
    if (user.hasCustomPrices) {
      return 'Customer prices'
    }
    return ''
  }
}
