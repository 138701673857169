import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'
import type { AxiosCollection } from '@/types/Components/Response'
import type { Item } from '@/types/Models/Item'

export default class AssignmentProxy extends applyProxyMethodsMixin(class {}) implements ProxyMethods {
  public endpoint = 'assignments'

  getItems(assignmentId: string): Promise<AxiosCollection<Item>> {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/items`)
  }

  getStatus(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/status`)
  }

  getLabelsStatuses(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/labels-statuses`)
  }

  validate(assignmentId: string) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/validate`)
  }

  getReportData(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/report`)
  }

  createLabels(assignmentId: string, payload: any) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/create-labels`, payload)
  }

  submitOptimize(assignmentId: string) {
    return this.submit('post', `/${this.endpoint}/${assignmentId}/optimize`)
  }

  updateService(assignmentId: string, payload: any) {
    return this.submit('put', `/${this.endpoint}/${assignmentId}/service-level`, payload)
  }

  getAssignmentSummary(assignmentId: string) {
    return this.submit('get', `/${this.endpoint}/${assignmentId}/single-parcel/summary`)
  }

  getLabelsMultiple(payload: any) {
    return this.submit('post', `/${this.endpoint}/labels/download`, payload)
  }
}
