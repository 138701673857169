<template>
  <CustomSelect
    v-if="isStaff"
    class="col-12"
    placeholder="Choose client"
    :selected="customer_id"
    :options="customers"
    :selectable="(option: User) => option.isActive"
    :filter="clientFilter"
    @selected="setCustomer"
  >
    <template #custom_option="{ option }">
      <div :class="'item-' + option.status">
        <span class="status" />
        <span class="name">{{ option.company || option.fullName }}</span>
        (Name: {{ option.fullName }}; Client number: {{ option.clientNumber }}; Email: {{ option.email }}; Phone:
        {{ option.phoneNumber }})
      </div>
    </template>
  </CustomSelect>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount, type Ref } from 'vue'
import { useStore } from 'vuex'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'
import type { User } from '@/types/Models/User'

export default defineComponent({
  name: 'ClientSelect',
  components: {
    CustomSelect,
  },
  props: {
    tableName: {
      type: String,
      default: null,
    },
  },
  emits: ['changeClient'],
  setup(_, { emit }) {
    const store = useStore()
    const customer_id: Ref<string | null> = ref(null)
    const customers: Ref<User[]> = computed(() => store.getters['customers/all'])
    const isStaff = computed(() => store.getters['account/isStaff'])

    const clientFilter = (option: any, _: string, search: string) => {
      const searchString = search.toLowerCase()
      const text = `${option?.label} ${option?.company} ${option?.id} ${option?.fullName} ${option?.email} ${option?.phoneNumber}`
      return text.toLowerCase().indexOf(searchString) > -1
    }

    const setCustomer = (customerId: string) => {
      customer_id.value = customerId
      emit('changeClient', customerId)
    }

    onBeforeMount(() => {
      if (customers.value.length === 0) {
        store.dispatch('customers/fetchAll')
      }
    })

    return {
      customer_id,
      customers,
      isStaff,
      clientFilter,
      setCustomer,
    }
  },
})
</script>
