<template>
  <modal-wrap
    :id="id"
    :name="modalName"
    :header-class="'border-0 service-level-header'"
    :body-class="'service-level-modal'"
    :hide-footer="false"
    :hide-header-close="true"
    size="md"
  >
    <template #modal-title> Choose service level </template>
    <template #default>
      <form>
        <div>
          <input id="service_level_economy" v-model="level" type="radio" value="economy" />
          <label for="service_level_economy" class="radio-group">
            <span class="radio-title">Economy</span>
            <span class="radio-description"> 1-4 days </span>
          </label>
        </div>
        <div>
          <input id="service_level_priority" v-model="level" type="radio" value="priority" />
          <label for="service_level_priority" class="radio-group">
            <span class="radio-title">Priority</span>
            <span class="radio-description"> 1-2 days </span>
          </label>
        </div>
      </form>
      <b-alert
        :model-value="isFinishedStatus"
        variant="secondary"
        class="alert-info-danger d-flex align-items-center mt-4 mb-0"
      >
        <span><b>Important: </b>You cannot change service level for this assignment</span>
      </b-alert>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="closeModal()"> Cancel </b-button>
      <b-button variant="success" size="md" class="btn-green" :disabled="isDisabled" @click="saveServiceLevel">
        Apply
      </b-button>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { openModal } from '@/services/Modal'
import { notify } from '@kyvg/vue3-notification'
import { SERVICE_LEVEL_MODAL } from '@/const/ModalTypes'
import { CANCELLED, COMPLETE } from '@/const/AssignmentStatuses'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'ServiceLevelModal',
  components: { ModalWrap },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const level = ref('')
    const assignment = computed(() => store.getters['assignment/get'])
    const modalName = SERVICE_LEVEL_MODAL

    const isFinishedStatus = computed(() =>
      assignment.value ? assignment.value.status === COMPLETE || assignment.value.status === CANCELLED : false,
    )
    const serviceLevel = computed(() => assignment.value.serviceLevel)
    const isDisabled = computed(() => (isFinishedStatus.value ? true : level.value === serviceLevel.value))

    level.value = serviceLevel.value

    const closeModal = (changed = !isDisabled.value) => {
      if (changed) {
        openModal('confirmWrap', {
          title: 'There are unsaved changes',
          message: 'Are you sure you want to cancel?',
          onConfirm: () => {
            store.dispatch('vueNextModal/closeModal', modalName)
          },
        })
      } else {
        store.dispatch('vueNextModal/closeModal', modalName)
      }
      store.dispatch('assignment/fetch', assignment.value.id)
    }

    const saveServiceLevel = () => {
      store
        .dispatch('assignment/updateServiceLevel', {
          level: level.value,
          id: route.params.id,
        })
        .then(() => {
          notify({ group: 'success', text: 'Service level was changed' })
          closeModal(false)
        })
        .catch(() => {
          notify({ group: 'error', text: 'Service level change error' })
        })
    }

    watch(serviceLevel, () => {
      level.value = serviceLevel.value
    })

    return {
      level,
      assignment,
      modalName,
      isFinishedStatus,
      serviceLevel,
      isDisabled,
      closeModal,
      saveServiceLevel,
    }
  },
})
</script>
