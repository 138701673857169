import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'

export default class AssignmentStatusProxy extends applyProxyMethodsMixin(class {}) implements ProxyMethods {
  public endpoint = 'assignments/id/status'

  constructor(id: string, parameters: object = {}) {
    super(parameters)
    this.endpoint = `assignments/${id}/status`
  }

  submitProcess() {
    return this.submit('post', `/${this.endpoint}/process`)
  }

  submitReview() {
    return this.submit('post', `/${this.endpoint}/review`)
  }

  submitReportRelease() {
    return this.submit('post', `/${this.endpoint}/report/release`)
  }

  submitReportReleaseAndAccept() {
    return this.submit('post', `/${this.endpoint}/report/release-accept`)
  }

  submitReportAccept() {
    return this.submit('post', `/${this.endpoint}/report/accept`)
  }

  submitReportDeny() {
    return this.submit('post', `/${this.endpoint}/report/deny`)
  }

  submitLabelsRelease() {
    return this.submit('post', `/${this.endpoint}/labels/release`)
  }

  submitCancel() {
    return this.submit('post', `/${this.endpoint}/cancel`)
  }

  submitUnArchive() {
    return this.submit('post', `/${this.endpoint}/unarchive`)
  }

  submitComplete() {
    return this.submit('post', `/${this.endpoint}/complete`)
  }
}
