import customerLabelsStatuses from '@/services/Assignment/CustomerLabelsStatuses'
import staffLabelsStatuses from '@/services/Assignment/StaffLabelsStatuses'
import staffReportStatuses from '@/services/Assignment/StaffReportStatuses'
import customerReportStatuses from '@/services/Assignment/CustomerReportStatuses'
import customerValidatingStatuses from '@/services/Assignment/CustomerValidatingStatuses'
import staffValidatingStatuses from '@/services/Assignment/StaffValidatingStatuses'
import defaultStatuses from '@/services/Assignment/DefaultStatuses'

export const getStaffAssignmentsStatuses = () => ({
  ...defaultStatuses(),
  ...staffValidatingStatuses(),
  ...staffReportStatuses(),
  ...staffLabelsStatuses(),
})

export const getCustomerAssignmentsStatuses = () => ({
  ...defaultStatuses(),
  ...customerValidatingStatuses(),
  ...customerReportStatuses(),
  ...customerLabelsStatuses(),
})
