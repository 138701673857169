<template>
  <modal-wrap :name="modalName" size="xl" :hide-footer="false">
    <template #modal-title> Edit customer address </template>
    <template #default>
      <div class="edit-customer-data">
        <VeeForm v-if="userData" @submit="onSubmit">
          <b-form-group class="form-group" label="Invoice Address">
            <div class="form-inline-row">
              <label class="label-input">Invoice address country</label>
              <div class="w-100">
                <CountryPicker
                  v-model="customer.countryCode"
                  :value="customer.countryCode"
                  vinput-id="country"
                  name="Sender address country"
                  vid="country_code"
                  placeholder="Choose country *"
                  :required="true"
                />
              </div>
            </div>

            <div class="form-inline-row">
              <label class="label-input">Invoice address city</label>
              <div class="w-100">
                <VInputDatalist
                  id="customer.city"
                  v-model="customer.city"
                  type="text"
                  :rules="{ required: true }"
                  :list="citiesOptions"
                  vid="customer.city"
                  name="city"
                  placeholder="City *"
                />
              </div>
            </div>

            <div class="form-inline-row">
              <label class="label-input">Invoice address ZIP</label>
              <VInput
                v-model="customer.postalCode"
                :rules="{ required: true, zip: [customer.countryCode] }"
                type="text"
                name="postalCode"
                placeholder="Postal Code"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Client Address *</label>
              <VInput
                v-model="customer.addressLine"
                :rules="{ required: true }"
                type="text"
                name="addressLine"
                placeholder="Client Address *"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Client Address 2</label>
              <VInput v-model="customer.addressLine2" type="text" name="addressLine2" placeholder="Client Address 2" />
            </div>
          </b-form-group>
        </VeeForm>
      </div>
    </template>
    <template #modal-footer>
      <div>
        <b-button size="md" class="btn-grey mr-3" @click="cancelEdit"> Cancel </b-button>
        <b-button
          variant="success"
          size="md"
          :disabled="!isFormChanged"
          class="btn-green"
          type="submit"
          @click="onSubmit"
        >
          Save changes
        </b-button>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { notify } from '@kyvg/vue3-notification'
import { ref, computed, defineComponent, toRaw, watch, onMounted } from 'vue'
import { isEqual } from '@/services/Utils'
import { useStore } from 'vuex'
import { getValidationState } from '@/services/Validation'
import { Form as VeeForm } from 'vee-validate'
import { EDIT_USER_ADDRESS_MODAL } from '@/const/ModalTypes'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'
import VInputDatalist from '@/views/Components/Elements/Form/VInputDatalist.vue'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import allCities from '@/assets/files/cities.json'
import type { City } from '@/types/Models/Customer'

export default defineComponent({
  name: 'EditUserAddressModal',
  components: {
    CountryPicker,
    VInputDatalist,
    ModalWrap,
    VInput,
    VeeForm,
  },
  emits: ['update'],
  setup(_, { emit }) {
    const store = useStore()
    const modalName = EDIT_USER_ADDRESS_MODAL
    const userId = computed(() => store.getters['vueNextModal/modalProps'](modalName)?.id)
    const addressData = computed(() => store.getters['vueNextModal/modalProps'](modalName)?.clientAddress)
    const customer = ref({
      countryCode: addressData.value?.countryCode || '',
      postalCode: addressData.value?.postalCode || '',
      region: addressData.value?.region || '',
      city: addressData.value?.city || '',
      addressLine: addressData.value?.addressLine || '',
      addressLine2: addressData.value?.addressLine2 || '',
    })

    const citiesOptions = computed(() => {
      const result: City[] = [
        {
          value: null,
          text: 'Choose city *',
        },
      ]
      return result.concat(
        allCities.map((cityName: string) => ({
          value: cityName,
          text: cityName,
        })),
      )
    })

    const isFormChanged = computed(() => {
      const customerData = { ...customer.value }
      return !isEqual(customerData, addressData.value)
    })

    const onSubmit = async () => {
      const data: any = { clientAddress: toRaw(customer.value) }
      await store
        .dispatch('customers/update', {
          id: userId.value,
          data,
        })
        .then(() => {
          notify({ group: 'success', text: 'Customer data updated' })
          emit('update', data)
          store.dispatch('vueNextModal/closeModal', modalName)
        })
    }

    const cancelEdit = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const loadAddressData = () => {
      if (addressData.value) {
        customer.value = {
          countryCode: addressData.value.countryCode,
          postalCode: addressData.value.postalCode,
          region: addressData.value.region,
          city: addressData.value.city,
          addressLine: addressData.value.addressLine,
          addressLine2: addressData.value.addressLine2,
        }
      }
    }

    onMounted(loadAddressData)

    watch(addressData, loadAddressData)

    return {
      customer,
      userData: addressData,
      modalName,
      citiesOptions,
      isFormChanged,
      onSubmit,
      cancelEdit,
      getValidationState,
    }
  },
})
</script>

<style lang="scss" scoped>
.edit-customer-data {
  :deep(.form-group) {
    margin-bottom: 0;
    width: 100%;
  }
  :deep(.form-control) {
    padding: 13px 24px 13px 13px;
    width: 100%;
  }
  :deep(select.form-control) {
    padding: 10px 24px 13px 13px;
  }
  .form-inline-row {
    margin-bottom: 12px;
    white-space: nowrap;
  }
  .label-input {
    min-width: 170px;
  }
}
</style>
