<template>
  <modal-wrap :id="id" :name="modalName" :hide-footer="false" size="xl">
    <template #modal-title> Edit customer data </template>
    <template #default>
      <div class="edit-customer-data">
        <VeeForm v-if="userData" @submit="onSubmit">
          <b-form-group class="form-group">
            <div class="form-inline-row">
              <label class="label-input">Email address</label>
              <VInput
                v-model="customer.email"
                :rules="{ required: true, email: true }"
                autocomplete="off"
                type="email"
                name="email"
                placeholder="Email address"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Client №</label>
              <VInput
                v-model="customer.clientNumber"
                :rules="{ required: true }"
                type="text"
                name="clientNumber"
                placeholder="Client №"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Client name</label>
              <VInput
                v-model="customer.fullName"
                :rules="{ required: true }"
                type="text"
                name="fullName"
                placeholder="Client name"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Phone number</label>
              <VInput
                v-model="customer.phoneNumber"
                type="text"
                name="phoneNumber"
                :rules="{ required: true }"
                placeholder="Phone number"
              />
            </div>

            <div class="form-inline-row">
              <label class="label-input">Short name</label>
              <VInput v-model="customer.shortName" type="text" name="shortName" placeholder="Short name" />
            </div>

            <div class="form-inline-row">
              <label class="label-input"> Client password </label>
              <VInput
                v-model="customer.password"
                :rules="customer.password ? { min: 6 } : {}"
                type="password"
                class="inspectletIgnore"
                autocomplete="off"
                name="password"
                placeholder="Client password"
              />
            </div>

            <div class="form-inline-row d-flex align-items-baseline">
              <label class="label-input"> Custom Options </label>
              <div class="d-flex flex-column">
                <div class="form-check">
                  <input
                    id="hidePriceCheckbox"
                    v-model="customer.hidePrice"
                    class="form-check-input"
                    type="checkbox"
                    :value="true"
                  />
                  <label class="form-check-label" for="hidePriceCheckbox">
                    Uncheck this if you don't want to show optimized prices for the customer
                  </label>
                </div>

                <div class="form-check mt-3">
                  <input
                    id="customerNumbersCheckbox"
                    v-model="customer.canHasCustomerNumbers"
                    class="form-check-input"
                    type="checkbox"
                    :value="true"
                  />
                  <label class="form-check-label" for="customerNumbersCheckbox">
                    The customer can use the carrier's own customer number.
                  </label>
                </div>
              </div>
            </div>
          </b-form-group>
        </VeeForm>
      </div>
    </template>
    <template #modal-footer>
      <b-button size="md" class="btn-grey mr-3" @click="cancelEdit"> Cancel </b-button>
      <b-button
        variant="success"
        size="md"
        :disabled="!isFormChanged"
        class="btn-green"
        type="submit"
        @click="onSubmit"
      >
        Save changes
      </b-button>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { ref, computed, defineComponent, toRaw, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { isEqual } from '@/services/Utils'
import { notify } from '@kyvg/vue3-notification'
import { Form as VeeForm } from 'vee-validate'
import { EDIT_USER_MODAL } from '@/const/ModalTypes'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import ModalWrap from '@/views/Modals/ModalWrap.vue'

export default defineComponent({
  name: 'EditUserModal',
  components: { ModalWrap, VInput, VeeForm },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  emits: ['update'],
  setup(_, { emit }) {
    const store = useStore()
    const modalName = EDIT_USER_MODAL
    const userData = computed(() => store.getters['vueNextModal/modalProps'](modalName))
    const customer = ref({
      id: '',
      email: '',
      clientNumber: '',
      fullName: '',
      phoneNumber: '',
      shortName: '',
      password: '',
      hidePrice: true,
      canHasCustomerNumbers: true,
    })

    const isFormChanged = computed(() => {
      const customerData = { ...customer.value }
      customerData.hidePrice = !customerData.hidePrice
      return !isEqual(customerData, userData.value) || customerData.password
    })

    const onSubmit = async () => {
      const data: any = { ...toRaw(customer.value) }
      data.hidePrice = !!data.hidePrice
      await store
        .dispatch('customers/update', {
          id: data.id,
          data,
        })
        .then(() => {
          notify({ group: 'success', text: 'Customer data updated' })
          emit('update', data)
          store.dispatch('vueNextModal/closeModal', modalName)
        })
    }

    const cancelEdit = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
    }

    const loadAddressData = () => {
      if (userData.value) {
        customer.value = {
          id: userData.value.id,
          email: userData.value.email,
          clientNumber: userData.value.clientNumber,
          fullName: userData.value.fullName,
          phoneNumber: userData.value.phoneNumber,
          shortName: userData.value.shortName,
          password: userData.value.password,
          hidePrice: userData.value.hidePrice,
          canHasCustomerNumbers: userData.value.canHasCustomerNumbers,
        }
      }
    }

    onMounted(loadAddressData)

    watch(userData, loadAddressData)

    return {
      customer,
      modalName,
      userData,
      isFormChanged,
      onSubmit,
      cancelEdit,
    }
  },
})
</script>

<style lang="scss" scoped>
.edit-customer-data {
  :deep(.form-group) {
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
