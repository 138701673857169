import { LABELS_CREATING_LOADER, OPTIMIZE_CREATING_REPORT_LOADER } from '@/const/LoadersNames'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'
import { none } from '@/services/Helpers'
import store from '@/store'
import router from '@/router'

export default class StaffActions {
  static submitOptimize() {
    openModal('confirmWrap', {
      title: 'Optimize & Create Report',
      message:
        "The file looks good. Next we'll run the optimization and create report. Are you sure you want to proceed?",
      okButtonText: 'Proceed',
      onConfirm: () => {
        store
          .dispatch('ui/loaderShow', {
            name: OPTIMIZE_CREATING_REPORT_LOADER,
            data: { progress: 0 },
          })
          .then(none)
        store.dispatch('assignment/submitOptimize').then(none)
      },
    })
  }

  static submitReportRelease() {
    openModal('confirmWrap', {
      title: 'Are you sure you want to send report?',
      message:
        'Report will be sent to the Customer. Labels will not be created until he accepts the report. Customer chose NOT to receive report.',
      okButtonText: 'Proceed',
      onConfirm: () => {
        store.dispatch('assignment/submitReportRelease').then(() => {
          store.dispatch('assignment/fetch').then(none)
          notify({ group: 'success', text: 'Assignment has been released' })
        })
      },
    })
  }

  static submitReportReleaseAndAccept() {
    openModal('confirmWrap', {
      title: 'Are you sure you want to send and accept report?',
      message: 'Report will be sent to the Customer and accept automatically. Customer chose NOT to receive report.',
      okButtonText: 'Proceed',
      onConfirm: () => {
        store.dispatch('assignment/submitReportReleaseAndAccept').then(() => {
          store.dispatch('assignment/fetch').then(none)
          notify({
            group: 'success',
            text: 'Assignment has been released and accepted',
          })
        })
      },
    })
  }

  static submitReportAccept() {
    openModal('confirmWrap', {
      title: 'Are you sure you want to accept report?',
      message: 'Report will be accepted by staff user',
      okButtonText: 'Proceed',
      onConfirm: () => {
        store.dispatch('assignment/submitReportAccept').then(() => {
          store.dispatch('assignment/fetch').then(none)
          notify({
            group: 'success',
            text: 'Assignment has been released and accepted',
          })
        })
      },
    })
  }

  static createLabels() {
    store
      .dispatch('ui/loaderShow', {
        name: LABELS_CREATING_LOADER,
        data: { progress: 0 },
      })
      .then(none)
    store
      .dispatch('assignment/createLabels', { id: router.currentRoute.value.params.id })
      .then(none)
      .catch(() => {
        notify({ group: 'error', text: 'Labels Create Error' })
      })
  }

  static createMissingLabels() {
    openModal('confirmWrap', {
      title: 'Create missing Labels',
      message:
        'Some labels failed to create. ' +
        'Labels cannot be released until all of them are created or empty rows deleted. ' +
        'Proceeding will create labels only for parcels that still have no tracking number.',
      okButtonText: 'Create missing Labels',
      onConfirm: () => {
        store
          .dispatch('ui/loaderShow', {
            name: LABELS_CREATING_LOADER,
            data: { progress: 0 },
          })
          .then(none)
        store
          .dispatch('assignment/createLabels', { id: router.currentRoute.value.params.id })
          .then(none)
          .catch(() => {
            notify({ group: 'error', text: 'Labels Create Error' })
          })
      },
    })
  }

  static submitLabelsRelease() {
    openModal('confirmWrap', {
      title: 'Release Labels',
      message: 'Are you sure you want to proceed?',
      okButtonText: 'Proceed',
      onConfirm: () => {
        store.dispatch('assignment/submitLabelsRelease').then(() => {
          store.dispatch('assignment/fetch').then(none)
          notify({
            group: 'success',
            text: 'Thanks, the customer can now download the labels. Labels Released 🙂',
          })
        })
      },
    })
  }

  static submitCloseAssignment() {
    openModal('confirmWrap', {
      title: 'Are you sure you want to archive assigment?',
      message: 'The assigment will be forward to “Archived assignments" ',
      okButtonText: 'Archive',
      onConfirm: () => {
        store.dispatch('assignment/archive').then(() => {
          store.dispatch('assignment/fetch').then(none)
          notify({
            group: 'success',
            text: 'The assignment was successfully archived.',
          })
        })
      },
    })
  }
}
