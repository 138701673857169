<template>
  <tr>
    <td>
      <RowDate :date="message.created_at" />
    </td>
    <td>
      <span class="mr-1">
        <i v-if="isSms" class="fa fa-phone" />
        <i v-else class="fa fa-envelope" />
      </span>
      {{ message.sent_to }}
    </td>
    <td>
      <div>
        {{ messageData }}
      </div>
      <div v-if="message.error" class="pt-3 text-danger">
        <i class="fa fa-exclamation-triangle fa-fw" />
        {{ message.error }}
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import RowDate from '@/views/Components/Elements/Table/RowDate.vue'
import type { Message } from '@/types/Models/Shipment'

export default defineComponent({
  name: 'Message',
  components: { RowDate },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  setup(props) {
    const isSms = computed(() => props.message.type === 'sms')

    const emailHandle = computed(() => {
      const parsedData = JSON.parse(props.message.data)
      return parsedData.handle
    })

    const emailName = computed(() => {
      const names: Record<string, string> = {
        'labelportal.shipment.service_point_out_for_delivery': 'Parcel can be collected at service-point',
        'labelportal.shipment.service_point_delivery': 'Parcel was diverted to a service-point',
        'labelportal.shipment.dispatched': 'Parcel is in transit',
        'labelportal.shipment.delivered': 'Parcel was delivered',
        'labelportal.shipment.package.dispatched': 'Package is in transit',
        'labelportal.shipment.package.delivered': 'Parcel was delivered',
      }
      return names[emailHandle.value] || emailHandle.value
    })

    const messageData = computed(() => (isSms.value ? props.message.data : emailName.value))

    return {
      isSms,
      messageData,
    }
  },
})
</script>
