<template>
  <div class="row">
    <div class="col-12">
      <button class="btn create-btn btn-green w-100" @click="openDownloadLabelsModal">Download Label</button>
    </div>
    <br />
    <div v-if="documentsUrl" class="col-12">
      <button class="btn create-btn btn-green w-100" @click="openDownloadDocuments">
        Download secondary documents
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import { openModal, registerModals } from '@/services/Modal'
import { DOWNLOAD_LABELS, DOWNLOAD_LABELS_WARNING } from '@/const/ModalTypes'
import type { SingleAssignment } from '@/types/Models/Assignment'
import { useDownloadLabelsAction } from '@/composables/labels/use-download-labels-action'

export default defineComponent({
  name: 'LabelsReleased',
  setup() {
    const store = useStore()
    const downloadLabelsAction = useDownloadLabelsAction()

    const assignment: ComputedRef<SingleAssignment> = computed(() => store.getters['assignment/assignmentSummary/get'])
    const documentsUrl = computed(() => assignment.value.documentsUrl)

    const openDownloadLabelsModal = () => downloadLabelsAction([assignment.value])

    const openDownloadDocuments = () => {
      window.open(documentsUrl.value, '_blank')
    }

    onMounted(() => {
      registerModals(DOWNLOAD_LABELS)
      registerModals(DOWNLOAD_LABELS_WARNING)
    })

    return {
      openDownloadLabelsModal,
      openDownloadDocuments,
      documentsUrl,
    }
  },
})
</script>
