<template>
  <modal-wrap
    :id="id"
    ref="integrationsDetails"
    :name="modalName"
    :header-class="'integrations-details-header'"
    body-class="integrations-details-modal"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4>{{ modalTitle }}</h4>
      </div>
    </template>
    <template #default>
      <div v-if="modalData?.system === 'ongoing'">
        <b>Webhook URL:</b> {{ ongoingWebhooksUrl }}<br />

        <div class="row">
          <div class="form-group mb-0">
            <div class="form-check mb-0 mt-0">
              <input id="sandbox" v-model="payload.sandbox" class="form-check-input" type="checkbox" />
              <label for="sandbox" class="form-check-label mb-0"> Sandbox </label>
            </div>
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between align-items-center">
          <b>Status Settings</b>
        </div>

        <div class="row">
          <div class="form-group mb-0">
            <div class="form-check mb-0 mt-0">
              <input id="updateLabel" v-model="payload.updateLabel" class="form-check-input" type="checkbox" />
              <label for="updateLabel" class="form-check-label mb-0"> Send Labels to OnGoing </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <div class="form-check mb-0 mt-0">
              <input id="sendLabels" v-model="payload.updateStatus" class="form-check-input" type="checkbox" />
              <label for="sendLabels" class="form-check-label"> Send Status to OnGoing </label>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <b>Status for error</b>
        </div>

        <div class="row">
          <div class="form-group">
            <div class="input-group">
              <select v-model="payload.onErrorStatusCode" class="form-control">
                <option v-for="(status, key) in ongoingStatuses" :key="key" :value="status.number">
                  {{ status.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <b>Status for success</b>
        </div>

        <div class="row">
          <div class="form-group">
            <div class="input-group">
              <select v-model="payload.onSuccessStatusCode" class="form-control">
                <option v-for="(status, key) in ongoingStatuses" :key="key" :value="status.number">
                  {{ status.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <button type="button" class="btn create-btn btn-green" @click="updateSettings(modalData.id)">
                Save Settings
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="modalData?.system === 'shopify'">
        Webhook URL: https://core.pacsortonline.com/api/integrations/shopify/callback?user_id={{
          customerId
        }}&external_user_id={{ modalData?.external_id }}
      </div>
      <div v-if="modalData?.system === 'woocommerce'">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              WebhookURL: {{ webhooksUrl }}
              <CopyToClipboard :text="webhooksUrl">
                <button class="fa-copy fa" title="Copy" />
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Url: {{ modalData.details.credentials.url }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Default Weight: {{ modalData.details.default_weight }}g</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Default Height: {{ modalData.details.default_height }}cm</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Default Height: {{ modalData.details.default_width }}cm</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Default Height: {{ modalData.details.default_length }}cm</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Active: {{ modalData.details.active ? 'Yes' : 'No' }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">Interval: every {{ modalData.details.interval || 60 }} minutes</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              Import Type:
              {{ modalData.details.import_type.replace(/\_/, ' ') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              Last Imported:
              {{ modalData.details.last_imported_at ? modalData.details.last_imported_at : 'no data' }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <button type="button" class="btn create-btn btn-green" @click="viewOrders(modalData.id)">
                View Orders
              </button>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <button type="button" class="btn create-btn btn-green" @click="runImport(modalData.id)">
                Run import
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { openModal } from '@/services/Modal'
import { useStore } from 'vuex'
import { INTEGRATION_DETAILS, INTEGRATION_ORDERS } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import CopyToClipboard from '@/views/Components/Elements/CopyToClipboard.vue'

export default defineComponent({
  name: 'IntegrationsDetailsModal',
  components: {
    CopyToClipboard,
    ModalWrap,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    const store = useStore()

    const ongoingStatuses = ref([])

    const modalName = INTEGRATION_DETAILS
    const modalTitle = computed(() => `Integration details for ${modalData.value?.name}`)
    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName))
    const customerId = computed(() => store.getters['vueNextModal/modalProps'](modalName)?.customerId)
    const webhooksUrl = computed(
      () =>
        `https://service-importer-orders.pacsortonline.com/api/v1/webhook/woocommerce/?client_internal_id=${customerId.value}`,
    )
    const ongoingWebhooksUrl = computed(
      () => `https://core.pacsortonline.com/api/integrations/ongoing/callback?external_user_id=${customerId.value}`,
    )

    const payload = ref({
      onErrorStatusCode: 150,
      onSuccessStatusCode: 350,
      updateStatus: false,
      updateLabel: false,
      sandbox: false,
    })

    const runImport = (id: string) => {
      store
        .dispatch('customers/integrations/runActions', {
          customerId: customerId.value,
          id,
          action: 'force-import',
          params: {},
        })
        .then((response: any) => {
          if (response.message) {
            notify({ group: 'success', text: response.message })
          } else {
            notify({ group: 'success', text: 'Import done.' })
            closeModal()
          }
        })
        .catch((e: any) => {
          notify({ group: 'error', text: `Import error. ${e.message}` })
        })
    }

    const viewOrders = (id: string) => {
      openModal(INTEGRATION_ORDERS, {
        customerId: customerId.value,
        integrationId: id,
      })
    }

    const closeModal = () => {
      store.dispatch('vueNextModal/closeModal', modalName)
      store.dispatch('vueNextModal/closeModal', INTEGRATION_DETAILS)
    }

    const updateSettings = () => {
      store
        .dispatch('customers/integrations/runActions', {
          customerId: customerId.value,
          id: modalData.value.id,
          action: 'update-settings',
          params: { ...payload.value },
        })
        .then((response: any) => {
          if (response.message) {
            notify({ group: 'success', text: response.message })
          } else {
            notify({ group: 'success', text: 'Settings updated.' })
            closeModal()
          }
        })
        .catch((e: any) => {
          notify({ group: 'error', text: `Settings update error. ${e.message}` })
        })
    }

    watch(
      () => modalData.value,
      () => {
        if (!modalData.value.id) return
        store
          .dispatch('customers/integrations/runActions', {
            customerId: customerId.value,
            id: modalData.value.id,
            action: 'statuses',
            params: {},
          })
          .then((response: any) => {
            ongoingStatuses.value = response.data
          })
        payload.value.onErrorStatusCode = modalData.value?.details?.onErrorStatusCode || 150
        payload.value.onSuccessStatusCode = modalData.value?.details?.onSuccessStatusCode || 350
        payload.value.updateStatus = modalData.value?.details?.updateStatus || false
        payload.value.updateLabel = modalData.value?.details?.updateLabel || false
      },
    )

    return {
      payload,
      modalName,
      modalData,
      modalTitle,
      customerId,
      webhooksUrl,
      ongoingStatuses,
      ongoingWebhooksUrl,
      runImport,
      viewOrders,
      updateSettings,
    }
  },
})
</script>
