<template>
  <div class="assignment-stripe">
    <div class="assignment-number mr-4">
      <router-link :to="assignmentsPage" class="mr-4">
        <a class="back-to position-absolute">
          <mdicon name="ChevronLeft" />
        </a>
      </router-link>
      <span class="ml-1">Assignment #{{ assignment.reference }}</span>
    </div>
    <div v-if="isShowAssignmentInfo" class="assignment-action d-flex mr-4 user-info">
      <div class="d-flex flex-column mr-3">
        <div v-if="user.shortName" class="color-grey">Short name</div>
        <div class="color-black">
          {{ user.shortName }}
        </div>
      </div>
      <div v-if="user.clientNumber" class="d-flex flex-column mr-3">
        <div class="color-grey">Client №</div>
        <div class="color-black">
          {{ user.clientNumber }}
        </div>
      </div>
      <div v-if="user.company" class="d-flex flex-column">
        <div class="color-grey">Company</div>
        <div class="color-black">
          {{ user.company }}
        </div>
      </div>
    </div>
    <div class="d-flex pr-4 status" :class="{ 'border-l pl-4': !isShowAssignmentInfo }">
      <AssignmentStatus :status="assignmentStatus" />
      <span v-if="errorCount > 0" class="ml-3"> ({{ errorCount }} {{ pluralize('error', errorCount) }}) </span>
    </div>
    <div class="assignment-action mr-4 d-flex">
      <b-button
        :disabled="!reportFile || isHidePrice"
        class="file-btn ml-0"
        :href="reportFile"
        target="_blank"
        :download="true"
      >
        <Tooltip :text="actionTooltip('report', !reportFile)">
          <mdicon name="file-pdf-box" />
        </Tooltip>
      </b-button>
      <b-button class="file-btn" :disabled="!sourceFile" :href="sourceFile" :download="true">
        <Tooltip :text="actionTooltip('Source', !sourceFile)">
          <mdicon name="Download" />
        </Tooltip>
      </b-button>
      <b-button class="file-btn ml-3" :href="exportLink" :download="true" target="_blank">
        <Tooltip text="Export assignment to CSV">
          <mdicon name="file-delimited-outline" />
        </Tooltip>
      </b-button>
    </div>

    <div class="assignment-last-update d-flex align-items-center">
      <mdicon name="History" />
      Last edited {{ toTimeFromNow(assignment.updatedAt) }}
    </div>

    <div class="assignment-function d-flex">
      <div
        v-if="isStaff && (assignment.customOptimization.price || assignment.customOptimization.priority)"
        class="d-flex flex-column justify-content-center flex-wrap border-r pr-3"
      >
        <b-badge v-if="assignment.customOptimization.price" class="mb-1" variant="success">
          {{ assignment.customOptimization.price }}
        </b-badge>
        <b-badge v-if="assignment.customOptimization.priority" variant="warning">
          {{ assignment.customOptimization.priority }}
        </b-badge>
      </div>
      <router-link
        v-if="can('manage', 'prices')"
        :to="dynamicPricesLink"
        :disabled="!isItemsEditable"
        :class="'btn ml-3 btn-height-md btn-outline-secondary no-wrap' + (isItemsEditable ? '' : ' disabled')"
      >
        Dynamic Pricing
      </router-link>

      <StripeBtn :disabled="!isItemsEditable" class="ml-3 no-wrap" @click="openModal(SERVICE_LEVEL_MODAL)">
        Service level
      </StripeBtn>

      <StripeBtn
        v-if="isUserCanBackToEdit"
        :disabled="!isAvailableBackButton"
        class="only-icon ml-3"
        @click="backToEdit"
      >
        <mdicon name="ArchiveOutline" />
      </StripeBtn>
    </div>
    <ServiceLevelModal :id="SERVICE_LEVEL_MODAL" />
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { openModal, registerModals } from '@/services/Modal'
import { defineComponent, computed, onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { none, pluralize, toTimeFromNow } from '@/services/Helpers'
import { SERVICE_LEVEL_MODAL } from '@/const/ModalTypes'
import { CUSTOMER_ASSIGNMENTS_LIST, STAFF_ASSIGNMENT_PRICES, STAFF_ASSIGNMENTS_LIST } from '@/types/router'
import Tooltip from '@/views/Components/Elements/Tooltip.vue'
import StripeBtn from '@/views/Components/Elements/Buttons/StripeBtn.vue'
import AssignmentStatus from '@/views/Components/PageParts/AssignmentStatus.vue'
import ServiceLevelModal from '@/views/Modals/Assignment/ServiceLevelModal.vue'

export default defineComponent({
  name: 'AssignmentStripe',
  components: {
    StripeBtn,
    AssignmentStatus,
    ServiceLevelModal,
    Tooltip,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const userId = computed(() => store.getters['account/userId'])
    const isStaff = computed(() => store.getters['account/isStaff'])
    const user = computed(() => store.getters['assignment/getUser'])
    const ability = computed(() => store.getters['ability'])

    const assignment = computed(() => store.getters['assignment/get'])
    const isItemsEditable = computed(() => store.getters['assignment/isItemsEditable'])
    const assignmentStatus = computed(() => store.getters['assignment/getStatus'])
    const isAvailableBackButton = computed(() => store.getters['assignment/isAvailableBackToEdit'])
    const isHidePrice = computed(() => store.getters['account/isHidePrice'])
    const errorCount = computed(() => assignment.value.internationalErrorsCount + assignment.value.domesticErrorsCount)
    const isShowAssignmentInfo = computed(() => user.value.shortName || user.value.clientNumber || user.value.company)
    const isUserCanBackToEdit = computed(() => isStaff.value && assignment.value.staff?.id === userId.value)
    const dynamicPricesLink = computed(() => ({
      name: STAFF_ASSIGNMENT_PRICES,
      id: route.params.id,
    }))
    const sourceFile = computed(() => assignment.value?.sourceFiles[0]?.fileUrl || '')
    const reportFile = computed(() => assignment.value?.optimisationReportUrl || '')
    const exportLink = computed(
      () => `${import.meta.env.VITE_APP_API_LOCATION}/assignments/${assignment.value.id}/export`,
    )

    const actionTooltip = (type = '', disabled = false) => (disabled ? '' : `Download ${type} file`)

    const backToEdit = () => {
      const text =
        assignment.value.status.split('_')[0] === 'report'
          ? 'Report was already created'
          : 'Labels were already released'
      openModal('confirmWrap', {
        title: 'Are you sure you want to edit the assignment?',
        message: `${text}, it will be automatically denied if you start editing the assignment. You will then have to create a new report.`,
        onConfirm: () => {
          store.dispatch('assignment/submitReview')
        },
      })
    }

    onMounted(() => {
      registerModals(SERVICE_LEVEL_MODAL)
    })

    onBeforeUnmount(() => {
      window.Echo.leave('App.Assignments')
    })

    onBeforeMount(() => {
      window.Echo.channel('App.Assignments').listen(
        '\\App\\Events\\Assignment\\AssignmentStatusChanged',
        (data: any) => {
          if (data.assignment.id === assignment.value.id) {
            store.dispatch('assignment/fetch').then(none)
          }
        },
      )
    })

    return {
      assignment,
      isAvailableBackButton,
      isItemsEditable,
      assignmentStatus,
      user,
      userId,
      isStaff,
      isHidePrice,
      errorCount,
      isShowAssignmentInfo,
      isUserCanBackToEdit,
      dynamicPricesLink,
      sourceFile,
      reportFile,
      exportLink,
      openModal,
      pluralize,
      backToEdit,
      actionTooltip,
      toTimeFromNow,
      can: (rule: string, section: string) => ability.value.can(rule, section),
      assignmentsPage: { name: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST },
      SERVICE_LEVEL_MODAL,
    }
  },
})
</script>

<style lang="scss" scoped>
.border-r {
  border-right: 1px solid #cccccc;
}
.border-l {
  border-left: 1px solid #cccccc;
}
.status {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.file-btn {
  background: transparent;
  padding: 0;
  border: 0;
  color: #000000;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    max-height: 24px;
  }

  &:first-child {
    margin-right: 18px;
  }

  &.disabled {
    opacity: 1;
    color: #c8c8c8;
  }
}
.btn-height-md {
  height: 40px !important;
  line-height: 20px !important;
}
.user-info {
  .color-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #757575;
  }
  .color-black {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #151515;
  }
}

.border-l-none {
  border-left: none;
}

.assignment-function {
  .btn {
    width: auto;
    max-height: 40px;
    min-height: auto;
    padding: 9px 24px;
    font-size: 14px;
    color: #757575;
    border-color: #cccccc;
  }

  .btn:hover {
    color: #fff;
  }
}
</style>
