<template>
  <div class="w-100">
    <div class="w-100 d-flex justify-content-between mb-3 filters">
      <TableTitle icon="file-document-outline" title="Assignments" />
      <FiltersWrap />
      <AddButton />
      <LabelsButton v-if="checkedAssignments.length" class="ml-2" :assignments="checkedAssignments" />
    </div>
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      v-model:items-selected="checkedAssignments"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data>
        <div style="text-align: center">No data available</div>
      </template>
      <template #item="{ column, item }">
        <span v-if="column === 'createdAt'">
          <RowDate :date="item.createdAt" />
        </span>
        <span v-if="column === 'reference'">
          <ReferenceCell :assignment="item" />
        </span>
        <span v-if="column === 'customer'">
          <CustomerCell :assignment="item" />
        </span>
        <span v-if="column === 'details'">
          <DetailsCell :assignment="item" />
        </span>
        <span v-if="column === 'features'">
          <FeaturesCell :assignment="item" />
        </span>
        <span v-if="column === 'carrier'">
          <CarriersCell :assignment="item" />
        </span>
        <span v-if="column === 'cost'">
          <CostCell :assignment="item" />
        </span>
        <span v-if="column === 'status'">
          <StatusCell :assignment="item" />
        </span>
        <span v-if="column === 'actions'">
          <ActionsCell :assignment="item" />
        </span>
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import RowDate from '@/views/Components/Elements/Table/RowDate.vue'
import TableTitle from '@/views/Components/Elements/Table/TableTitle.vue'
import CustomerCell from '@/views/Components/Assignment/Cells/Customer.vue'
import StatusCell from '@/views/Components/Assignment/Cells/Status.vue'
import LabelsButton from '@/views/Components/Assignment/LabelsButton.vue'
import CarriersCell from '@/views/Components/Assignment/Cells/Carriers.vue'
import ReferenceCell from '@/views/Components/Assignment/Cells/Reference.vue'
import ActionsCell from '@/views/Components/Assignment/Cells/Actions.vue'
import DetailsCell from '@/views/Components/Assignment/Cells/DetailsCell.vue'
import FeaturesCell from '@/views/Components/Assignment/Cells/Features.vue'
import CostCell from '@/views/Components/Assignment/Cells/Cost.vue'
import FiltersWrap from '@/views/Components/Assignment/Filters/FiltersWrap.vue'
import AddButton from '@/views/Components/Assignment/AddButton.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import AssignmentsListTransformer from '@/transformers/AssignmentsListTransformer'
import EasyDataTable from 'vue3-easy-data-table'
import type { Ref } from 'vue'
import type { ListAssignment, ListAssignmentResponse } from '@/types/Models/Assignment'
import type { AxiosCollection } from '@/types/Components/Response'

export default defineComponent({
  name: 'AssignmentsList',
  components: {
    TablePagination,
    RowDate,
    TableTitle,
    CustomerCell,
    StatusCell,
    LabelsButton,
    CarriersCell,
    ReferenceCell,
    ActionsCell,
    DetailsCell,
    FeaturesCell,
    CostCell,
    FiltersWrap,
    AddButton,
    EasyDataTable,
  },
  props: {
    version: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute()
    const dataTable = ref()
    const items: Ref<ListAssignment[]> = ref([])
    const error: Ref<boolean> = ref(false)
    const loading: Ref<boolean> = ref(false)
    const serverItemsLength: Ref<number> = ref(0)
    const checkedAssignments: Ref<any[]> = ref([])
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 50,
    })

    const headers = [
      { text: 'Date', value: 'createdAt' },
      { text: 'Ref', value: 'reference' },
      { text: 'Customer', value: 'customer' },
      { text: 'Details', value: 'details' },
      { text: 'Features', value: 'features' },
      { text: 'Carrier', value: 'carrier' },
      { text: 'Cost', value: 'cost' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions' },
    ]

    const loadFromServer = async () => {
      loading.value = true
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        orderBy: 'createdAt',
        archiveMode: 'active',
      }

      new AssignmentProxy({ ...params, ...route.query })
        .all()
        .then((response: AxiosCollection<ListAssignmentResponse>) => {
          items.value = AssignmentsListTransformer.fetchCollection(response.data)
          serverItemsLength.value = response.count || 0
          loading.value = false
          return response
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
          error.value = true
        })
    }

    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    const updateAssignmentStatus = (assignment: ListAssignment) => {
      if (!assignment.id) {
        return
      }
      const index = items.value.findIndex((a) => a.id === assignment.id)
      if (index !== -1) {
        items.value[index].status = assignment.status
        items.value[index].itemsCount = assignment.itemsCount
      }
    }

    onMounted(loadFromServer)
    watch(serverOptions, loadFromServer)
    watch(() => props.version, loadFromServer)

    onBeforeUnmount(() => {
      window.Echo.leave('App.Assignments')
    })

    onBeforeMount(() => {
      window.Echo.channel('App.Assignments').listen(
        '\\App\\Events\\Assignment\\AssignmentStatusChanged',
        (data: any) => {
          updateAssignmentStatus(data.assignment)
        },
      )
    })

    return {
      error,
      items,
      loading,
      headers,
      dataTable,
      serverOptions,
      serverItemsLength,
      checkedAssignments,
      updateServerOptions,
    }
  },
})
</script>
