<template>
  <div class="mh-v login-page">
    <header class="main-header">
      <div class="container">
        <div class="d-flex h-110">
          <div class="logo">
            <a href="https://www.21grams.se/">
              <img src="@/assets/img/logo.svg" alt="" />
            </a>
          </div>
          <div class="header-title align-self-center">
            <img src="@/assets/img/21grams-white-logo.svg" alt="" />
          </div>
        </div>
      </div>
    </header>

    <div class="main-full-height">
      <div class="container">
        <div class="wrap-form">
          <b-alert :model-value="isCredentialsError" variant="danger">Invalid credentials</b-alert>
          <div>
            <VeeForm @submit="login">
              <VInput v-model="user.email" type="text" name="Email" rules="emailPhoneRequired" />
              <VInput
                v-model="user.password"
                class="mb-0 inspectletIgnore"
                type="password"
                name="Password"
                rules="required"
              />
              <b-button class="w-100" type="submit" :disabled="isDisabled()"> Login </b-button>
            </VeeForm>
            <div class="d-flex mt-4 justify-content-between">
              <router-link :to="{ name: registerLink }" class="link">
                Don't have an account? Request access
              </router-link>

              <router-link :to="{ name: forgotPasswordLink }" class="link"> Forgot Password? </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pattern" />

    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'
import { Form as VeeForm } from 'vee-validate'
import { useStore } from 'vuex'
import { CUSTOMER_FORGOT_PASSWORD, REGISTRATION } from '@/types/router'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import VInput from '@/views/Components/Elements/Form/VInput.vue'

export default defineComponent({
  name: 'LoginIndex',
  components: {
    VeeForm,
    LayoutFooter,
    VInput,
  },
  setup() {
    const user = ref({
      email: null,
      password: null,
    })
    const errors = ref({})
    const store = useStore()
    const isCredentialsError = ref(false)
    const registerLink = computed(() => REGISTRATION)
    const forgotPasswordLink = computed(() => CUSTOMER_FORGOT_PASSWORD)

    const isDisabled = () => {
      return !user.value.email || !user.value.password
    }

    const login = async () => {
      try {
        await store.dispatch('auth/login', user.value)
      } catch (err) {
        isCredentialsError.value = true
      }
    }

    return {
      user,
      errors,
      isCredentialsError,
      registerLink,
      forgotPasswordLink,
      isDisabled,
      login,
    }
  },
})
</script>

<style scoped>
.link {
  color: #ffffff;
}
</style>
