// DefaultActions.ts
import { notify } from '@kyvg/vue3-notification'
import { none } from '@/services/Helpers'
import { openModal } from '@/services/Modal'
import { LABELS_CREATING_LOADER, OPTIMIZE_CREATING_REPORT_LOADER } from '@/const/LoadersNames'
import store from '@/store'
import router from '@/router'

export default class CustomerActions {
  static submitOptimize() {
    const msg =
      "The file looks good. Next we'll run the optimization and create report. Are you sure you want to proceed?"
    openModal('confirmWrap', {
      title: 'Optimize & Create Report',
      message: msg,
      onConfirm: () => {
        store
          .dispatch('ui/loaderShow', {
            name: OPTIMIZE_CREATING_REPORT_LOADER,
            data: { progress: 0 },
          })
          .then(none)
        store.dispatch('assignment/submitOptimize').then(none)
      },
    })
  }

  static createLabels() {
    store
      .dispatch('ui/loaderShow', {
        name: LABELS_CREATING_LOADER,
        data: { progress: 0 },
      })
      .then(none)
    store.dispatch('assignment/createLabels', { id: router.currentRoute.value.params.id }).catch(() => {
      notify({ group: 'error', text: 'Labels Create Error' })
    })
  }

  static createMissingLabels() {
    const msg =
      'Some labels failed to create. ' +
      'Labels cannot be released until all of them are created or empty rows deleted. ' +
      'Proceeding will create labels only for parcels that still have no tracking number.'
    openModal('confirmWrap', {
      title: 'Create missing Labels',
      message: msg,
      okButtonText: 'Create missing Labels',
      onConfirm: () => {
        store
          .dispatch('ui/loaderShow', {
            name: LABELS_CREATING_LOADER,
            data: { progress: 0 },
          })
          .then(none)
        store.dispatch('assignment/createLabels', { id: router.currentRoute.value.params.id }).catch(() => {
          notify({ group: 'error', text: 'Labels Create Error' })
        })
      },
    })
  }
}
