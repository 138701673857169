<template>
  <div class="bg-light">
    <header class="main-header">
      <div class="container">
        <div class="d-flex h-110">
          <div class="logo">
            <router-link :to="{ name: loginPageLink }">
              <img src="@/assets/img/logo.svg" alt="" />
            </router-link>
          </div>
          <div class="header-title align-self-center">
            <img src="@/assets/img/21grams-dark-logo.svg" alt="" />
          </div>
        </div>
      </div>
    </header>
    <div class="page-body">
      <template v-if="!created">
        <h5 class="form-title title center-text">
          Interested in ParcelPortal?
          <br />
          Fill the form and we will contact you.
        </h5>
        <div class="container">
          <div class="wrap-form">
            <div>
              <VeeForm @submit="createCustomer">
                <Field v-slot="{ field, errors }" name="email" :rules="rules.email">
                  <b-form-group class="form-group position-relative">
                    <input
                      v-bind="field"
                      v-model="customer.email"
                      type="text"
                      name="Email address *"
                      class="form-control"
                      placeholder="Email address *"
                    />
                    <div class="invalid-feedback">
                      {{ errors.length ? errors.join() : '' }}
                    </div>
                  </b-form-group>
                </Field>
                <Field v-slot="{ field, errors }" name="firstName" :rules="rules.lettersField">
                  <b-form-group class="form-group position-relative">
                    <input
                      v-bind="field"
                      v-model="customer.firstName"
                      type="text"
                      name="First name *"
                      class="form-control"
                      placeholder="First name *"
                    />
                    <div class="invalid-feedback">
                      {{ errors.length ? errors.join() : '' }}
                    </div>
                  </b-form-group>
                </Field>
                <Field v-slot="{ field, errors }" name="lastName" :rules="rules.lettersField">
                  <b-form-group class="form-group position-relative">
                    <input
                      v-bind="field"
                      v-model="customer.lastName"
                      type="text"
                      name="Last name *"
                      class="form-control"
                      placeholder="Last name *"
                    />
                    <div class="invalid-feedback">
                      {{ errors.length ? errors.join() : '' }}
                    </div>
                  </b-form-group>
                </Field>
                <Field v-slot="{ field, errors }" name="company" rules="required">
                  <b-form-group class="form-group position-relative">
                    <input
                      v-bind="field"
                      v-model="customer.company"
                      type="text"
                      name="Company *"
                      class="form-control"
                      placeholder="Company *"
                    />
                    <div class="invalid-feedback">
                      {{ errors.length ? errors.join() : '' }}
                    </div>
                  </b-form-group>
                </Field>
                <Field v-slot="{ field, errors }" name="phoneNumber" :rules="{ phone: true, required: true }">
                  <b-form-group class="form-group position-relative">
                    <input
                      v-bind="field"
                      v-model="customer.phoneNumber"
                      type="text"
                      name="Phone number*"
                      class="form-control"
                      placeholder="Phone number*"
                    />
                    <div class="invalid-feedback">
                      {{ errors.length ? errors.join() : '' }}
                    </div>
                  </b-form-group>
                </Field>
                <b-alert :show="serverErrors" variant="danger">
                  Customer not created, please check fields errors or contact administrator
                </b-alert>
                <b-button type="submit" class="w-100"> Request Access </b-button>
              </VeeForm>
              <router-link :to="{ name: loginPageLink }" class="color-grey">
                Already have an account? Log in
              </router-link>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="main-full-height">
          <div class="container">
            <div class="wrap-form center-text">
              <h5 class="center-text mb-4">Thanks for registering your interest! We will contact you soon.</h5>
              <b-button class="assignmentButton mt-2" type="submit" :to="toLogin"> Log In </b-button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { removeEmpty } from '@/services/Helpers'
import { useNotification } from '@kyvg/vue3-notification'
import { Form as VeeForm, Field } from 'vee-validate'
import { LOGIN_PAGE } from '@/types/router'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'

export default defineComponent({
  name: 'CustomerRegistration',
  components: {
    VeeForm,
    Field,
    LayoutFooter,
  },
  setup() {
    const customer = ref({
      email: '',
      clientNumber: null,
      firstName: '',
      lastName: '',
      company: '',
      clientAddress: {
        countryCode: null,
        city: null,
        zip: null,
        address1: null,
        address2: null,
      },
      senderAddress: {
        countryCode: null,
        city: null,
        zip: null,
        address1: null,
        address2: null,
      },
      phoneNumber: '',
      shortName: '',
      contractParty: null,
      salesmanId: null,
      sendNotifications: true,
    })

    const errorsList = ref({})
    const created = ref(false)
    const serverErrors = ref(false)

    const store = useStore()
    const { notify } = useNotification()

    const rules = computed(() => ({
      email: {
        email: true,
        required: true,
        min: 3,
      },
      clientNumber: {
        integer: true,
        required: true,
      },
      lettersField: {
        required: true,
        min: 3,
      },
      shortName: {
        max: 4,
        required: false,
      },
    }))

    const toLogin = computed(() => ({
      name: LOGIN_PAGE,
    }))

    const loginPageLink = computed(() => LOGIN_PAGE)

    const createCustomer = async (values: any) => {
      const data = removeEmpty(values)
      try {
        await store.dispatch('customers/requestCreate', data)
        errorsList.value = {}
        created.value = true
      } catch (response: any) {
        errorsList.value = response.errors
        serverErrors.value = true
        Object.values(response.errors).forEach((errorGroup: any) => {
          errorGroup.forEach((error: string) => {
            notify({ type: 'error', text: error })
          })
        })
      }
    }

    return {
      customer,
      created,
      rules,
      toLogin,
      serverErrors,
      loginPageLink,
      createCustomer,
    }
  },
})
</script>

<style lang="scss" scoped>
.client-city {
  width: 390px;
  margin-right: 16px;
}

.form-title {
  width: 600px;
  margin: 80px auto 0 auto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}

.assignmentButton {
  width: 365px;
}

:deep(.form-control) {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  color: #757575;
}

:deep(.custom-select) {
  border: 1px solid #cccccc;
  padding-left: 25px;
  color: #757575;
}

.color-grey {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #757575;
  margin-top: 12px;
}
.bg-light {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
footer {
  margin-top: auto;
}
</style>
