<template>
  <div>
    <div class="container mb-5">
      <h2 class="form-title">
        {{ assignmentId ? 'Edit Single Assignment' : 'Create new assigment' }}
      </h2>
      <VeeForm class="assignment-form box-shadow" @submit="submit">
        <div v-if="isStaff">
          <div class="form-group">
            <label class="required fs-xl mt-0"> Customer </label>
            <div class="input-group">
              <CustomSelect
                placeholder="Choose client"
                :selected="assignment.customer_id"
                :options="customers"
                :selectable="(option: any) => !option.status.includes('inactive')"
                :filter="clientFilter"
                @selected="chooseClient"
              >
                <template #custom_option="{ option }">
                  <div :class="'item-' + option.status">
                    <span class="status" />
                    <span class="name">{{ option.company || option.name }}</span>
                    (Client number: {{ option.clientNumber }}; Email: {{ option.email }}; Phone:
                    {{ option.phoneNumber }})
                  </div>
                </template>
                <template #custom_list-footer>
                  <div class="list-footer">
                    <div class="create-client" @click="openModal(CREATE_NEW_CUSTOMER)">Create New Customer</div>
                  </div>
                </template>
              </CustomSelect>
            </div>
            <Field v-if="!isStaff" name="Customer" :rules="rules.customer_id">
              <template #default="{ field, errors }">
                <b-form-group>
                  <div class="input-group">
                    <b-form-input
                      v-bind="field"
                      :state="errors.length === 0"
                      type="text"
                      class="hidden"
                      placeholder="Description"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </template>
            </Field>
            <div v-if="errorsList.customer_id.length" class="text-danger">
              {{ errorsList.customer_id }}
            </div>
          </div>
        </div>

        <div class="mb-4" :class="{ 'mt-4 mb-0': isStaff }">
          <label class="fs-xl mt-0">Sender Address</label>
          <CustomSelect
            :selected="assignment.sender_address_id"
            :options="senderAddresses"
            placeholder="Choose sender address"
            class="w-100"
            @selected="selectSenderAddress"
          >
            <template #custom_option="{ option }">
              <div :class="'d-flex align-items-baseline item-' + option.id">
                <div class="d-flex justify-content-between w-100 align-items-center">
                  <div class="d-flex align-items-baseline">
                    <span class="status" />
                    <span class="name d-block mr-2">{{ option.company || option.fullName }} </span>
                    <div>
                      {{ option.countryCode }}, {{ option.city }},
                      {{ option.addressLine }}
                    </div>
                    <div v-if="isStaff" class="d-flex ml-2">
                      <b-badge v-if="option.customNumbers?.length" class="mt-0" variant="primary">
                        Customer Numbers
                      </b-badge>
                      <b-badge v-if="option.isDefault === 1" class="ml-1 mt-0" variant="primary">
                        Default Address
                      </b-badge>
                      <b-badge v-if="option.hasCustomPrices === 1" variant="success" class="ml-1">
                        Sender prices
                      </b-badge>
                      <b-badge v-if="option.hasCustomPriorities === 1" variant="warning" class="ml-1">
                        Sender carriers
                      </b-badge>
                    </div>
                  </div>
                  <div v-if="!isStaff">
                    <b-button
                      class="d-flex align-items-center justify-content-center action-btn"
                      variant="outline-secondary"
                      @click="openSenderAddressEditModal(option.id)"
                    >
                      <mdicon name="pencil-outline" class="pointer" size="18" />
                    </b-button>
                  </div>
                </div>
              </div>
            </template>
            <template #custom_list-footer>
              <div v-if="assignment.customer_id || (!isStaff && userId)" class="list-footer">
                <div
                  class="create-client"
                  @click="
                    openModal(SENDER_ADDRESS_ACTION, {
                      customerId: assignment.customer_id || userId,
                      address: '',
                      canHasCustomerNumbers: customerCanHasCustomerNumbers,
                    })
                  "
                >
                  Create New Sender Address
                </div>
              </div>
            </template>
          </CustomSelect>
          <div v-if="errorsList.sender_address_id.length" class="text-danger">
            {{ errorsList.sender_address_id }}
          </div>
        </div>

        <div class="form-group">
          <div class="label required fs-xl" :class="{ 'mt-0': !isStaff }">Assignment type</div>
          <div class="row">
            <div class="col-12 col-md-6">
              <input
                id="consolidated_type"
                v-model="assignment.assignment_type"
                name="assignment_type"
                type="radio"
                value="consolidate"
              />
              <label for="consolidated_type" class="radio-group">
                <span class="radio-title"> Batch shipment (Upload file) </span>
              </label>
            </div>
            <div class="col-12 col-md-6">
              <input
                id="single_type"
                v-model="assignment.assignment_type"
                name="assignment_type"
                type="radio"
                value="single"
              />
              <label for="single_type" class="radio-group">
                <span class="radio-title">Single package (Enter address)</span>
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="label required fs-xl" :class="{ 'mt-0': !isStaff }">Distribution Channel</div>
          <div class="row">
            <div v-for="(channel, i) in distributionChannels" :key="i" :class="channel.class">
              <input
                v-if="!channel.staffOnly || isStaff"
                :id="'type_' + channel.code"
                v-model="assignment.distribution_channel"
                :checked="assignment.distribution_channel === channel.code"
                :value="channel.code"
                name="distribution_channel"
                type="radio"
              />
              <label v-if="!channel.staffOnly || isStaff" :for="'type_' + channel.code" class="radio-group">
                <span class="radio-title">
                  <span v-if="channel.icon" :class="channel.icon" />
                  {{ channel.name }}
                </span>
                <span class="radio-description">{{ channel.description }}</span>
                <span v-if="channel.beta" class="company-name-info">
                  <img src="@/assets/img/info-circle-outlined.svg" alt="" class="mr-1" /> beta
                </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="assignment.assignment_type === CONSOLIDATE" class="row">
          <div class="col-12 col-md-9">
            <div class="form-group">
              <label class="required"> Select files </label>
              <div class="input-group">
                <div class="select-file">
                  <input id="file" type="file" accept=".csv,.xlsx,.xls,.txt" @change="fileSelected($event)" />
                  <div v-if="assignment.file && assignment.file.name">
                    <span>{{ assignment.file.name }}</span>
                  </div>
                  <div v-else><span>Choose file</span> or drop file here</div>
                </div>
              </div>
              <div v-if="errorsList.file.length" class="text-danger">
                {{ errorsList.file }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <label>File requirements</label>
            <div class="file-requirements">
              <div class="requirements-list">
                <div>Format: <strong>CSV, TXT, XLSX</strong></div>
                <div>Max size: <strong>50mb</strong></div>
              </div>
              <a
                v-b-tooltip="{
                  title:
                    'CSV files support most common delimiters are: a comma\n' +
                    '<code>,</code>, semicolon <code>;</code> and tab <code>\\t</code>',
                }"
                href="/sample-spreadsheet.txt"
                class="download-sample"
                download="sample-spreadsheet.txt"
              >
                Download TXT example files
              </a>
              <a
                v-b-tooltip="{
                  title:
                    'TXT files support most common delimiters are: a comma\n' +
                    '<code>,</code>, semicolon <code>;</code> and tab <code>\\t</code>',
                }"
                href="/sample-spreadsheet.csv"
                class="download-sample"
                download="sample-spreadsheet.csv"
              >
                Download CSV example files
              </a>
              <a href="/sample-spreadsheet.xlsx" class="download-sample" download="sample-spreadsheet.xlsx">
                Download XLSX example file
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <div class="shipping-address-heading">
              <div class="label required fs-xl">Shipping address</div>
              <b-button class="ship-to-terminal-btn" @click="fillTerminalAddress">Ship to Terminal</b-button>
            </div>

            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Company</label>
                  <span class="company-name-info">
                    <img src="@/assets/img/info-circle-outlined.svg" alt="" class="mr-1" />
                    Insert only, if sending to company
                  </span>
                  <VInput
                    v-model="assignment.item.company_name"
                    :rules="{ required: false, min: 3 }"
                    type="text"
                    vid="item.company_name"
                    name="item.company_name"
                    placeholder="Company"
                    @input="companyNameChange"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="form-group pl-0 m-0 col-6 pr-12">
                    <label class="required">First Name</label>
                    <VInput
                      v-model="assignment.item.first_name"
                      type="text"
                      :rules="{ required: true }"
                      vid="item.first_name"
                      name="item.first_name"
                      placeholder="First Name *"
                    />
                  </div>
                  <div class="form-group p-0 m-0 col-6">
                    <label>Last Name</label>
                    <VInput
                      v-model="assignment.item.last_name"
                      type="text"
                      :rules="{ permissible: true }"
                      vid="item.last_name"
                      name="item.last_name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label class="required">Address 1</label>
                  <VInput
                    v-model="assignment.item.address"
                    :rules="{ required: true, min: 3, max: 30 }"
                    type="text"
                    vid="item.address"
                    name="item.address"
                    placeholder="Client Address 1 *"
                  />
                </div>
                <div class="form-group">
                  <label>Address 2</label>
                  <VInput
                    v-model="assignment.item.secondary_address"
                    :rules="{ required: false, min: 3, max: 30 }"
                    type="text"
                    vid="item.secondary_address"
                    name="item.secondary_address"
                    placeholder="Client Address 2"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="form-group mb-0">
                    <label class="required">ZIP</label>
                    <VInput
                      v-model="assignment.item.postal_code"
                      type="text"
                      :rules="{ required: true, zip: assignment.item.country_code }"
                      vid="postal_code"
                      name="item.postal_code"
                      placeholder="ZIP *"
                    />
                  </div>
                  <div class="form-group client-city mb-0">
                    <label :class="{ required: true }">City</label>
                    <VInputDatalist
                      id="city"
                      v-model="assignment.item.city"
                      type="text"
                      :list="cities"
                      :rules="{ required: true }"
                      vid="item.city"
                      name="item.city"
                      placeholder="City *"
                      @input="onCityUpdate"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div class="form-group">
                    <label class="required">Country</label>
                    <CountryPicker
                      :value="assignment.item.country_code"
                      :disabled="false"
                      vinput-id="country"
                      name="item.country_code"
                      vid="country_code"
                      placeholder="Choose country *"
                      :required="true"
                      @change="onCountryUpdate"
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :class="{
                        required: (isSwedenPicked && assignment.item.phone_number.length === 0) || !isSwedenPicked,
                      }"
                    >
                      Email Address
                    </label>
                    <VInput
                      v-model="assignment.item.email"
                      type="text"
                      vid="item.email"
                      name="item.email"
                      :rules="rules.emailRule"
                      :placeholder="
                        (isSwedenPicked && assignment.item.phone_number.length === 0) || !isSwedenPicked
                          ? 'Email address *'
                          : 'Email address'
                      "
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :class="{
                        required: (isSwedenPicked && assignment.item.email.length === 0) || !isSwedenPicked,
                      }"
                    >
                      SMS-Number
                    </label>
                    <VInput
                      v-model="assignment.item.phone_number"
                      type="text"
                      vid="item.phone_number"
                      name="item.phone_number"
                      :rules="rules.phoneRule"
                      :formatter="numbersOnly"
                      :placeholder="
                        (isSwedenPicked && assignment.item.email.length === 0) || !isSwedenPicked
                          ? 'SMS-Number *'
                          : 'SMS-Number'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ParcelCharacteristics
            :parcels="assignment.item.parcel_characteristics"
            :show-add-parcel="assignment.distribution_channel === PARCEL"
            :show-estimated-cost="!isSwedenPicked && !isParcelNonEU"
            :show-warning="!!parcelWarningText"
            :warning-text="parcelWarningText"
            :fields-limits="parcelLimits"
            :show-req-link="false"
            @parcels-changed="changeParcels"
          />

          <div v-if="isParcelNonEU">
            <div class="row">
              <div class="col-md-3">
                <div class="label required fs-xl">Customs Invoice</div>
                <div class="form-group mb-0">
                  <label>Category:</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <VSelect
                    v-model="assignment.item.invoiceType"
                    :options="categories"
                    :rules="{ required: true }"
                    placeholder="Category *"
                    fieldset-class="category-select"
                    vid="item.invoiceType"
                    name="Category"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group currency-dropdown">
                  <dropdown
                    :with-search="true"
                    :options="currencies"
                    :dropdown-text="`${selectedCurrency.icon} ${selectedCurrency.value}`"
                    @selected="(e: any) => (assignment.item.currency = e.value)"
                  />
                </div>
              </div>
              <div class="col-md-6 mt-3 mt-md-0">
                <div class="custom-alert">
                  <div class="custom-alert-image">
                    <mdicon name="Alert" />
                  </div>
                  <div class="custom-alert-text">
                    Customs declaration must be filled out correctly. If in doubt, please read more
                    <router-link :to="{ name: PROFORMA_INVOICE }" target="_blank"> here </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12">
                  <table class="custom-table">
                    <thead>
                      <tr>
                        <th style="width: 60px" class="text-center">Delete</th>
                        <th style="width: 80px">Quantity</th>
                        <th style="width: 160px">Country Of Origin</th>
                        <th>Content (in English)</th>
                        <th>Commodity code</th>
                        <th style="width: 80px">Unit weight (g)</th>
                        <th style="width: 80px">Unit value</th>
                        <th style="width: 80px" v-text="`Total value (${selectedCurrency.sign})`" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product, index) in assignment.item.products" :key="`invoice-product-${index}`">
                        <td class="text-center">
                          <transition>
                            <button
                              v-if="assignment.item.products.length > 1"
                              type="button"
                              @click="removePackage(index)"
                            >
                              <mdicon name="TrashCanOutline" />
                            </button>
                          </transition>
                        </td>
                        <td>
                          <Field
                            v-slot="{ errors }"
                            :model-value="product.quantity"
                            name="product.quantity"
                            rules="required"
                          >
                            <input v-model="product.quantity" type="number" placeholder=" " class="form-control" />
                            <div class="invalid-feedback">
                              {{ errors.length ? errors.join() : '' }}
                            </div>
                          </Field>
                        </td>
                        <td>
                          <VSelect
                            v-model="product.countryCode"
                            :options="countriesCode"
                            rules="required"
                            placeholder=""
                            vid="country"
                            name="country"
                          />
                        </td>
                        <td>
                          <Field
                            v-slot="{ errors }"
                            :model-value="product.description"
                            :rules="{ required: true, min: 1 }"
                            name="product.description"
                          >
                            <input v-model="product.description" type="text" placeholder=" " class="form-control" />
                            <div class="invalid-feedback">
                              {{ errors.length ? errors.join() : '' }}
                            </div>
                          </Field>
                        </td>
                        <td>
                          <Field
                            v-slot="{ errors }"
                            :model-value="product.commodityCode"
                            name="product.commodityCode"
                            :rules="{
                              required: true,
                              commodityCode: true,
                            }"
                          >
                            <input v-model="product.commodityCode" type="number" placeholder=" " class="form-control" />
                            <div class="invalid-feedback">
                              {{ errors.length ? errors.join() : '' }}
                            </div>
                          </Field>
                        </td>
                        <td>
                          <Field
                            v-slot="{ errors }"
                            name="product.weight"
                            :model-value="product.weight"
                            :rules="{ required: true, min_value: 1 }"
                          >
                            <input v-model="product.weight" type="number" placeholder=" " class="form-control" />
                            <div class="invalid-feedback">
                              {{ errors.length ? errors.join() : '' }}
                            </div>
                          </Field>
                        </td>
                        <td>
                          <Field
                            v-slot="{ errors }"
                            name="product.estimatedCost"
                            :model-value="product.estimatedCost"
                            :rules="{ required: true, min_value: 0.1 }"
                          >
                            <input v-model="product.estimatedCost" type="number" placeholder=" " class="form-control" />
                            <div class="invalid-feedback">
                              {{ errors.length ? errors.join() : '' }}
                            </div>
                          </Field>
                        </td>
                        <td>
                          <div class="total-count">
                            {{ product.quantity * Number(product.estimatedCost) }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-0">
                          <span class="d-flex align-items-center add-package" @click="addNewPackage">
                            <img src="@/assets/img/add-client-icon.svg" alt="" class="mr-2" />
                            Add new package
                          </span>
                        </td>
                        <td class="border-0" colspan="5" />
                        <td>
                          <div class="total-count" v-text="`${totalInvoiceCosts || 0} ${selectedCurrency.sign}`" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="![PARCEL, LETTER].includes(assignment.distribution_channel)" class="form-group">
          <div class="label required fs-xl">Choose Service Level</div>
          <div class="row">
            <div class="col-12 col-md-6">
              <input id="service_level_priority" v-model="assignment.service_level" type="radio" value="priority" />
              <label for="service_level_priority" class="radio-group">
                <span class="radio-title">Priority</span>
              </label>
            </div>
            <div class="col-12 col-md-6">
              <input id="service_level_economy" v-model="assignment.service_level" type="radio" value="economy" />
              <label for="service_level_economy" class="radio-group">
                <span class="radio-title">Economy</span>
                <span v-if="showDeliveryTime" class="radio-description"> 1-4 days </span>
              </label>
            </div>
          </div>
        </div>

        <div
          v-if="assignment.assignment_type === SINGLE && assignment.distribution_channel === PARCEL"
          class="pickup-block"
        >
          <PickupCalendar
            heading="Book Pickup"
            :months-bold="true"
            :pickup-error="errorsList.pickup_date"
            :max-period="maxPickupPeriod"
            @select-date="setPickUpDate"
          />

          <b-form-group class="mt-5 pickup-instructions">
            <label>Pickup Instructions</label>
            <div class="input-group">
              <input
                v-model="assignment.pickup_instructions"
                type="text"
                placeholder="Eg. Pickup behind the little small red house"
                class="form-control"
              />
            </div>
          </b-form-group>
        </div>
        <b-form-group>
          <label>Description</label>
          <VInput
            v-model="assignment.description"
            type="text"
            :rules="rules.description"
            vid="customer_reference"
            name="Description"
            placeholder="Description"
          />
        </b-form-group>
        <b-form-group v-if="assignment.assignment_type !== SINGLE">
          <label>Message</label>
          <textarea v-model="assignment.message" class="form-control" placeholder="Message" rows="5" />
        </b-form-group>
        <b-form-group>
          <label class="fs-xl">Invoice Reference</label>
          <VInput
            v-model="assignment.customer_reference"
            type="text"
            :rules="{ required: false }"
            vid="customer_reference"
            name="customer_reference"
            placeholder="Here you can add a reference for your account administrator or billing administrator"
          />
        </b-form-group>
        <div class="form-group mt-4">
          <b-button type="submit" class="btn create-btn btn-green">
            {{ assignmentId ? 'Update Assignment' : 'Create new assigment' }}
          </b-button>
        </div>
      </VeeForm>
      <SourceFileErrorsModal :id="CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS" />
    </div>
    <CreateNewCustomerModal :id="CREATE_NEW_CUSTOMER" />
    <SenderAddressActionModal
      :id="SENDER_ADDRESS_ACTION"
      :address-id="modalAddressId"
      @refresh="fetchSenderAddresses"
    />
    <RequestErrorModal :id="REQUEST_ERROR" />
  </div>
</template>

<script lang="ts">
import {
  ref,
  reactive,
  computed,
  onMounted,
  watch,
  onUnmounted,
  defineComponent,
  toRaw,
  type ComputedRef,
  type Ref,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { buildFormData } from '@/services/Form'
import { openModal, registerModals } from '@/services/Modal'
import { getProformaInvoiceTypes } from '@/services/Utils'
import { numbersOnly } from '@/services/Helpers'
import { Form as VeeForm, Field } from 'vee-validate'
import { BOX, LETTER, PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { ADDRESS_DELIVERY, SERVICE_POINT_DELIVERY } from '@/const/AssignmentDeliveryTypes'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import { OTHER } from '@/const/ProformaInvoiceTypes'
import {
  CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
  CREATE_NEW_CUSTOMER,
  REQUEST_ERROR,
  SENDER_ADDRESS_ACTION,
} from '@/const/ModalTypes'
import {
  CUSTOMER_ASSIGNMENT_SUMMARY,
  CUSTOMER_ASSIGNMENTS_LIST,
  PROFORMA_INVOICE,
  STAFF_ASSIGNMENT_SUMMARY,
  STAFF_ASSIGNMENTS_LIST,
} from '@/types/router'
import VInput from '@/views/Components/Elements/Form/VInput.vue'
import VSelect from '@/views/Components/Elements/Form/VSelect.vue'
import Dropdown from '@/views/Components/Elements/Dropdown.vue'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'
import CountryPicker from '@/views/Components/Elements/Form/CountryPicker.vue'
import VInputDatalist from '@/views/Components/Elements/Form/VInputDatalist.vue'
import PickupCalendar from '@/views/Components/Assignment/PickupCalendar.vue'
import RequestErrorModal from '@/views/Modals/RequestErrorModal.vue'
import ParcelCharacteristics from '@/views/Components/Assignment/ParcelCharacteristics/ParcelCharacteristics.vue'
import SourceFileErrorsModal from '@/views/Modals/Assignment/SourceFileErrorsModal.vue'
import CreateNewCustomerModal from '@/views/Modals/Customer/CreateNewCustomerModal.vue'
import SenderAddressActionModal from '@/views/Modals/SenderAddress/SenderAddressActionModal.vue'
import ItemTransformer from '@/transformers/ItemTransformer'
import AssignmentProxy from '@/proxies/AssignmentProxy'
import countriesEU from '@/assets/files/countriesEU.json'
import allCities from '@/assets/files/cities.json'
import allCountries from '@/assets/files/countries.json'
import allCurrencies from '@/assets/files/currencies.json'
import terminalAddressInfo from '@/assets/files/terminalAddressInfo.json'
import type { City, Country } from '@/types/Models/Customer'
import type { SelectOptions } from '@/types/Components/SelectOptions'

export default defineComponent({
  name: 'AssignmentForm',
  components: {
    VeeForm,
    Field,
    VInput,
    VSelect,
    Dropdown,
    CustomSelect,
    CountryPicker,
    VInputDatalist,
    PickupCalendar,
    ParcelCharacteristics,
    SenderAddressActionModal,
    CreateNewCustomerModal,
    SourceFileErrorsModal,
    RequestErrorModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const assignment = ref({
      pickup_date: '',
      pickup_instructions: '',
      file: {
        name: '',
        data: '',
      },
      customer_id: '',
      sender_address_id: '',
      service_level: 'priority',
      description: '',
      message: '',
      customer_reference: '',
      distribution_channel: PACKAGE,
      subAccountId: '',
      assignment_type: SINGLE,
      item: {
        is_b2b_shipment: false,
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        country_code: '',
        city: '',
        postal_code: '',
        address: '',
        secondary_address: '',
        delivery_type: '',
        weight: '',
        height: '',
        length: '',
        width: '',
        reference: '',
        estimated_cost: '',
        invoiceType: OTHER,
        currency: 'SEK',
        invoice: {
          products: [
            {
              quantity: 1,
              countryCode: 'SE',
              description: '',
              commodityCode: '',
              unitWeight: 0,
              unitCost: 0,
            },
          ],
        },
        parcel_characteristics: [
          {
            weight: '',
            height: '',
            length: '',
            width: '',
            reference: '',
            description: '',
            commodity_code: '',
            estimated_cost: '',
          },
        ],
        products: [
          {
            quantity: 1,
            countryCode: 'SE',
            description: '',
            commodityCode: '',
            unitWeight: 0,
            unitCost: 0,
            weight: '100',
            width: '15',
            height: '15',
            length: '15',
            estimatedCost: '',
          },
        ],
      },
      report: {},
    })
    const modalAddressId = ref('')
    const currencies: Ref<SelectOptions[]> = ref(
      allCurrencies.map((currency) => ({
        text: `${currency.icon} ${currency.value}`,
        value: currency.value,
      })),
    )

    const customerCanHasCustomerNumbers = ref(false)
    const errorsList = reactive({
      customer_id: '',
      file: '',
      sender_address_id: '',
      pickup_date: false,
    })
    const parcelWarningText = ref('')
    const parcelLimitation = ref(false)
    const isFileUpdated = ref(false)
    const clientFilter = (option: any, label: any, search: string) => {
      const temp = search.toLowerCase()
      if (option.company !== null) {
        return option.label.toLowerCase().indexOf(temp) > -1 || option?.company.toLowerCase().indexOf(temp) > -1
      }
      return label
    }
    const proxy = new AssignmentProxy()

    const parcelLimits = computed(() => {
      let limits = {
        weight: { min: 1, max: 2000 },
        width: { min: 1, max: 60 },
        length: { min: 1, max: 60 },
        height: { min: 1, max: 60 },
      }

      if (assignment.value.distribution_channel === LETTER) {
        limits = {
          weight: { min: 1, max: 2000 },
          width: { min: 9, max: 60 },
          length: { min: 14, max: 60 },
          height: { min: 0.1, max: 3 },
        }
      } else if (assignment.value.distribution_channel === PACKAGE) {
        if (assignment.value.item.country_code === 'SE') {
          limits.weight.max = 5000
        }
      } else {
        // for DHL Freight
        limits = assignment.value.item.is_b2b_shipment
          ? {
              weight: { min: 100, max: 35000 },
              width: { min: 11, max: 50 },
              length: { min: 15, max: 150 },
              height: { min: 3.5, max: 50 },
            }
          : {
              weight: { min: 100, max: 20000 },
              width: { min: 11, max: 131.5 },
              length: { min: 15, max: 150 },
              height: { min: 3.5, max: 139 },
            }
      }

      return limits
    })
    const defaultParcelData = [
      {
        weight: '',
        height: '',
        length: '',
        width: '',
        reference: '',
        description: '',
        commodity_code: '',
        estimated_cost: '',
      },
    ]
    const totalInvoiceCosts = computed(
      () => assignment.value.item.products?.reduce((acc: any, cv: any) => acc + cv.quantity * cv.estimatedCost, 0) || 0,
    )
    const categories: ComputedRef<SelectOptions[]> = computed(getProformaInvoiceTypes)

    const userId = computed(() => store.getters['account/userId'])
    const isStaff = computed(() => store.getters['account/isStaff'])
    const customers = computed(() => store.getters['customers/all'])
    const senderAddresses = computed(() => store.getters['customers/senderAddresses/get'])
    const assignmentId = computed(() => route.params.id)
    const selectedCurrency = computed(
      () => allCurrencies.find((currency) => currency.value === assignment.value.item?.currency) ?? allCurrencies[0],
    )
    const isSwedenPicked = computed(() => assignment.value.item.country_code === 'SE')
    const showDeliveryTime = computed(() => isSwedenPicked.value && assignment.value.assignment_type === 'single')
    const isParcelNonEU = computed(
      () => assignment.value.item.country_code && !countriesEU.includes(assignment.value.item.country_code),
    )

    const rules = computed(() => ({
      deliveryType: {
        required: true,
      },
      email: {
        email: true,
        required: true,
      },
      lettersField: {
        alpha_spaces: true,
        required: true,
        min: 3,
      },
      description: {
        required: assignment.value.item.country_code !== 'SE' && assignment.value.assignment_type === SINGLE,
        min: 3,
        max: 30,
      },
      reference: {
        required: false,
        min: 3,
        max: 34,
      },
      message: {
        required: false,
        min: 3,
      },
      customer_id: {
        required: true,
        min: 3,
      },
      file: {
        required: true,
        min: 1,
      },
      emailRule: {
        emailPhoneRequired:
          (isSwedenPicked.value && assignment.value.item.phone_number.length === 0) || !isSwedenPicked.value,
        email: true,
      },
      phoneRule: {
        simplePhone: assignment.value.item.country_code,
        emailPhoneRequired: (isSwedenPicked.value && assignment.value.item.email.length === 0) || !isSwedenPicked.value,
      },
    }))

    const defaultProductData = {
      quantity: 1,
      countryCode: 'SE',
      description: '',
      commodityCode: '',
      unitWeight: 0,
      unitCost: 0,
      weight: '100',
      width: '15',
      height: '15',
      length: '15',
      estimatedCost: '',
    }

    const maxPickupPeriod = computed(() => {
      // DHL Express max pickup date period is 10 days after current day
      // DHL Freight max pickup date period is 100 days after current day
      return !isSwedenPicked.value ? 10 : 100
    })

    const cities: ComputedRef<SelectOptions[]> = computed(() => {
      const result: City[] = [
        {
          value: null,
          text: 'Choose city *',
        },
      ]
      return result.concat(
        allCities.map((cityName: string) => ({
          value: cityName,
          text: cityName,
        })),
      )
    })

    const countriesCode: ComputedRef<SelectOptions[]> = computed(() => {
      const countries: Country[] = allCountries.map((item: Country) => ({
        text: item.value,
        value: item.value,
      }))
      return [{ text: 'Choose country *', value: null }, ...countries]
    })

    const distributionChannels = [
      {
        code: PACKAGE,
        name: 'Packet',
        description: 'Mailbox delivery (or to the door step) – Up to 5 kg.',
        class: isStaff.value ? 'col-12 col-md-3' : 'col-12 col-md-6',
        beta: false,
        staffOnly: false,
        icon: 'fa fa-book',
      },
      {
        code: PARCEL,
        name: 'Parcel',
        description: 'Choose between delivery to Service point (B2C) or by Carrier (B2B).',
        class: isStaff.value ? 'col-12 col-md-4' : 'col-12 col-md-6',
        beta: false,
        staffOnly: false,
        icon: 'fa fa-cube',
      },
      {
        code: BOX,
        name: 'Parcel locker',
        description: 'Parcel lockers located outside or indoors.',
        class: 'col-12 col-md-3',
        beta: true,
        staffOnly: true,
        icon: 'fa fa-lock',
      },
      {
        code: LETTER,
        name: 'Letter',
        description: 'Up to 2 kg.',
        class: 'col-12 col-md-2',
        beta: true,
        staffOnly: true,
        icon: 'fa fa-envelope',
      },
    ]

    const fetchSenderAddresses = () => {
      if (isStaff.value) {
        store.dispatch('customers/fetchAll')
      } else {
        store.dispatch('customers/senderAddresses/fetch', userId.value).then(() => {
          assignment.value.sender_address_id = senderAddresses.value.find((el: any) => el.isDefault)?.id || ''
        })
      }
    }

    const companyNameChange = (value: string) => {
      const companyName = value ? value.trim() : ''
      assignment.value.item.is_b2b_shipment = companyName !== '' && companyName.length > 2
    }

    const validateWeight = () => {
      const totalWeight = assignment.value.item.parcel_characteristics.reduce((sum, parcel) => sum + +parcel.weight, 0)
      let maxWeight = isSwedenPicked.value && assignment.value.distribution_channel === PACKAGE ? 5000 : 2000

      if (assignment.value.distribution_channel === BOX) {
        maxWeight = isSwedenPicked.value ? 20000 : 35000
      }

      if (assignment.value.distribution_channel === PARCEL) {
        maxWeight = assignment.value.item.is_b2b_shipment ? 150000 : 20000
      }

      if (totalWeight > maxWeight) {
        if (assignment.value.distribution_channel === PACKAGE) {
          parcelWarningText.value =
            'Your package weight exceed the limit for package delivery! Switching to Parcel distribution'
          assignment.value.distribution_channel = PARCEL
        } else {
          parcelLimitation.value = true
          parcelWarningText.value =
            'Your parcel(s) total weight exceed the limit for parcel delivery! ' +
            'Please edit the field(s) or create another assignment'
        }
      } else if (assignment.value.distribution_channel === PARCEL && assignment.value.item.is_b2b_shipment) {
        // multiple parcels
        for (let i = 0; i < assignment.value.item.parcel_characteristics.length; i += 1) {
          const parcel = assignment.value.item.parcel_characteristics[i]

          if (+parcel.weight > parcelLimits.value.weight.max) {
            parcelLimitation.value = true
            parcelWarningText.value =
              'Your parcel weight exceed the limit for parcel delivery! ' +
              "Please edit the field or add another parcel clicking on 'Add another package'"
            break
          }
        }
      }
    }
    const validateDimensions = () => {
      if (parcelLimitation.value) {
        return
      }
      for (let i = 0; i < assignment.value.item.parcel_characteristics.length; i += 1) {
        const parcel = assignment.value.item.parcel_characteristics[i]

        // for DHL Freight Circumference value
        if (assignment.value.distribution_channel === PARCEL) {
          if (!assignment.value.item.is_b2b_shipment) {
            const range = 2 * (+parcel.width + +parcel.height)

            if (+parcel.length + range > 300) {
              parcelWarningText.value =
                "Circumference value can't be greater than 300cm for domestic " + 'single parcel distribution'
              break
            }
          }
        } else if (+parcel.width + +parcel.length + +parcel.height > 90) {
          parcelWarningText.value =
            'All dimensions combined cannot be greater than 90 centimeters for package' +
            ' sending! Switching to Parcel distribution'
          assignment.value.distribution_channel = PARCEL
          break
        }
      }
    }

    const changeParcels = (parcelsData: any) => {
      assignment.value.item.parcel_characteristics = [...parcelsData]

      parcelLimitation.value = false
      parcelWarningText.value = ''

      validateWeight()
      validateDimensions()
    }

    const setPickUpDate = (date: any) => {
      assignment.value.pickup_date = date
    }

    const fileSelected = (file: any) => {
      ;[assignment.value.file] = file.target.files
      isFileUpdated.value = true
    }

    const addNewPackage = () => {
      assignment.value.item.products.push({ ...defaultProductData })
    }
    const removePackage = (id: number) => {
      if (assignment.value.item.products.length === 1) {
        assignment.value.item.products = [defaultProductData]
      } else {
        assignment.value.item.products.splice(id, 1)
      }
    }

    const openSenderAddressEditModal = (addressId: string) => {
      modalAddressId.value = addressId
      openModal(SENDER_ADDRESS_ACTION, {
        addressId,
        customerId: userId.value,
        canHasCustomerNumbers: customerCanHasCustomerNumbers.value,
      })
    }

    const submit = () => {
      let promise = null
      if (assignment.value.assignment_type !== SINGLE) {
        errorsList.customer_id = isStaff.value && assignment.value.customer_id === '' ? 'Select client, please.' : ''
      }

      if (errorsList.customer_id === '') {
        store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
        errorsList.file = ''
        const data: any = { ...toRaw(assignment.value) }
        if (!data?.sender_address_id) {
          delete data.sender_address_id
        }
        if (!isStaff.value) {
          delete data.customer_id
        }

        // transform item
        data.item = ItemTransformer.create(data.item)

        if (assignment.value.assignment_type === SINGLE) {
          delete data.file
          if (assignment.value.distribution_channel === PARCEL && data.item) {
            // move this hack to backend
            data.item.delivery_type = data.item.company_name ? ADDRESS_DELIVERY : SERVICE_POINT_DELIVERY
          }

          data.optimize = true
          data.create_report = false
          data.create_labels = false

          promise = proxy.create(data)
        } else {
          delete data.item
          const formData = new FormData()
          buildFormData(formData, data)
          if (!isFileUpdated.value) {
            formData.delete('file')
          }
          promise = proxy.create(formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        }

        promise
          .then((val: any) => {
            if (assignment.value.assignment_type !== SINGLE) {
              router.push({
                name: isStaff.value ? STAFF_ASSIGNMENTS_LIST : CUSTOMER_ASSIGNMENTS_LIST,
              })
              return
            }
            router.push({
              name: isStaff.value ? STAFF_ASSIGNMENT_SUMMARY : CUSTOMER_ASSIGNMENT_SUMMARY,
              params: { id: val.data.id },
            })
          })
          .catch((e: any) => {
            const fileErrors = e?.errors?.file || []
            if (fileErrors.length > 0) {
              openModal(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS, { fileErrors })
            } else if (e?.errors) {
              openModal(REQUEST_ERROR, { errors: e.errors })
            }
          })
          .finally(() => {
            store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          })
      }
    }

    const fillTerminalAddress = () => {
      assignment.value.item.company_name = terminalAddressInfo.company_name
      assignment.value.item.first_name = terminalAddressInfo.first_name
      assignment.value.item.email = terminalAddressInfo.email
      assignment.value.item.phone_number = terminalAddressInfo.phone_number
      assignment.value.item.country_code = terminalAddressInfo.country_code
      assignment.value.item.city = terminalAddressInfo.city
      assignment.value.item.postal_code = terminalAddressInfo.postal_code
      assignment.value.item.address = terminalAddressInfo.address
    }

    const chooseClient = (client: string) => {
      assignment.value.customer_id = client
      errorsList.customer_id = ''
    }

    const selectSenderAddress = (value: string) => {
      assignment.value.sender_address_id = value
      errorsList.sender_address_id = ''
    }

    const onCountryUpdate = (value: string) => {
      assignment.value.item.country_code = value
    }

    const onCityUpdate = (value: string) => {
      assignment.value.item.city = value
    }

    watch(
      () => assignment.value.distribution_channel,
      () => {
        switch (assignment.value.distribution_channel) {
          case PARCEL:
            // always send service level priority for parcel assignments
            assignment.value.service_level = 'priority'
            break
          case LETTER:
            // always send service level economy for letter assignments
            assignment.value.service_level = 'economy'
            break
          case PACKAGE:
            if (assignment.value.item.parcel_characteristics.length > 1) {
              changeParcels(defaultParcelData)
            }
            break
        }
      },
    )
    watch(
      () => assignment.value.item.weight,
      (value: any) => {
        if (assignment.value.item.products.length === 1) {
          assignment.value.item.products[0].weight = value
        }
      },
    )
    watch(
      () => assignment.value.customer_id,
      (customerId) => {
        if (!customerId) {
          return
        }
        assignment.value.sender_address_id = ''
        customerCanHasCustomerNumbers.value =
          customers.value.find((el: any) => el.id === customerId)?.canHasCustomerNumbers || false
        store.dispatch('customers/senderAddresses/fetch', customerId).then(() => {
          assignment.value.sender_address_id = senderAddresses.value.find((el: any) => el.isDefault)?.id || ''
        })
      },
    )

    onMounted(() => {
      registerModals(REQUEST_ERROR)
      registerModals(CREATE_NEW_CUSTOMER)
      registerModals(SENDER_ADDRESS_ACTION)
      registerModals(CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS)
      if (assignmentId.value) {
        proxy.find(String(assignmentId.value)).then((val) => {
          assignment.value = Object.assign(
            { ...val.data },
            {
              item: assignment.value.item,
              customer_id: val.data.user.id || assignment.value.customer_id,
              sender_address_id: val.data.senderAddress.id || assignment.value.sender_address_id,
              service_level: val.data.serviceLevel,
              distribution_channel: val.data.distributionChannel,
              file: val.data.sourceFiles[0] || { name: '', data: '' },
            },
          )

          fetchSenderAddresses()
        })
      } else {
        assignment.value.assignment_type =
          router.currentRoute.value.query?.assignmentType === SINGLE ? SINGLE : CONSOLIDATE
        fetchSenderAddresses()
      }
    })

    onUnmounted(() => {
      store.dispatch('customers/senderAddresses/resetSenderAddresses')
    })

    return {
      userId,
      isStaff,
      currencies,
      selectedCurrency,
      countriesCode,
      cities,
      submit,
      openModal,
      numbersOnly,
      onCityUpdate,
      chooseClient,
      clientFilter,
      addNewPackage,
      removePackage,
      onCountryUpdate,
      selectSenderAddress,
      fillTerminalAddress,
      fetchSenderAddresses,
      openSenderAddressEditModal,
      companyNameChange,
      changeParcels,
      setPickUpDate,
      fileSelected,
      rules,
      customers,
      categories,
      errorsList,
      assignment,
      assignmentId,
      parcelLimits,
      parcelWarningText,
      distributionChannels,
      totalInvoiceCosts,
      showDeliveryTime,
      maxPickupPeriod,
      senderAddresses,
      modalAddressId,
      isSwedenPicked,
      isParcelNonEU,
      customerCanHasCustomerNumbers,
      BOX,
      PARCEL,
      LETTER,
      PACKAGE,
      SINGLE,
      CONSOLIDATE,
      ADDRESS_DELIVERY,
      SERVICE_POINT_DELIVERY,
      CREATE_ASSIGNMENT_SOURCE_FILE_ERRORS,
      SENDER_ADDRESS_ACTION,
      CREATE_NEW_CUSTOMER,
      PROFORMA_INVOICE,
      REQUEST_ERROR,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/parts/assignment_form.scss';
</style>
