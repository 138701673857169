<template>
  <div v-if="statusSettings">
    <b-icon icon="circle-fill" :style="style" />
    <span class="pl-2">{{ statusSettings.text }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { getCustomerAssignmentsStatuses, getStaffAssignmentsStatuses } from '@/services/Assignment/AssignmentsStatuses'

export default defineComponent({
  name: 'AssignmentStatus',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()
    const isStaff = computed(() => store.getters['account/isStaff'])
    const assignment = computed(() => store.getters['assignment/get'])
    const assignmentStatuses: any = computed(() => {
      return isStaff.value ? getStaffAssignmentsStatuses() : getCustomerAssignmentsStatuses()
    })

    const statusSettings = computed(() => {
      if (assignment.value.archived) {
        return assignmentStatuses.value.cancelled || false
      }
      return assignmentStatuses.value[props.status] || false
    })

    const style = computed(() => {
      const color = statusSettings.value.color || '#C8C8C8'
      return {
        color: color || 'transparent',
      }
    })

    return {
      statusSettings,
      style,
    }
  },
})
</script>
