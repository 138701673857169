import {
  IN_REVIEW,
  OPTIMISATION_COMPLETE,
  OPTIMISING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
} from '@/const/AssignmentStatuses'
import { BLACK, GREEN, GREEN_SUCCESS, GREY, RED, WHITE, YELLOW } from '@/const/Colors'
import { getDefaultToDo } from '@/services/Assignment/DefaultToDo'
import StaffActions from '@/services/Assignment/Actions/StaffActions'
import DefaultActions from '@/services/Assignment/Actions/DefaultActions'

export default () => {
  const defaultToDo = getDefaultToDo()
  return {
    [IN_REVIEW]: {
      color: RED,
      text: 'Under Review',
      toDo: defaultToDo.EDIT_ASSIGNMENT,
      itemsActions: [
        {
          text: 'Proceed',
          color: GREEN_SUCCESS,
          handler: () => {
            StaffActions.submitOptimize()
          },
        },
      ],
    },
    [OPTIMISING]: {
      color: GREY,
      text: 'Optimising',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
    },
    [OPTIMISATION_COMPLETE]: {
      color: YELLOW,
      text: 'Report Created',
      toDo: defaultToDo.VIEW_REPORT,
      itemsActions: [
        {
          text: 'View report',
          color: YELLOW,
          handler: () => {
            DefaultActions.toReportPage()
          },
        },
        {
          text: 'Proceed',
          color: GREEN_SUCCESS,
          handler: () => {
            StaffActions.submitReportRelease()
          },
        },
      ],
      reportActions: [
        {
          text: 'Deny',
          color: '#757575',
          handler: () => {
            DefaultActions.submitReportDeny()
          },
        },
        {
          text: 'Send report',
          color: YELLOW,
          textColor: BLACK,
          handler: () => {
            StaffActions.submitReportRelease()
          },
        },
        {
          text: 'Send report and accept',
          color: GREEN_SUCCESS,
          textColor: WHITE,
          handler: () => {
            StaffActions.submitReportReleaseAndAccept()
          },
        },
      ],
    },

    [REPORT_RELEASED]: {
      color: GREY,
      text: 'Report Sent',
      toDo: defaultToDo.VIEW_REPORT,
      itemsActions: [
        {
          text: 'View report',
          color: YELLOW,
          handler: () => {
            DefaultActions.toReportPage()
          },
        },
        {
          text: 'Accept report',
          color: GREEN_SUCCESS,
          textColor: WHITE,
          handler: () => {
            StaffActions.submitReportAccept()
          },
        },
      ],
      reportActions: [
        {
          text: 'View assigment',
          color: GREEN,
          handler: () => {
            DefaultActions.toAssignmentSinglePage()
          },
        },
      ],
    },
    [REPORT_ACCEPTED]: {
      color: GREEN,
      text: 'Report accepted',
      toDo: defaultToDo.VIEW_REPORT,
      itemsActions: [
        {
          text: 'Create Labels',
          color: GREEN_SUCCESS,
          handler: () => {
            StaffActions.createLabels()
          },
        },
      ],
      reportActions: [
        {
          text: 'View assigment',
          color: GREY,
          handler: () => {
            DefaultActions.toAssignmentSinglePage()
          },
        },
      ],
    },
    [REPORT_DENIED]: {
      color: RED,
      text: 'Report Denied',
      toDo: { ...defaultToDo.EDIT_ASSIGNMENT, color: RED },
      itemsActions: [
        {
          icon: 'ArchiveOutline',
          variant: 'link',
          text: 'Close assigment',
          style: {},
          class: ['gray-color'],
          handler: () => {
            StaffActions.submitCloseAssignment()
          },
        },
        {
          text: 'Proceed',
          color: GREEN_SUCCESS,
          handler: () => {
            StaffActions.submitOptimize()
          },
        },
      ],
      reportActions: [
        {
          text: 'View assigment',
          color: GREY,
          handler: () => {
            DefaultActions.toAssignmentSinglePage()
          },
        },
      ],
    },
  }
}
