<template>
  <div class="sender-address-prices-header">
    <div class="row w-100">
      <div class="col-6 my-auto">
        <div class="assignment-number mr-4 d-flex align-items-center">
          <router-link :to="{ name: customersArea }" class="d-flex align-items-center mr-4">
            <a class="back-to position-absolute">
              <mdicon name="ChevronLeft" />
            </a>
          </router-link>
          <span class="ml-1 sender-address-title">
            Prices for sender address of <b>{{ customer.fullName }}</b> customer
          </span>
          <div class="d-flex flex-column badges">
            <b-badge v-if="selectedSenderAddress.hasCustomPrices" variant="success" class="ml-3">
              Sender prices
            </b-badge>
            <b-badge
              v-if="selectedSenderAddress.hasCustomPriorities"
              variant="warning"
              class="ml-3"
              :class="{ 'mt-1': selectedSenderAddress.hasCustomPrices }"
            >
              Sender carriers
            </b-badge>
          </div>
        </div>
      </div>
      <div class="col-6 pr-0">
        <div class="d-flex justify-content-end">
          <div class="col-12 col-lg-9 col-xl-auto ml-4 pr-0">
            <div class="d-flex align-items-center mb-0">
              <label class="mt-0 mb-0 mr-3 font-weight-bold"> Sender Address: </label>
              <CustomSelect
                :selected="selectedSenderAddress.id"
                :options="senderAddresses"
                :selectable="(option: Address) => option"
                placeholder="Choose sender address"
                @selected="changeSenderAddress"
              >
                <template #custom_option="{ option }">
                  <div :class="'item-' + option.id">
                    <span class="status" />
                    <span class="name">{{ option.fullName }}</span>
                    {{ option.countryCode }}, {{ option.city }},
                    {{ option.address_line }}
                    <b-badge v-if="option.customNumbers.length" variant="warning"> Customer Numbers </b-badge>
                    <b-badge v-if="option.isDefault" class="ml-1" variant="success"> Default Address </b-badge>
                    <b-badge v-if="option.hasCustomPrices" variant="success" class="ml-1"> Sender prices </b-badge>
                  </div>
                </template>
              </CustomSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, type Ref } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { STAFF_CUSTOMERS_LIST } from '@/types/router'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import CustomSelect from '@/views/Components/Elements/Form/CustomSelect.vue'
import type { Address, Customer } from '@/types/Models/Customer'
import type { BasePrice } from '@/types/Models/BasePrice'

export default defineComponent({
  name: 'SenderAddressPricesActionMenu',
  components: { CustomSelect },
  setup() {
    const store = useStore()
    const route = useRoute()
    const customer: Ref<Customer> = ref({} as Customer)
    const selectedSenderAddress: Ref<Address> = ref({} as Address)
    const defaultPrices: Ref<BasePrice[]> = ref([])

    const senderAddresses = computed(() => store.getters['customers/senderAddresses/get'])
    const customers = computed(() => store.getters['customers/all'])
    const customersArea = computed(() => STAFF_CUSTOMERS_LIST)

    const changeSenderAddress = (val: string) => {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      selectedSenderAddress.value = senderAddresses.value.find((el: any) => el.id === val)
      store.dispatch('customers/senderAddresses/selectSenderAddress', {
        customerId: customer.value.id,
        addressId: selectedSenderAddress.value.id,
      })
      getPrices(val)
    }

    const getPrices = (id: string) => {
      store.dispatch('prices/fetchDefault', id).then((res: any) => {
        defaultPrices.value = res
        store
          .dispatch('prices/fetch', id)
          .then((result: any) => {
            if (result.length === 0) {
              store.commit('prices/SET_PRICES', defaultPrices.value)
            }
            store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          })
          .catch(() => {
            store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          })
      })
    }

    watch(senderAddresses, (val) => {
      if (val.length) {
        selectedSenderAddress.value = { ...val[0] }
        store.dispatch('customers/senderAddresses/selectSenderAddress', {
          customerId: route.params.id,
          addressId: selectedSenderAddress.value.id,
        })
        getPrices(selectedSenderAddress.value.id)
      }
    })

    watch(customers, (val) => {
      if (val.length) {
        customer.value = customers.value.find((el: Customer) => el.id === route.params.id)
      }
    })

    onBeforeRouteLeave((to, from, next) => {
      if (store.getters['prices/changedPrices'].value.length > 0) {
        openModal('confirmWrap', {
          title: 'There are unsaved changes',
          message: 'Are you sure you want to cancel?',
          onConfirm: () => {
            store.dispatch('prices/reset')
            next(true)
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    })

    return {
      customer,
      selectedSenderAddress,
      senderAddresses,
      customersArea,
      changeSenderAddress,
      getPrices,
    }
  },
})
</script>

<style lang="scss" scoped>
.sender-address-prices-header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  background: white;
  padding: 16px 13px;
  margin-bottom: -25px;
}
.sender-address-title {
  font-size: 15px;
}
.badges {
  font-size: 14px;
}
:deep(.vs__actions) {
  display: block;
  & > :not(.vs__open-indicator) {
    display: none;
  }
}
.custom-selectbox {
  min-width: 450px;
}
</style>
