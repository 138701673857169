<template>
  <div>
    <div class="w-100 d-flex justify-content-between mb-3 filters">
      <TableTitle icon="archive-outline" title="Pickups" />
      <Search @search-triggered="updateSearch" />
    </div>
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data>
        <div style="text-align: center">No data available</div>
      </template>
      <template #item-distributionChannel="{ distributionChannel }">
        <span class="text-center">
          <b>{{ capitalize(distributionChannel) }}</b>
        </span>
      </template>
      <template #item-carrier="{ carrier }">
        <CarrierRowWithIcon :carrier="carrier" />
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
  </div>
</template>

<script lang="ts">
import { BOX, PACKAGE } from '@/const/DistributionChannels'
import { useRoute } from 'vue-router'
import { capitalize } from '@/services/Helpers'
import { type Ref, defineComponent, ref, watch, onMounted } from 'vue'
import Search from '@/views/Components/PageParts/Search.vue'
import TableTitle from '@/views/Components/Elements/Table/TableTitle.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import CarrierRowWithIcon from '@/views/Components/Assignment/CarrierRowWithIcon.vue'
import PickupTransformer from '@/transformers/PickupTransformer'
import PickupProxy from '@/proxies/PickupProxy'
import EasyDataTable from 'vue3-easy-data-table'

export default defineComponent({
  name: 'PickupsTable',
  components: {
    CarrierRowWithIcon,
    TablePagination,
    TableTitle,
    Search,
    EasyDataTable,
  },
  setup() {
    const route = useRoute()

    const dataTable = ref(null)
    const items: Ref<any[]> = ref([])
    const error = ref(false)
    const search = ref('')
    const loading = ref(false)
    const serverItemsLength = ref(0)
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    })
    const headers = [
      { text: 'ID', value: 'id' },
      { text: 'Status', value: 'status', sortable: true },
      {
        text: 'Distribution type',
        value: 'distributionChannel',
        sortable: true,
      },
      { text: 'Carrier', value: 'carrier', sortable: true },
      { text: 'Created at', value: 'createdAt', sortable: true },
      { text: 'Shipment number', value: 'shipmentNumber' },
      { text: 'Label', value: 'labelUrl' },
    ]

    const loadFromServer = async () => {
      loading.value = true
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        ascending: 1,
        byColumn: 0,
        query: search.value,
      }
      new PickupProxy({ ...params, ...route.query })
        .all()
        .then((response) => {
          items.value = PickupTransformer.fetchCollection(response.data || [])
          serverItemsLength.value = response.count || 0
          loading.value = false
          return response
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
          error.value = true
        })
    }

    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    const updateSearch = (value: string) => {
      search.value = value
    }

    onMounted(loadFromServer)

    watch(serverOptions, loadFromServer, { deep: true })
    watch(search, loadFromServer, { deep: true })

    return {
      dataTable,
      items,
      error,
      loading,
      headers,
      serverItemsLength,
      serverOptions,
      updateSearch,
      updateServerOptions,
      capitalize,
      PACKAGE,
      BOX,
    }
  },
})
</script>

<style lang="scss" scoped>
.carrier-icon-wrap {
  white-space: nowrap;
}
.carrier-cell {
  padding: 0;
}
</style>
