<template>
  <div>
    <div v-if="!fatalErrorsCount">
      <div>
        <div v-if="status === OPEN">
          <Process />
        </div>
        <div v-if="status === VALIDATION_COMPLETE">
          <CreateReport />
        </div>
        <div v-else-if="status === REPORT_RELEASED">
          <ViewAdjacent :is-report-origin="isReportOrigin" />
          <AcceptReport />
          <RejectReport />
        </div>
        <div v-else-if="status === OPTIMISATION_COMPLETE">
          <ViewAdjacent :is-report-origin="isReportOrigin" />
          <AcceptReport />
          <RejectReport />
        </div>
        <div v-else-if="status === REPORT_ACCEPTED">
          <ViewAdjacent :is-report-origin="isReportOrigin" />
          <CreateLabels />
        </div>
        <div v-else-if="status === REPORT_DENIED">
          <ViewAdjacent :is-report-origin="isReportOrigin" />
          <Complete />
        </div>
        <div v-else-if="status === LABELS_RELEASED">
          <ViewAdjacent :is-report-origin="isReportOrigin" />
          <DownloadLabels />
          <DownloadDocuments />
          <Complete />
        </div>
        <div v-else-if="status === COMPLETE">
          <ViewAdjacent :is-report-origin="isReportOrigin" />
        </div>
      </div>
    </div>
    <InfoAlert v-else class="ml-auto danger"> Please fix all fatal errors to proceed </InfoAlert>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import InfoAlert from '@/views/Components/PageParts/InfoAlert.vue'
import RejectReport from '@/views/Components/Assignment/Actions/Buttons/RejectReport.vue'
import CreateLabels from '@/views/Components/Assignment/Actions/Buttons/CreateLabels.vue'
import CreateReport from '@/views/Components/Assignment/Actions/Buttons/CreateReport.vue'
import AcceptReport from '@/views/Components/Assignment/Actions/Buttons/AcceptReport.vue'
import DownloadDocuments from '@/views/Components/Assignment/Actions/Buttons/DownloadDocuments.vue'
import ViewAdjacent from '@/views/Components/Assignment/Actions/Buttons/ViewAdjacent.vue'
import DownloadLabels from '@/views/Components/Assignment/Actions/Buttons/DownloadLabels.vue'
import Process from '@/views/Components/Assignment/Actions/Buttons/Process.vue'
import Complete from '@/views/Components/Assignment/Actions/Buttons/Complete.vue'
import {
  COMPLETE,
  LABELS_RELEASED,
  OPEN,
  OPTIMISATION_COMPLETE,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
  VALIDATION_COMPLETE,
} from '@/const/AssignmentStatuses'

export default defineComponent({
  name: 'AssignmentActions',
  components: {
    Complete,
    Process,
    DownloadLabels,
    ViewAdjacent,
    DownloadDocuments,
    AcceptReport,
    CreateReport,
    CreateLabels,
    RejectReport,
    InfoAlert,
  },
  props: {
    origin: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const fatalErrorsCount = computed(() => store.getters['assignment/getFatalErrorsCount'])
    const status = computed(() => store.getters['assignment/getStatus'])

    return {
      status,
      fatalErrorsCount,
      isReportOrigin: props.origin === 'report',
      VALIDATION_COMPLETE,
      REPORT_RELEASED,
      OPTIMISATION_COMPLETE,
      REPORT_ACCEPTED,
      REPORT_DENIED,
      LABELS_RELEASED,
      COMPLETE,
      OPEN,
    }
  },
})
</script>
