<template>
  <div class="d-flex">
    <b-icon icon="circle-fill" scale="1.3" :style="circleStyle" />
    <div class="pl-2 ptop">
      {{ statusName }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { StatusColor } from '@/services/Status/StatusColor'
import { StatusName } from '@/services/Status/StatusName'
import type { ListAssignment } from '@/types/Models/Assignment'
import BIcon from '@/views/Components/Elements/Icons/BIcon.vue'

export default defineComponent({
  name: 'StatusCell',
  components: { BIcon },
  props: {
    assignment: {
      type: Object as () => ListAssignment,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const accountType = computed(() => store.getters['account/accountType'])

    const statusName = computed(() => StatusName[props.assignment.type][accountType.value][props.assignment.status])
    const statusColor = computed(() => StatusColor[accountType.value][props.assignment.status])

    const circleStyle = computed(() => {
      return {
        color: statusColor.value,
      }
    })

    return {
      statusName,
      circleStyle,
    }
  },
})
</script>

<style scoped>
.ptop {
  padding: 2px 0 0 8px;
}
</style>
