import { computed, type ComputedRef, ref } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { none } from '@/services/Helpers'
import { notify } from '@kyvg/vue3-notification'
import {
  ARCHIVE_ASSIGNMENT,
  DOWNLOAD_DOCUMENTS,
  DOWNLOAD_LABELS,
  DOWNLOAD_REPORT,
  DOWNLOAD_SOURCE,
  PROCESS_ASSIGNMENT,
  UNARCHIVE_ASSIGNMENT,
  VIEW_ASSIGNMENT,
  VIEW_REPORT,
  VIEW_SHIPMENTS,
} from '@/const/AssignmentActions'
import { CONSOLIDATE, SINGLE } from '@/const/AssignmentTypes'
import {
  COMPLETE,
  LABELS_CREATED,
  LABELS_CREATING,
  LABELS_RELEASED,
  OPEN,
  OPTIMISATION_COMPLETE,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
} from '@/const/AssignmentStatuses'
import {
  CUSTOMER_ASSIGNMENT_SUMMARY,
  CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
  CUSTOMER_ASSIGNMENTS_SINGLE,
  CUSTOMER_SHIPMENTS,
  STAFF_ASSIGNMENT_SUMMARY,
  STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT,
  STAFF_ASSIGNMENTS_SINGLE,
  STAFF_SHIPMENTS,
} from '@/types/router'
import type { ListAssignment } from '@/types/Models/Assignment'
import type { Action } from '@/types/Components/Actions'
import { useDownloadLabelsAction } from '@/composables/labels/use-download-labels-action'

export const ActionsConfig = [
  {
    handle: VIEW_ASSIGNMENT,
    icon: 'fa-file',
    name: 'View assignment',
    types: [SINGLE, CONSOLIDATE],
    order: 20,
  },
  {
    handle: PROCESS_ASSIGNMENT,
    icon: 'fa-arrow-right',
    name: 'Finish Buffering',
    types: [SINGLE, CONSOLIDATE],
    statuses: () => [OPEN],
    order: 25,
  },
  {
    handle: VIEW_REPORT,
    icon: 'fa-file',
    name: 'View report',
    types: [CONSOLIDATE],
    extraCheck: (assignment: ListAssignment) => !assignment.hidePrice,
    statuses: (_: ListAssignment, isStaff: boolean) => {
      if (isStaff) {
        return [
          OPTIMISATION_COMPLETE,
          REPORT_RELEASED,
          REPORT_ACCEPTED,
          REPORT_DENIED,
          LABELS_CREATING,
          LABELS_CREATED,
          LABELS_RELEASED,
          COMPLETE,
        ]
      }

      return [
        REPORT_RELEASED,
        REPORT_ACCEPTED,
        REPORT_DENIED,
        LABELS_CREATING,
        LABELS_CREATED,
        LABELS_RELEASED,
        COMPLETE,
      ]
    },
    order: 30,
  },
  {
    handle: DOWNLOAD_SOURCE,
    icon: 'fa-download',
    name: 'Download source',
    types: [CONSOLIDATE],
    order: 50,
  },
  {
    handle: DOWNLOAD_REPORT,
    icon: 'fa-download',
    name: 'Download report',
    types: [CONSOLIDATE],
    extraCheck: (assignment: ListAssignment) => !assignment.hidePrice,
    statuses: (_: ListAssignment, isStaff: boolean) => {
      if (isStaff) {
        return [
          OPTIMISATION_COMPLETE,
          REPORT_RELEASED,
          REPORT_ACCEPTED,
          REPORT_DENIED,
          LABELS_CREATING,
          LABELS_CREATED,
          LABELS_RELEASED,
          COMPLETE,
        ]
      }

      return [
        REPORT_RELEASED,
        REPORT_ACCEPTED,
        REPORT_DENIED,
        LABELS_CREATING,
        LABELS_CREATED,
        LABELS_RELEASED,
        COMPLETE,
      ]
    },
    order: 40,
  },
  {
    handle: DOWNLOAD_LABELS,
    icon: 'fa-download',
    name: (assignment: ListAssignment) => (assignment.type === CONSOLIDATE ? 'Download labels' : 'Download label'),
    types: [CONSOLIDATE, SINGLE],
    statuses: (_: ListAssignment, isStaff: boolean) => {
      if (isStaff) {
        return [LABELS_CREATED, LABELS_RELEASED]
      }

      return [LABELS_RELEASED]
    },
    order: 10,
  },
  {
    handle: DOWNLOAD_DOCUMENTS,
    icon: 'fa-download',
    name: 'Download documents',
    types: [CONSOLIDATE, SINGLE],
    extraCheck: (assignment: ListAssignment) => !!assignment.documentsUrl,
    statuses: (_: ListAssignment, isStaff: boolean) => {
      if (isStaff) {
        return [LABELS_CREATED, LABELS_RELEASED]
      }

      return [LABELS_RELEASED]
    },
    order: 15,
  },
  {
    handle: VIEW_SHIPMENTS,
    icon: 'fa-truck',
    name: (assignment: ListAssignment) => (assignment.type === CONSOLIDATE ? 'View shipments' : 'View shipment'),
    statuses: (_: ListAssignment, isStaff: boolean) => {
      if (isStaff) {
        return [LABELS_CREATED, LABELS_RELEASED, COMPLETE]
      }

      return [LABELS_RELEASED, COMPLETE]
    },
    order: 60,
  },
  {
    handle: ARCHIVE_ASSIGNMENT,
    icon: 'fa-eye-slash',
    name: 'Archive assignment',
    extraCheck: (assignment: ListAssignment) => !assignment.archived,
    order: 70,
  },
  {
    handle: UNARCHIVE_ASSIGNMENT,
    icon: 'fa-eye',
    name: 'Unarchive assignment',
    extraCheck: (assignment: ListAssignment) => assignment.archived,
    order: 80,
  },
]

export const prepareAction = (assignment: ListAssignment, isStaff: boolean) => (action: Action) => {
  let { statuses, name, extraCheck } = action

  if (statuses && typeof statuses === 'function') {
    statuses = statuses(assignment, isStaff)
  }

  if (name && typeof name === 'function') {
    name = name(assignment, isStaff)
  }

  if (extraCheck && typeof extraCheck === 'function') {
    extraCheck = extraCheck(assignment, isStaff)
  }

  return {
    ...action,
    statuses,
    name,
    extraCheck,
  }
}

export const filterAction = (assignment: ListAssignment) => (action: Action) => {
  if (action.extraCheck === false) {
    return false
  }

  if (action.types && !action.types.includes(assignment.type)) {
    return false
  }

  if (!action.statuses) {
    return true
  }

  const isStaff = useStore().getters['account/isStaff']
  const statuses: string[] = Array.isArray(action.statuses)
    ? action.statuses
    : action.statuses(assignment, isStaff.value)

  return statuses.includes(assignment.status)
}

export const transformAction = (assignment: ListAssignment, isStaff: boolean) => (action: Action) => {
  let { name } = action
  const { handle, type, order, icon } = action

  if (typeof name === 'function') {
    name = name(assignment, isStaff)
  }

  return {
    handle,
    name,
    type,
    order,
    icon,
  }
}

export const sortAction = (a, b) => a.order - b.order

//////// Handlers ////////

export function useActionHandlers(assignment: ListAssignment, action: Action) {
  const store = useStore()
  const downloadLabelsAction = useDownloadLabelsAction()
  const currentAction = ref(action)

  const isStaff: ComputedRef<boolean> = computed(() => store.getters['account/isStaff'])
  const staffAssignmentPage: ComputedRef<string> = computed(() =>
    assignment.type === CONSOLIDATE ? STAFF_ASSIGNMENTS_SINGLE : STAFF_ASSIGNMENT_SUMMARY,
  )
  const customerAssignmentPage: ComputedRef<string> = computed(() =>
    assignment.type === CONSOLIDATE ? CUSTOMER_ASSIGNMENTS_SINGLE : CUSTOMER_ASSIGNMENT_SUMMARY,
  )

  const detailsRoute: ComputedRef<any> = computed(() => ({
    name: isStaff.value ? staffAssignmentPage.value : customerAssignmentPage.value,
    params: { id: assignment.id },
  }))

  const reportRoute: ComputedRef<any> = computed(() => ({
    name: isStaff.value ? STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT : CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
    params: { id: assignment.id },
  }))

  const shipmentsRoute: ComputedRef<any> = computed(() => ({
    name: isStaff.value ? STAFF_SHIPMENTS : CUSTOMER_SHIPMENTS,
    query: { assignment: assignment.id },
  }))

  const actionRoute: ComputedRef<any> = computed(() => resolveRoute(currentAction.value))
  const actionLink: ComputedRef<string> = computed(() => resolveLink(currentAction.value))

  function actionFn() {
    callAction(currentAction.value)
  }

  function resolveRoute(action: Action) {
    switch (action.handle) {
      case VIEW_ASSIGNMENT:
        return detailsRoute.value
      case VIEW_REPORT:
        return reportRoute.value
      case VIEW_SHIPMENTS:
        return shipmentsRoute.value
      default:
        return null
    }
  }

  function resolveLink(action: Action) {
    switch (action.handle) {
      case DOWNLOAD_SOURCE:
        return assignment.sourceFileUrl
      case DOWNLOAD_REPORT:
        return assignment.optimisationReportUrl
      case DOWNLOAD_DOCUMENTS:
        return assignment.documentsUrl
      default:
        return null
    }
  }

  function callAction(action: Action) {
    switch (action.handle) {
      case DOWNLOAD_LABELS:
        downloadLabels()
        break
      case ARCHIVE_ASSIGNMENT:
        archiveAssignment()
        break
      case UNARCHIVE_ASSIGNMENT:
        unArchiveAssignment()
        break
      case PROCESS_ASSIGNMENT:
        processAssignment()
        break
      default:
        break
    }
  }

  function downloadLabels() {
    downloadLabelsAction([assignment])
  }

  function archiveAssignment() {
    openModal('confirmWrap', {
      title: 'Are you sure you want to archive the assigment?',
      message: 'The assignment will be forwarded to “Archived assignments" filter section',
      onConfirm: () => {
        store.dispatch('assignment/archive', assignment.id).then(() => {
          store.dispatch('assignment/refresh').then(none)
        })
      },
    })
  }

  function unArchiveAssignment() {
    openModal('confirmWrap', {
      title: 'Are you sure you want to restore the assigment?',
      message: 'The assigment will be moved back to active assignments',
      onConfirm: () => {
        store.dispatch('assignment/unarchive', assignment.id).then(() => {
          store.dispatch('assignment/refresh').then(none)
        })
      },
    })
  }

  function processAssignment() {
    openModal('confirmWrap', {
      title: 'Process the assignment',
      message: 'Are you sure you want to process the assignment? This action will start the optimization process.',
      okButtonText: 'Process',
      onConfirm: () => {
        store
          .dispatch('assignment/submitProcess')
          .then(() => {
            store.dispatch('assignment/refresh').then(none)
          })
          .catch((e) => {
            notify({
              group: 'error',
              text: 'The assignment could not be processed. ' + e.message,
            })
          })
      },
    })
  }

  return {
    actionRoute,
    actionLink,
    actionFn,
  }
}
