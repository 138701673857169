<template>
  <div>
    <div class="w-100 d-flex justify-content-between mb-3 filters">
      <TableTitle icon="account-outline" title="Clients" />
      <QueryStringDropdown :options="options" :with-filter="false" :text="activeText" query-param="active" />
      <Search class="ml-2" @search-triggered="updateSearch" />
      <router-link class="btn btn-plus" :to="createCustomerPage">
        <mdicon name="plus" size="24" />
      </router-link>
    </div>
    <easy-data-table
      ref="dataTable"
      v-model:server-options="serverOptions"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #no-data> - </template>
      <template #item="{ column, item }">
        <span v-if="column === 'clientAddress.addressLine'">
          <div class="address-line">
            {{ ucFirst(item.clientAddress.addressLine) }}
          </div>
        </span>
        <span v-else-if="column === 'senderAddress.addressLine'">
          <div class="address-line">
            {{ ucFirst(item.senderAddress.addressLine) }}
          </div>
        </span>
        <span v-else-if="column === 'actions'" class="d-flex">
          <span v-if="activeText === 'Active'" class="btn-table-action" @click="editUser(item)">
            <mdicon name="pencil-outline" class="pointer" size="18" />
          </span>
          <b-dropdown v-if="activeText === 'Active'" no-caret lazy class="icon-button-dropdown">
            <template #button-content>
              <mdicon class="ml-3 pointer btn-table-action" name="dots-vertical" size="18" />
            </template>
            <b-dropdown-item @click="viewAssignments(item.id)">
              <mdicon class="pointer" name="file-document-multiple-outline" /> View assignments
            </b-dropdown-item>
            <b-dropdown-item @click="viewShipments(item.id)">
              <i class="fa fa-truck" /> View shipments
            </b-dropdown-item>
            <b-dropdown-item @click="archiveUser(item.id)">
              <mdicon class="pointer" name="archive-outline" /> Archive customer
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-if="activeText === 'Archived'"
            class="h-32"
            variant="secondary"
            size="sm"
            @click="unarchiveUser(item.id)"
          >
            Unarchive
          </b-button>
        </span>
        <span v-else>
          {{ item[column] }}
        </span>
      </template>
      <template #expand="item">
        <ChildRowUserData :user="item" />
      </template>
    </easy-data-table>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
    <confirm
      id="confirmDelete"
      message="The client will become inactive, he will not be able to log in and have assignments. Are you sure you want to archive client?"
      title="Archive client"
      :on-confirm="onArchive"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type Ref, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ucFirst } from '@/services/Helpers'
import { openModal } from '@/services/Modal'
import { CUSTOMER } from '@/const/AccountTypes'
import { STAFF_ASSIGNMENTS_LIST, STAFF_CUSTOMERS_CREATE, STAFF_SHIPMENTS } from '@/types/router'
import { EDIT_USER_MODAL } from '@/const/ModalTypes'
import QueryStringDropdown from '@/views/Components/PageParts/QueryStringDropdown.vue'
import ChildRowUserData from '@/views/Components/Customer/ChildRowUserData.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import TableTitle from '@/views/Components/Elements/Table/TableTitle.vue'
import Confirm from '@/views/Modals/Confirm.vue'
import Search from '@/views/Components/PageParts/Search.vue'
import UserTransformer from '@/transformers/UserTransformer'
import UserProxy from '@/proxies/UserProxy'
import EasyDataTable from 'vue3-easy-data-table'
import type { User } from '@/types/Models/User'

export default defineComponent({
  name: 'ActiveUsersTable',
  components: {
    QueryStringDropdown,
    ChildRowUserData,
    TablePagination,
    TableTitle,
    Confirm,
    Search,
    EasyDataTable,
  },
  props: {
    refresh: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const activeText: Ref<string> = ref('Active')
    const dataTable: Ref<any> = ref()
    const items: Ref<User[]> = ref([])
    const loading: Ref<boolean> = ref(false)
    const serverItemsLength: Ref<number> = ref(0)
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'createdAt',
      sortType: 'desc',
    })
    const createCustomerPage = { name: STAFF_CUSTOMERS_CREATE }
    const searchText: Ref<string> = ref('')

    const loadFromServer = async () => {
      loading.value = true
      activeText.value = route.query.active === 'archived' ? 'Archived' : 'Active'
      const params = {
        page: serverOptions.value.page,
        limit: serverOptions.value.rowsPerPage,
        ascending: serverOptions.value.sortType === 'asc' ? 1 : 0,
        orderBy: serverOptions.value.sortBy || 'createdAt',
        active: route.query.active === 'archived' ? 0 : 1,
        type: CUSTOMER,
        query: searchText.value,
      }

      new UserProxy(params)
        .all()
        .then((response) => {
          items.value = UserTransformer.fetchCollection(response.data || [])
          serverItemsLength.value = response.count || 0
          loading.value = false
          return response
        })
        .catch(() => {
          serverItemsLength.value = 0
          loading.value = false
          items.value = []
        })
    }

    const updateSearch = (value: string) => {
      searchText.value = value
    }
    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    const headers = [
      { value: 'email', text: 'Email address', sortable: true },
      { value: 'clientNumber', text: 'Client №', sortable: true },
      { value: 'fullName', text: 'Client name', sortable: true },
      {
        value: 'clientAddress.addressLine',
        text: 'Client address',
        sortable: true,
      },
      {
        value: 'senderAddress.addressLine',
        text: 'Sender address',
        sortable: true,
      },
      { value: 'phoneNumber', text: 'Phone number' },
      { value: 'company', text: 'Company' },
      { value: 'actions', text: 'Actions' },
    ]

    const options = [
      { value: 'active', text: 'Active', default: true },
      { value: 'archived', text: 'Archived' },
    ]

    const viewShipments = (id: string) => {
      router.push({
        name: STAFF_SHIPMENTS,
        query: {
          customer: [id],
        },
      })
    }

    const viewAssignments = (id: string) => {
      router.push({
        name: STAFF_ASSIGNMENTS_LIST,
        query: {
          customerId: [id],
        },
      })
    }

    const archiveUser = (id: string) => {
      openModal('confirmDelete', { id })
    }
    const onArchive = (data: any) => {
      store.dispatch('customers/archive', data.id).then(loadFromServer)
    }
    const unarchiveUser = (id: string) => {
      store.dispatch('customers/unarchive', id).then(loadFromServer)
    }
    const editUser = (row: any) => {
      openModal(EDIT_USER_MODAL, { ...toRaw(row) })
    }
    const refresh = computed(() => props.refresh)

    onMounted(loadFromServer)
    watch(serverOptions, loadFromServer)
    watch(searchText, loadFromServer)
    watch(refresh, loadFromServer)

    return {
      createCustomerPage,
      viewShipments,
      viewAssignments,
      unarchiveUser,
      archiveUser,
      onArchive,
      editUser,
      ucFirst,
      options,
      activeText,
      updateSearch,

      //data table options
      dataTable,
      items,
      loading,
      headers,
      serverOptions,
      serverItemsLength,
      updateServerOptions,
    }
  },
})
</script>

<style scoped lang="scss">
a.btn-plus {
  padding: 8px !important;
  margin-left: 12px;
}
.vue3-easy-data-table thead tr th {
  padding: 0 17px !important;
}
.address-line {
  width: 160px;
  white-space: pre-line;
}
</style>
