<template>
  <div class="bg-light">
    <simple-header />
    <div class="page-body">
      <div v-if="!submitted" class="container">
        <div class="wrap-form">
          <div class="title mb-4">Update a password for your account</div>
          <VeeForm @submit="createPassword">
            <Field v-slot="{ field, errors }" name="password" :rules="{ password: true, required: true }">
              <b-form-group class="form-group position-relative">
                <input
                  v-bind="field"
                  v-model="password"
                  type="password"
                  name="password"
                  class="form-control inspectletIgnore"
                  placeholder="Set a password *"
                  autocomplete="off"
                />
                <div class="invalid-feedback">
                  {{ errors.length ? errors.join() : '' }}
                </div>
              </b-form-group>
            </Field>
            <Field
              v-slot="{ field, errors }"
              name="confirmedPassword"
              :rules="{ confirmed: '@password', required: true, password: true }"
            >
              <b-form-group class="form-group position-relative">
                <input
                  v-bind="field"
                  v-model="confirmedPassword"
                  type="password"
                  name="password_confirm"
                  class="form-control inspectletIgnore"
                  placeholder="Confirm password *"
                  autocomplete="off"
                />
                <div class="invalid-feedback">
                  {{ errors.length ? errors.join() : '' }}
                </div>
              </b-form-group>
            </Field>
            <b-button type="submit" class="w-100" :disabled="isDisabled"> Create password </b-button>
          </VeeForm>
        </div>
      </div>
      <div v-else class="container">
        <div class="wrap-form center-text">
          <h5 class="center-text mb-4">Password successfully set</h5>
          <b-button class="assignmentButton mt-2" type="submit" :to="toLogin"> Log In </b-button>
        </div>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, defineComponent } from 'vue'
import { Form as VeeForm, Field } from 'vee-validate'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LOGIN_PAGE } from '@/types/router'
import { useNotification } from '@kyvg/vue3-notification'
import LayoutFooter from '@/views/Layouts/LayoutFooter.vue'
import SimpleHeader from '@/views/Layouts/SimpleHeader.vue'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    VeeForm,
    Field,
    SimpleHeader,
    LayoutFooter,
  },
  setup() {
    const password = ref('')
    const confirmedPassword = ref('')
    const submitted = ref(false)
    const customer = ref({
      email: '',
      token: '',
    })

    const store = useStore()
    const router = useRouter()
    const { notify } = useNotification()

    const isDisabled = computed(() => !password.value || !confirmedPassword.value)
    const toLogin = { name: LOGIN_PAGE }

    const createPassword = async () => {
      const payload = {
        email: customer.value.email,
        token: customer.value.token,
        password: password.value,
      }
      try {
        await store.dispatch('auth/createPassword', payload)
        submitted.value = true
        notify({ group: 'success', text: 'Password successfully set' })
      } catch (e: any) {
        if (e?.email) notify({ group: 'error', text: 'The selected email is invalid.' })
        if (e?.token) notify({ group: 'error', text: 'The selected token is invalid.' })
      }
    }

    onMounted(() => {
      customer.value.email = String(router.currentRoute.value.query.email)
      customer.value.token = String(router.currentRoute.value.query.token)
    })

    return {
      toLogin,
      password,
      confirmedPassword,
      submitted,
      customer,
      isDisabled,
      createPassword,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
}
.bg-light {
  height: 100%;
  display: flex;
  flex-direction: column;
}
footer {
  margin-top: auto;
}
.green-color {
  color: rgb(12, 137, 128);
}
.invalid-feedback {
  display: block;
}
</style>
