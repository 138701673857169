/* ============
 * Pickup Transformer
 * ============
 *
 * The transformer for the Pickup.
 */

import Transformer from '@/transformers/Transformer'

export default class PickupTransformer extends Transformer {
  /**
   * Method used to transform a fetched Pickup.
   */
  static fetch(pickup: any) {
    return {
      id: pickup.id,
      status: pickup.status,
      distributionChannel: pickup.distribution_channel,
      carrier: pickup.carrier_handle,
      createdAt: pickup.pickup_date,
      shipmentNumber: pickup.shipment_number,
      labelUrl: pickup.label_url,
    }
  }
}
