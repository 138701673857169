<template>
  <QueryStringDropdown :options="options" :with-filter="false" :query-param="queryParam" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PACKAGE, PARCEL } from '@/const/DistributionChannels'
import { DISTRIBUTION_CHANNEL } from '@/const/AssignmentListQueryFilters'
import QueryStringDropdown from '@/views/Components/PageParts/QueryStringDropdown.vue'

export default defineComponent({
  name: 'DistributionChannelFilter',
  components: { QueryStringDropdown },
  setup() {
    const queryParam = DISTRIBUTION_CHANNEL
    const options = [
      { value: null, text: 'Distribution channel' },
      { value: PACKAGE, text: 'Packet' },
      { value: PARCEL, text: 'Parcel' },
    ]

    return {
      queryParam,
      options,
    }
  },
})
</script>
