<template>
  <div>
    <div class="d-flex mb-4 align-items-center">
      <h3 class="m-0">Base Prices</h3>
      <StripeBtn class="ml-3" @click="openModal(CARRIER_PRIORITY_MODAL)"> Available carriers </StripeBtn>
      <StripeBtn class="ml-3" @click="openModal(EDIT_DMT_FEE_MODAL)"> DMT Fee </StripeBtn>
    </div>
    <BasePriceTable :prices="prices" @update-price="updatePrice" />
    <EditDmtFeeModal :id="EDIT_DMT_FEE_MODAL" />
    <CarrierPriorityModal :id="CARRIER_PRIORITY_MODAL" type="base" />
    <PageFooter @save-prices="savePrices" />
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { openModal, registerModals } from '@/services/Modal'
import { computed, defineAsyncComponent, defineComponent, onBeforeUnmount, onMounted } from 'vue'
import { CARRIER_PRIORITY_MODAL, EDIT_DMT_FEE_MODAL } from '@/const/ModalTypes'
import { DEFAULT_LOADER } from '@/const/LoadersNames'
import StripeBtn from '@/views/Components/Elements/Buttons/StripeBtn.vue'

export default defineComponent({
  name: 'BasePricePage',
  components: {
    StripeBtn,
    PageFooter: defineAsyncComponent(() => import('@/views/Components/PageParts/PageFooter.vue')),
    BasePriceTable: defineAsyncComponent(() => import('@/views/Staff/BasePrice/BasePriceTable.vue')),
    EditDmtFeeModal: defineAsyncComponent(() => import('@/views/Components/BasePrice/EditDmtFeeModal.vue')),
    CarrierPriorityModal: defineAsyncComponent(() => import('@/views/Modals/Assignment/CarrierPriorityModal.vue')),
  },
  setup() {
    const store = useStore()
    const prices = computed(() => store.getters['prices/all'])

    const updatePrice = (price: any) => {
      store.dispatch('prices/updatePrices', price)
    }

    const savePrices = () => {
      store.dispatch('ui/loaderShow', { name: DEFAULT_LOADER })
      store
        .dispatch('prices/save')
        .then(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'success', text: 'Prices have been updated' })
        })
        .catch(() => {
          store.dispatch('ui/loaderHide', { name: DEFAULT_LOADER })
          notify({ group: 'error', text: 'Price update error' })
        })
    }

    onMounted(() => {
      registerModals(EDIT_DMT_FEE_MODAL)
      registerModals(CARRIER_PRIORITY_MODAL)
      store.commit('prices/SET_TYPE', '')
      store.dispatch('prices/fetch', {})
    })

    onBeforeUnmount(() => {
      store.dispatch('prices/resetState')
    })

    return {
      prices,
      openModal,
      savePrices,
      updatePrice,
      EDIT_DMT_FEE_MODAL,
      CARRIER_PRIORITY_MODAL,
    }
  },
})
</script>
