<template>
  <div>
    <div v-if="dateText">
      <div>
        <span>
          <b>
            <span class="no-wrap">{{ readableDate(dateText) }}</span>
          </b>
        </span>
      </div>
      <div>
        <span class="text-muted">{{ readableTime(dateText) }}</span>
      </div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { readableDate, readableTime } from '@/services/Helpers'

export default defineComponent({
  name: 'RowDate',
  props: {
    date: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const dateText = computed(() => props.date)

    return {
      dateText,
      readableDate,
      readableTime,
    }
  },
})
</script>
