<template>
  <div class="form-group parcel-characteristic-block">
    <div class="heading-row">
      <div class="label required fs-xl">Parcel Characteristics</div>
      <div v-if="showReqLink" class="custom-alert">
        <div class="custom-alert-image">
          <mdicon name="Alert" />
        </div>
        <div class="custom-alert-text">
          Read about parcel requirements
          <router-link :to="{ name: parcelRequirementsLink }" target="_blank"> here </router-link>
        </div>
      </div>
    </div>

    <div v-if="showWarning" class="limitation-warning">
      <div>
        {{ warningText }}
      </div>
    </div>

    <div id="parcel-characteristics">
      <ParcelCharacteristicsRow
        v-for="(parcel, index) in localParcels"
        :key="`parcel_${index}`"
        :vid="`parcel_${index}`"
        :parcel="parcel"
        :index="index"
        :show-remove-btn="localParcels.length > 1"
        :show-copy-btn="index === localParcels.length - 1 && showAddParcel"
        :show-estimated-cost="showEstimatedCost"
        :fields-limits="fieldsLimits"
        :in-modal="inModal"
        @parcel-removed="removeParcel"
        @parcel-updated="updateParcel"
        @parcel-copied="copyParcel"
      />
    </div>

    <span v-if="showAddParcel" class="d-flex align-items-center add-parcel" @click="addNewParcel">
      <button class="btn">+</button>
      Add new parcel characteristics
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref, toRaw } from 'vue'
import { PARCEL_REQUIREMENTS } from '@/types/router'
import ParcelCharacteristicsRow from '@/views/Components/Assignment/ParcelCharacteristics/ParcelCharacteristicsRow.vue'
import type { FieldsLimits } from '@/types/Models/Assignment'

export default defineComponent({
  name: 'ParcelCharacteristics',
  components: {
    ParcelCharacteristicsRow,
  },
  props: {
    parcels: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    showAddParcel: {
      type: Boolean,
      default: false,
    },
    showEstimatedCost: {
      type: Boolean,
      default: false,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    warningText: {
      type: String,
      default: '',
    },
    fieldsLimits: {
      type: Object as PropType<FieldsLimits>,
      required: true,
    },
    showReqLink: {
      type: Boolean,
      default: true,
    },
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['parcels-changed'],
  setup(props, { emit }) {
    const defaultParcelData = {
      weight: '',
      height: '',
      length: '',
      width: '',
      reference: '',
      description: '',
      commodity_code: '',
      estimated_cost: '',
    }

    const localParcels = ref(props.parcels)
    const parcelRequirementsLink = computed(() => PARCEL_REQUIREMENTS)

    const addNewParcel = () => {
      const parcels = toRaw(localParcels.value)
      parcels.push(defaultParcelData)
      emit('parcels-changed', parcels)
    }

    const removeParcel = (index: number) => {
      const parcels = toRaw(localParcels.value)
      if (parcels.length === 1) {
        emit('parcels-changed', [defaultParcelData])
        return
      }

      parcels.splice(index, 1)
      emit('parcels-changed', parcels)
    }

    const updateParcel = (index: number, field: string, value: any) => {
      const parcels = toRaw(localParcels.value)
      parcels[index][field] = value
      emit('parcels-changed', parcels)
    }

    const copyParcel = (index: number) => {
      const parcels = toRaw(localParcels.value)
      parcels.push({ ...parcels[index] })
      emit('parcels-changed', parcels)
    }

    return {
      parcelRequirementsLink,
      addNewParcel,
      removeParcel,
      updateParcel,
      copyParcel,
      localParcels,
    }
  },
})
</script>

<style lang="scss" scoped>
.parcel-characteristic-block {
  margin-top: 25px;
  margin-bottom: 10px;
  :deep(.fs-xl) {
    font-style: normal;
    font-weight: normal;
    font-size: 24px !important;
    line-height: 150%;
    text-transform: capitalize;
    color: #151515;
    margin-bottom: 24px;
    margin-top: 40px;
  }

  :deep(label),
  :deep(.label) {
    color: #000;
    font-weight: 700;
    line-height: 24px;
  }

  :deep(.label) {
    font-size: 16px;
  }

  :deep(label) {
    font-size: 14px;
  }

  :deep(.required)::after {
    content: '*';
    color: #ff5252;
  }

  .heading-row {
    margin-bottom: 30px;
    display: flex;
    .fs-xl {
      margin: 0 !important;
    }
  }

  .alert {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-weight: 700;
  }

  .add-parcel {
    cursor: pointer;
    width: fit-content;
    margin-top: 15px;
    font-weight: 700;
    color: #151515;
    font-size: 14px;

    .btn {
      margin-right: 10px;
      background-color: #058880;
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }

  .limitation-warning {
    color: #ff4e10;
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .switch-parcel-btn {
      font-size: 14px;
      font-weight: 700;
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
