import {
  CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
  CUSTOMER_ASSIGNMENTS_SINGLE,
  STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT,
  STAFF_ASSIGNMENTS_SINGLE,
} from '@/types/router'
import store from '@/store'
import { YELLOW } from '@/const/Colors'

const singleAssignmentPage = () =>
  store.getters['account/isStaff'] ? STAFF_ASSIGNMENTS_SINGLE : CUSTOMER_ASSIGNMENTS_SINGLE
const reportPage = () =>
  store.getters['account/isStaff'] ? STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT : CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT

export const getDefaultToDo = () => ({
  VIEW_ASSIGNMENT: {
    text: 'View Assigment',
    to: singleAssignmentPage(),
  },
  EDIT_ASSIGNMENT: {
    text: 'Edit Assigment',
    to: singleAssignmentPage(),
  },
  VIEW_REPORT: {
    text: 'View report',
    color: YELLOW,
    to: reportPage(),
  },
  VIEW_LABELS: {
    text: 'View Labels',
    to: singleAssignmentPage(), // TODO change to right route
  },
})
