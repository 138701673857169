<template>
  <modal-wrap
    :id="id"
    ref="IntegrationsOngoingSettings"
    :name="modalName"
    :header-class="'integrations-ongoing-settings-header'"
    body-class="integrations-ongoing-settings-modal"
    :hide-footer="true"
    size="md"
  >
    <template #modal-title>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">OnGoing Webhook Settings</h4>
      </div>
    </template>
    <template #default>
      <b-form-group>
        <div class="form-group">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Webhook URL</h4>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                1. Administration -> Integration -> Webhooks -> Create new automation webhook
                <br /><br />
                2. Choose webhook type (Order Picked or Order created) and paste the following URL:
                <br />
                https://core.pacsortonline.com/api/integrations/ongoing/callback?external_user_id={{ extUserId }}
                <br /><br />
                3. Administration -> Goods owners and users -> Users -> Create new user
                <br />
                You need to create user with Access Rights "Automation API user" and paste credentials below.
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Connection Settings</h4>
          </div>

          <div class="row">
            <div class="form-group mb-0">
              <div class="form-check mb-0 mt-0">
                <input id="sandbox" v-model="sandbox" class="form-check-input" type="checkbox" />
                <label for="sandbox" class="form-check-label mb-0"> Sandbox </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Warehouse</label>
                <VInput
                  v-model="externalId"
                  type="text"
                  name="externalId"
                  placeholder="Warehouse ID"
                  :rules="{ required: true, min: 3 }"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>OnGoing User</label>
                <VInput
                  v-model="user"
                  type="text"
                  name="user"
                  placeholder="Login"
                  :rules="{ required: true, min: 3 }"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>OnGoing Password</label>
                <VInput
                  v-model="pwd"
                  type="password"
                  name="pwd"
                  placeholder="Password"
                  :rules="{ required: true, min: 8 }"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <button type="button" class="btn create-btn btn-green" @click="connect">Connect</button>
        </div>
      </b-form-group>
    </template>
  </modal-wrap>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { openModal } from '@/services/Modal'
import { notify } from '@kyvg/vue3-notification'
import { INTEGRATIONS, ONGOING_WEBHOOK_SETTINGS } from '@/const/ModalTypes'
import ModalWrap from '@/views/Modals/ModalWrap.vue'
import VInput from '@/views/Components/Elements/Form/VInput.vue'

export default defineComponent({
  name: 'IntegrationsOngoingSettingsModal',
  components: {
    ModalWrap,
    VInput,
  },
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const extUserId = ref('')
    const customerId = ref('')
    const externalId = ref('')
    const sandbox = ref('')
    const user = ref('')
    const pwd = ref('')

    const modalName = ONGOING_WEBHOOK_SETTINGS

    const modalData = computed(() => store.getters['vueNextModal/modalProps'](modalName) || {})
    const parentModal = computed(() => store.getters['vueNextModal/modalProps'](INTEGRATIONS) || {})

    const connect = () => {
      store
        .dispatch('customers/integrations/connectOnGoing', {
          customerId: customerId.value,
          token: btoa(`${user.value}:${pwd.value}`),
          externalId: externalId.value,
          payload: {
            sandbox: sandbox.value,
          },
        })
        .then((response) => {
          if (response.message) {
            notify({ group: 'error', text: response.message })
          } else {
            notify({
              group: 'success',
              text: 'The integration has been connected.',
            })

            store.dispatch('vueNextModal/closeModal', INTEGRATIONS)
            store.dispatch('vueNextModal/closeModal', modalName)
            store.dispatch('customers/integrations/fetch', customerId.value).then((res) => {
              openModal(INTEGRATIONS, {
                ...parentModal.value,
                integrations: res.data || [],
              })
            })
          }
        })
        .catch((e) => {
          notify({
            group: 'error',
            text: `The integration has not been connected. ${e.message}`,
          })
        })
    }

    watch(modalData, () => {
      extUserId.value = modalData.value.extUserId
      customerId.value = modalData.value.customerId
      externalId.value = modalData.value.externalId
      sandbox.value = modalData.value.sandbox
      user.value = modalData.value.user
      pwd.value = modalData.value.pwd
    })

    return {
      connect,
      modalName,
      extUserId,
      customerId,
      externalId,
      sandbox,
      user,
      pwd,
    }
  },
})
</script>

<style lang="scss" scoped>
.integrations-ongoing-settings-modal .radio-group {
  padding: 10px 16px 10px 44px;
  margin: 6px 0;
}
.integrations-ongoing-settings-modal .radio-group::before {
  top: 12px;
}
</style>
