import {
  IN_REVIEW,
  OPTIMISATION_COMPLETE,
  OPTIMISING,
  REPORT_ACCEPTED,
  REPORT_DENIED,
  REPORT_RELEASED,
} from '@/const/AssignmentStatuses'
import { GREEN_SUCCESS, GREY, RED, YELLOW } from '@/const/Colors'
import { getDefaultToDo } from '@/services/Assignment/DefaultToDo'
import CustomerActions from '@/services/Assignment/Actions/CustomerActions'
import DefaultActions from '@/services/Assignment/Actions/DefaultActions'

export default () => {
  const defaultToDo = getDefaultToDo()
  return {
    [IN_REVIEW]: {
      color: GREY,
      text: 'Under Review',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
    },
    [OPTIMISING]: {
      color: GREY,
      text: 'Optimising',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
    },
    [OPTIMISATION_COMPLETE]: {
      color: GREY,
      text: 'Report Created',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
    },
    [REPORT_RELEASED]: {
      color: YELLOW,
      text: 'Report Created',
      toDo: defaultToDo.VIEW_REPORT,
      itemsActions: [
        {
          text: 'View report',
          color: YELLOW,
          handler: () => {
            DefaultActions.toReportPage()
          },
        },
      ],
      reportActions: [
        {
          text: 'Deny',
          color: RED,
          handler: () => {
            DefaultActions.submitReportDeny()
          },
        },
        {
          text: 'Decide Later',
          color: GREY,
          handler: () => {
            DefaultActions.toAssignmentSinglePage()
          },
        },
      ],
    },
    [REPORT_ACCEPTED]: {
      color: YELLOW,
      text: 'Report Accepted',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
      itemsActions: [
        {
          text: 'Create Labels',
          color: GREEN_SUCCESS,
          handler: () => {
            CustomerActions.createLabels()
          },
        },
      ],
      reportActions: [
        {
          text: 'View assigment',
          color: GREY,
          handler: () => {
            DefaultActions.toAssignmentSinglePage()
          },
        },
      ],
    },
    [REPORT_DENIED]: {
      color: RED,
      text: 'Report Denied',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
      infoNotify: {
        text: 'Report denied. We will contact you soon.',
        class: ['danger'],
        // help: 'It will not work to correct the assignment',
      },
    },
  }
}
