<template>
  <div class="w-100 m-auto shipments-list">
    <ShipmentsSearchForm />

    <b-card v-if="error" bg-variant="danger" class="mt-3">
      <h3>Error fetching shipments</h3>
      {{ error }}
    </b-card>
    <EasyDataTable
      ref="dataTable"
      v-model:server-options="serverOptions"
      table-class-name="vue3-easy-data-table table-shadow"
      :server-items-length="serverItemsLength"
      :loading="loading"
      :headers="headers"
      :items="items"
      :prevent-context-menu-row="false"
      :fixed-header="false"
      no-hover
      alternating
      hide-footer
      @update:server-options="updateServerOptions"
    >
      <template #loading>
        <div style="text-align: center">Loading...</div>
      </template>
      <template #header-shipmentNumber="header">
        <div class="customize-header">
          {{ header.text }}
        </div>
      </template>
      <template #expand="item">
        <div style="padding: 15px">
          <ShipmentDetails :shipment="item" />
        </div>
      </template>
      <template #no-data> - </template>
      <template #item="{ column, item }">
        <span v-if="column === 'createdAt'">
          <RowDate :date="item.createdAt" />
        </span>
        <span v-if="column === 'lastTrackingEventTime'">
          <RowDate :date="item.lastTrackingEventTime" />
        </span>
        <span v-if="column === 'user'">
          {{ item.user.company }}
        </span>
        <span v-if="column === 'recipient'">
          <RecipientCell :shipment="item" />
        </span>
        <span v-if="column === 'shipmentNumber'">
          <ShipmentNumberCell :shipment="item" />
        </span>
        <span v-if="column === 'lastTrackingEventStatus'">
          <TrackingStatusCell :shipment="item" />
        </span>
        <span v-if="column === 'carrierHandle'">
          <ShipmentsCarrierCell :shipment="item" />
        </span>
      </template>
    </EasyDataTable>

    <TablePagination
      v-model:server-options-ref="serverOptions"
      v-model:data-table-ref="dataTable"
      :server-items-length="serverItemsLength"
    />
  </div>
</template>

<script lang="ts">
import { ref, computed, watch, onMounted, defineComponent } from 'vue'
import { makeRequestFunction } from '@/services/Shipments'
import { STAFF } from '@/const/AccountTypes'
import ShipmentDetails from '@/views/Components/Shipments/Details/Details.vue'
import RowDate from '@/views/Components/Elements/Table/RowDate.vue'
import RecipientCell from '@/views/Components/Shipments/Cells/Recipient.vue'
import ShipmentsSearchForm from '@/views/Components/Shipments/ShipmentsSearchForm.vue'
import ShipmentsCarrierCell from '@/views/Components/Shipments/Cells/ShipmentsCarrierCell.vue'
import TrackingStatusCell from '@/views/Components/Shipments/Cells/TrackingStatus.vue'
import ShipmentNumberCell from '@/views/Components/Shipments/Cells/ShipmentNumber.vue'
import TablePagination from '@/views/Components/Elements/Table/TablePagination.vue'
import EasyDataTable from 'vue3-easy-data-table'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ShipmentsFeed',
  components: {
    TablePagination,
    ShipmentNumberCell,
    TrackingStatusCell,
    ShipmentsCarrierCell,
    ShipmentsSearchForm,
    ShipmentDetails,
    RecipientCell,
    RowDate,
    EasyDataTable,
  },
  props: {
    accountType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute()
    const dataTable = ref()
    const isStaff = computed(() => props.accountType === STAFF)
    const items = ref([])
    const error = ref(false)
    const loading = ref(false)
    const serverItemsLength = ref(0)
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 50,
    })
    const headers = computed(() => {
      return isStaff.value
        ? [
            { text: 'Shipment number', value: 'shipmentNumber' },
            { text: 'Created', value: 'createdAt' },
            { text: 'Transit', value: 'lastTrackingEventTime' },
            { text: 'Carrier', value: 'carrierHandle' },
            { text: 'Status', value: 'lastTrackingEventStatus' },
            { text: 'Customer', value: 'user' },
            { text: 'Recipient', value: 'recipient' },
          ]
        : [
            { text: 'Shipment number', value: 'shipmentNumber' },
            { text: 'Created', value: 'createdAt' },
            { text: 'Transit', value: 'lastTrackingEventTime' },
            { text: 'Carrier', value: 'carrierHandle' },
            { text: 'Status', value: 'lastTrackingEventStatus' },
            { text: 'Recipient', value: 'recipient' },
          ]
    })
    const loadFromServer = async () => {
      loading.value = true
      await makeRequestFunction(route)(serverOptions)
        .then((response: any) => {
          items.value = response?.data || []
          serverItemsLength.value = response.count || 0
        })
        .catch(() => {
          serverItemsLength.value = 0
          items.value = []
          error.value = true
        })
        .finally(() => {
          loading.value = false
        })
    }

    const updateServerOptions = (newOptions: any) => {
      serverOptions.value = newOptions
    }

    onMounted(loadFromServer)

    watch(serverOptions, loadFromServer, { deep: true })

    return {
      dataTable,
      error,
      items,
      loading,
      headers,
      serverItemsLength,
      serverOptions,
      updateServerOptions,
    }
  },
})
</script>

<style lang="scss">
.shipments-list {
  .child-row {
    padding: 25px;
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      text-transform: capitalize;
      color: #151515;
    }
    &__sub-title {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
      mix-blend-mode: normal;
    }
    &-item {
      &__title {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #757575;
        mix-blend-mode: normal;
        margin-bottom: 4px;
      }
      &__sub-title {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #151515;
        mix-blend-mode: normal;
      }
      &_underline {
        position: relative;
        padding: 0 0 56px 0;
        margin-bottom: 56px;
        &:after {
          position: absolute;
          top: 100%;
          left: 10px;
          content: '';
          display: block;
          height: 1px;
          width: 100%;
          background: #d6d6d1;
        }
      }
    }
    .p_default {
      padding: 0 56px;
    }
    .pr-default {
      padding-right: 56px;
    }
    .pl-default {
      padding-left: 56px;
    }
    .saved-cost {
      display: flex;
      align-items: center;
      margin-top: 16px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #08a762;
      }
      &__percent {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        background: rgba(8, 167, 98, 0.15);
        border-radius: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 144%;
        color: #08a762;
        padding: 0 5px;
        margin-left: 8px;
      }
    }
    .form-group {
      pointer-events: none;
      cursor: none;
      margin-bottom: 0;
      &.disabled {
        .radio-group {
          &::before {
            filter: grayscale(2);
            opacity: 0.6;
          }
        }
      }
      .radio-group {
        border: none !important;
        background: none !important;
        padding-left: 0 !important;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0 0 16px 0;
        &::before {
          top: 0;
          left: 0;
        }
      }
    }
    .border-x-light {
      border-right: 1px solid #d6d6d1;
      border-left: 1px solid #d6d6d1;
    }
    .invoice-reference {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      color: #151515;
      mix-blend-mode: normal;
      margin-top: 16px;
    }
  }
}
</style>
