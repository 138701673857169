/* ============
 * Order Transformer
 * ============
 *
 * The transformer for the order.
 */

import Transformer from '@/transformers/Transformer'

export default class IntegrationOrderTransformer extends Transformer {
  /**
   * Method used to transform a fetched order.
   */
  static fetch(order) {
    return {
      externalId: order.external_identifier,
      assignmentId: order.internal_identifier,
      status: order.import_status,
      error: order.error,
    }
  }
}
