import { LABELS_CREATED, LABELS_CREATING, LABELS_RELEASED } from '@/const/AssignmentStatuses'
import { GREEN, GREEN_SUCCESS, GREY } from '@/const/Colors'
import { LABELS_CREATED_WITH_ERRORS } from '@/const/LabelsCreatedStatuses'
import { getDefaultToDo } from '@/services/Assignment/DefaultToDo'
import CustomerActions from '@/services/Assignment/Actions/CustomerActions'
import DefaultActions from '@/services/Assignment/Actions/DefaultActions'

export default () => {
  const defaultToDo = getDefaultToDo()
  return {
    [LABELS_CREATED]: {
      color: GREY,
      text: 'Labels Creating',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
      infoNotify: {
        text: 'Creating labels. We will notify you when labels are ready to download.',
        class: ['success'],
      },
    },
    [LABELS_CREATING]: {
      color: GREY,
      text: 'Labels Creating',
      toDo: defaultToDo.VIEW_ASSIGNMENT,
      infoNotify: {
        text: 'Creating labels. We will notify you when labels are ready to download.',
        class: ['success'],
      },
    },
    [LABELS_RELEASED]: {
      color: GREEN_SUCCESS,
      text: 'Labels Released',
      toDo: defaultToDo.VIEW_LABELS,
      itemsActions: [
        {
          color: GREEN_SUCCESS,
          text: 'Download labels',
          handler: () => {
            DefaultActions.downloadLabels()
          },
        },
        {
          showIfLabelCreatedStatus: LABELS_CREATED_WITH_ERRORS,
          text: 'Create missing labels',
          color: GREEN,
          handler: () => {
            CustomerActions.createMissingLabels()
          },
        },
      ],
    },
  }
}
