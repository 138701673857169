<template>
  <Field v-slot="{ field, errors, meta }" :model-value="innerValue" :name="name" :rules="rules">
    <b-form-group class="form-group position-relative">
      <input
        v-bind="field"
        v-model="innerValue"
        :class="classes(meta)"
        :type="type"
        :max="max"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :placeholder="placeholderText"
        class="form-control"
      />
      <div v-if="unit.length" class="unit">
        {{ unit }}
      </div>
      <div class="invalid-feedback">
        <span v-if="!hideInvalidFeedback && errors.length > 0">
          {{ errors.length ? errors.join() : '' }}
        </span>
      </div>
    </b-form-group>
  </Field>
</template>

<script lang="ts">
import { Field } from 'vee-validate'
import { ref, computed, watch, defineComponent } from 'vue'
import { getValidationState } from '@/services/Validation'

export default defineComponent({
  name: 'VInput',
  components: {
    Field,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Object],
      default: () => ({}),
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    hideNumberArrow: {
      type: Boolean,
      default: false,
    },
    hideInvalidFeedback: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        ['text', 'number', 'email', 'password', 'search', 'url', 'tel', 'date', 'time', 'range', 'color'].includes(
          value,
        ),
    },
    unit: {
      type: String,
      default: '',
      required: false,
    },
    max: {
      type: String,
      default: '',
      required: false,
    },
    formatter: {
      type: Function,
      default: null,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const innerValue = ref(props.value)
    const validated = ref(false)

    const placeholderText = computed(() => {
      return props.hidePlaceholder ? '' : props.placeholder || props.name
    })

    const permissible = computed(() => {
      return !innerValue.value && props.rules && props.rules.permissible === true && validated.value
    })

    const classes = (meta) => {
      return {
        required: props.rules && (props.rules.required === true || props.rules.emailPhoneRequired === true),
        'is-permissible': permissible.value,
        'hide-input-arrow': props.hideNumberArrow,
        'is-valid': meta.touched && meta.valid,
        'is-invalid': meta.touched && !meta.valid,
      }
    }

    watch(innerValue, (val) => {
      emit('input', val)
    })

    watch(
      () => props.value,
      (val) => {
        innerValue.value = val
      },
    )

    return {
      innerValue,
      placeholderText,
      classes,
      getValidationState,
    }
  },
})
</script>

<style lang="scss" scoped>
.unit {
  position: absolute;
  right: 1px;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  background: #f5f5f3;
  border-radius: 0 4px 4px 0;
  border-left-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #757575;
}
.form-group {
  margin-bottom: 1rem;
}
.invalid-feedback {
  display: block;
}
</style>
