<template>
  <div v-if="isHidePrices">
    <h2>Optimization report is not available due to user level</h2>
  </div>
  <div v-else>
    <div class="report-container">
      <OptimizationReportHeader />
      <div class="optimization-wrap">
        <OptimizationTopList :type-report="typeReport" />
        <BeforeOptimizationTable />
        <AfterOptimizationTable />
      </div>
    </div>

    <div class="assignment-footer d-flex justify-content-between align-items-center">
      <div class="footer-btn ml-auto d-flex align-items-center">
        <AssignmentActions origin="report" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AssignmentActions from '@/views/Components/Assignment/Actions/AssignmentActions.vue'
import AfterOptimizationTable from '@/views/OptimizationReport/Tables/AfterOptimizationTable.vue'
import BeforeOptimizationTable from '@/views/OptimizationReport/Tables/BeforeOptimizationTable.vue'
import OptimizationReportHeader from '@/views/OptimizationReport/Components/OptimizationReportHeader.vue'
import OptimizationTopList from '@/views/OptimizationReport/Components/OptimizationTopList.vue'

export default defineComponent({
  name: 'OptimizationReport',
  components: {
    OptimizationTopList,
    OptimizationReportHeader,
    AfterOptimizationTable,
    BeforeOptimizationTable,
    AssignmentActions,
  },
  props: {
    headerColor: {
      type: String,
      default: '',
    },
    level: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const typeReport = ref('')
    const assignmentId = computed(() => route.params.id)

    const reportData = computed(() => store.getters['assignment/optimizationReport/all'])
    const assignment = computed(() => store.getters['assignment/get'])
    const status = computed(() => store.getters['assignment/getStatus'])
    const isHidePrices = computed(() => store.getters['account/isHidePrice'])
    const isStaff = computed(() => store.getters['account/isStaff'])

    const refreshData = () => {
      store.dispatch('assignment/optimizationReport/fetch', assignmentId.value)
      store.dispatch('assignment/fetch', assignmentId.value)
    }

    watch(assignmentId, refreshData)

    onMounted(refreshData)

    return {
      typeReport,
      reportData,
      assignment,
      status,
      isHidePrices,
      isStaff,
      assignmentId,
      refreshData,
    }
  },
})
</script>

<style lang="scss" scoped>
.report-container {
  margin-bottom: 8rem;
}

.optimization-report-option {
  margin-top: 15px;
}

.optimization-wrap {
  padding: 28px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.optimization-wrap .logo {
  width: 80px;
  height: 80px;
  margin-left: 15px;
  z-index: 0;
}

.optimization-action .btn {
  width: auto;
}

.optimization-action .to-do-button {
  max-width: 100%;
}
</style>
