<template>
  <b-overlay class="loader" :no-wrap="true" :no-center="true" :show="isShow">
    <template #overlay>
      <div class="loader">
        <div class="wrap-custom-progress">
          <h1 class="mb-3">Optimization & Creating Report</h1>
          <b-progress height="45px" :max="max">
            <span class="progress-value">{{ progress }} %</span>
            <b-progress-bar :value="progress" />
          </b-progress>
          <p class="mt-1 text-xxs" v-text="message ? message : 'Waiting for the server to start processing.'" />
          <p class="text-center mt-4">This can take up to several minutes, depending on how busy the server is.</p>
          <p class="overlay-bottom-text">
            You don't have to wait on this page while it loads, feel free to go to
            <router-link to="/"> My Assignments </router-link>
          </p>
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { OPTIMIZE_CREATING_REPORT_LOADER } from '@/const/LoadersNames'
import { OPTIMISING, REPORT_ACCEPTED, REPORT_RELEASED } from '@/const/AssignmentStatuses'
import {
  CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT,
  CUSTOMER_ASSIGNMENTS_SINGLE,
  STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT,
  STAFF_ASSIGNMENTS_SINGLE,
} from '@/types/router'

export default defineComponent({
  name: 'OptimizationCreatingReportLoader',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const max = ref(100)
    const progress = ref(0)
    const message = ref<string | null>(null)
    const status = ref<string | null>(null)
    const loader = computed(() => store.getters['ui/loader'])
    const assignmentId = computed(() => store.getters['assignment/getId'])
    const userId = computed(() => store.getters['account/userId'])
    const isStaff = computed(() => store.getters['account/isStaff'])
    const isShow = computed(() => OPTIMIZE_CREATING_REPORT_LOADER === loader.value.name && loader.value.show)

    const checkMessage = () => {
      setTimeout(() => {
        if (progress.value === 0) {
          message.value = 'Server is busy with other requests. waiting for them to finish...'
        }
      }, 25000)
    }

    const parseLoaderProgress = (response: any) => {
      progress.value = progress.value === 100 ? 100 : response.progress
      message.value = typeof response.message !== 'undefined' ? response.message : null
    }

    watch(status, (newVal, oldVal) => {
      if (!isShow.value) {
        return
      }

      if (oldVal !== OPTIMISING) {
        return
      }

      let next: string
      if (newVal === REPORT_RELEASED) {
        next = isStaff.value ? STAFF_ASSIGNMENTS_OPTIMIZATION_REPORT : CUSTOMER_ASSIGNMENTS_OPTIMIZATION_REPORT
      } else if (newVal === REPORT_ACCEPTED) {
        next = isStaff.value ? STAFF_ASSIGNMENTS_SINGLE : CUSTOMER_ASSIGNMENTS_SINGLE
      }

      if (!next) {
        return
      }

      progress.value = 100
      setTimeout(() => {
        router.push({
          name: next,
          params: { id: assignmentId.value },
        })
        store.dispatch('ui/loaderHide')
      }, 1000)
    })

    watch(isShow, (val) => {
      progress.value = 0
      if (val) {
        status.value = store.getters['assignment/getStatus']
        checkMessage()
        window.Echo.private(`App.User.${userId.value}.Assignment.${assignmentId.value}`).listen(
          '.optimisation-progress',
          (response: any) => parseLoaderProgress(response),
        )
        window.Echo.channel('App.Assignments').listen(
          '\\App\\Events\\Assignment\\AssignmentStatusChanged',
          (data: any) => {
            if (data.assignment.id === assignmentId.value) {
              status.value = data.assignment.status
            }
          },
        )
      }
    })

    onMounted(() => {
      if (props.show) {
        checkMessage()
      }
    })

    return {
      max,
      progress,
      message,
      isShow,
    }
  },
})
</script>
