import AssignmentProxy from '@/proxies/AssignmentProxy'
import AssignmentTransformer from '@/transformers/AssignmentTransformer'
import type { Assignment } from '@/types/Models/Assignment'
import { PACKAGE } from '@/const/DistributionChannels'

export const FETCH_ASSIGNMENT_SUMMARY = 'FETCH_ASSIGNMENT_SUMMARY'

const state = {
  assignment: {
    distributionChannel: PACKAGE,
    pickupDate: null,
    pickupInstructions: null,
    item: {},
    address: {},
    report: {
      info: {
        distribution_channel: PACKAGE,
        invoiceReference: null,
        serviceLevel: null,
      },
      manual: {},
    },
    user: {},
  },
}

const getters = {
  get: (state) => state.assignment,
}

const mutations = {
  [FETCH_ASSIGNMENT_SUMMARY](state, data: Assignment) {
    if (data.item === null) {
      // when batch assignment we should not have item
      delete data.item
    }
    state.assignment = Object.assign(state.assignment, data)
  },
}

const actions = {
  fetch: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      new AssignmentProxy()
        .getAssignmentSummary(payload)
        .then((response) => {
          const data = AssignmentTransformer.fetch(response.data)
          commit(FETCH_ASSIGNMENT_SUMMARY, data)
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    }),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
