import { VALIDATION_COMPLETE } from '@/const/AssignmentStatuses'
import { BLACK, GREEN_SUCCESS } from '@/const/Colors'
import { getDefaultToDo } from '@/services/Assignment/DefaultToDo'
import StaffActions from '@/services/Assignment/Actions/StaffActions'

export default () => {
  const defaultToDo = getDefaultToDo()
  return {
    [VALIDATION_COMPLETE]: {
      color: BLACK,
      text: 'Imported',
      toDo: defaultToDo.EDIT_ASSIGNMENT,
      itemsActions: [
        {
          text: 'Proceed',
          color: GREEN_SUCCESS,
          handler: () => {
            StaffActions.submitOptimize()
          },
        },
      ],
    },
  }
}
